<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-50">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / <router-link :to="{ name: 'Companies' }"> Companies </router-link>/
        {{ company.name }}
      </div>
    </div>

    <div class="row mx-0">
      <div class="col-sm pt-5">
        <h2>Informations</h2>
        <section class="height-fit-content">
          <table class="table w-100">
            <thead>
              <tr>
                <th scope="col" class="w-50">Property</th>
                <th scope="col">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="w-25">Name</td>
                <td>
                  {{ company.name }}
                </td>
              </tr>
              <tr>
                <td>Domain name</td>
                <td>
                  <a
                    target="_blank"
                    :href="`https://${company.domain_name?.trim()}`"
                    >{{ company.domain_name }}</a
                  >
                </td>
              </tr>
              <tr>
                <td>Ownership type</td>
                <td>
                  {{ company.ownership_type }}
                </td>
              </tr>
              <tr>
                <td>Headquarter</td>
                <td>
                  {{ headquarter.name }}
                </td>
              </tr>
              <tr>
                <td>Headquarter continent</td>
                <td>
                  {{ headquarter.continent }}
                </td>
              </tr>
              <tr>
                <td>Annual number of FTEs</td>
                <td>
                  {{ company.annual_number_of_ftes }}
                </td>
              </tr>
              <tr>
                <td>Number of site</td>
                <td>
                  <p class="m-0 p-0">{{ company.number_of_site }} (reported)</p>
                  <p class="m-0 p-0">{{ company.sites.length }} (computed)</p>
                </td>
              </tr>
              <tr>
                <td>Max OEL</td>
                <td>
                  {{ company.max_oel }}
                </td>
              </tr>
              <tr>
                <td>Max Scale</td>
                <td>{{ company.max_scale }} Litres</td>
              </tr>
              <tr>
                <td>Veterinary</td>
                <td>
                  <input
                    id=""
                    class="checkboxCustom"
                    type="checkbox"
                    :checked="company.vet == 'True'"
                    disabled
                  />
                </td>
              </tr>
              <tr>
                <td>Typologies (computed)</td>
                <td>
                  <span
                    v-for="t in company.computed_typologies"
                    :key="'typo_computed-' + t.id"
                    class="badge bg-dark text-white m-1"
                    >{{ t.general_typology?.name }} / {{ t?.name }}</span
                  >
                </td>
              </tr>
              <tr>
                <td>Technologies (computed)</td>
                <td>
                  <span
                    v-for="t in company.computed_technologies"
                    :key="'techno_computed-' + t.id"
                    class="badge bg-dark text-white m-1"
                    >{{ t.modality?.name }} / {{ t?.name }}</span
                  >
                </td>
              </tr>
              <tr>
                <td>CreatedAt</td>
                <td>
                  {{ company.createdAt }}
                </td>
              </tr>
              <tr>
                <td>Completion</td>
                <td>
                  <div
                    class="progress-bar bg-dark mt-2"
                    role="progressbar"
                    :style="
                      'width:' +
                      parseInt(company.corporate_infos_score * 100) +
                      '%;height:20px'
                    "
                    :aria-valuenow="
                      parseInt(company.corporate_infos_score * 100)
                    "
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {{ parseInt(company.corporate_infos_score * 100) }}%
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>

      <div class="col-sm pt-5">
        <h2>Sites</h2>
        <section class="height-fit-content">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col" class="w-25">City</th>
                <th scope="col" class="w-25">Label</th>
                <th scope="col" class="w-50">Services</th>
                <th scope="col" class="w-25">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in filter_sitesOnPage" :key="'site-' + item.id">
                <td>
                  <router-link
                    :to="{ name: 'SiteItem', params: { site_id: item.id } }"
                  >
                    {{ item.city }}</router-link
                  ><span style="padding-left: 5px"
                    >( {{ JSON.parse(item.country).name }} )</span
                  ><!--<span style="padding-left: 5px"
                ><img
                  class="rounded"
                  :src="getCountryFlagByName(JSON.parse(item.country).name)"
                  width="30"
                  alt="selectedCountry.code"
                /></span>-->
                </td>
                <td>{{ item.label }}</td>
                <td>
                  <span
                    class="badge bg-dark text-white m-1"
                    v-for="s in item.services"
                    :key="'service-' + s.id"
                    >{{ s.name }}</span
                  >
                </td>
                <td>
                  <button
                    class="btn btn-danger ml-auto"
                    style="border-radius: 4px"
                    v-on:click="formRemoveSite.show(item)"
                  >
                    <img
                      class="icon"
                      src="../../assets/icons/close.svg"
                      alt=""
                    />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex w-100">
            <pagination
              class="w-75"
              :index="pagination.index"
              :index-max="company.sites.length"
              :offset="pagination.offset"
              @previous-page="previousPage"
              @next-page="nextPage"
            />
            <div class="w-25 text-right">
              {{ filter_sitesOnPage.length }} item(s)
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="row mx-0 w-100 pt-5">
      <h2>Services</h2>
      <section class="height-fit-content">
        <div class="w-100">
          <div style="border-top: none">
            <select
              style="width: 270px"
              class="form-control"
              v-model="filter_selectedTechnology"
              required
            >
              <option value="" disabled>Select a modality/technology</option>
              <option
                v-for="item in filter_technologies.items"
                :key="'modality-' + item.id"
                :value="item"
              >
                {{ item.modality.name }} / {{ item.name }}
              </option>
            </select>
          </div>
          <table class="table table-striped w-100">
            <thead>
              <tr>
                <th scope="col" class="w-25">Name</th>
                <th scope="col" class="w-25">Parent</th>
                <th scope="col" class="w-25">Typology</th>
                <th scope="col" class="w-25">Column</th>
                <th scope="col" class="w-25">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in filter_serviceOnPage"
                :key="'service-' + item.id"
              >
                <td class="w-25">{{ item.name }}</td>
                <td class="w-25">{{ item.parent?.name }}</td>
                <td class="w-25">{{ item.typology?.name }}</td>
                <td class="w-25">{{ item.value_chain_column?.name }}</td>
                <td class="w-25">
                  <button
                    class="btn btn-danger ml-auto"
                    style="border-radius: 4px"
                    v-on:click="formRemoveService.show(item)"
                  >
                    <img
                      class="icon"
                      src="../../assets/icons/unlink.svg"
                      alt=""
                    />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex w-100">
            <pagination
              class="w-75"
              :index="paginationService.index"
              :index-max="filter_servicesByTechnology.length"
              :offset="paginationService.offset"
              @previous-page="previousPageService"
              @next-page="nextPageService"
            />
            <div class="w-25 text-right">
              {{ filter_serviceOnPage.length }} item(s)
            </div>
          </div>
        </div>
      </section>
    </div>

    <template #toolbar>
      <button
        :disabled="
          !$store.getters.permissions.includes(
            `pages.qualification.companies.${company.id}.add_service`
          )
        "
        class="btn btn-success"
        v-on:click="modalAddServices.show()"
      >
        Add services
      </button>

      <button
        :disabled="
          !$store.getters.permissions.includes(
            `pages.qualification.companies.${company.id}.add_site`
          )
        "
        class="btn btn-success"
        v-on:click="formCreateSite.show()"
      >
        Create Site
      </button>

      <button
        :disabled="
          !$store.getters.permissions.includes(
            `pages.qualification.companies.${company.id}.add_service`
          )
        "
        class="btn btn-success"
        v-on:click="formAddServiceAtSite.show()"
      >
        Add service to sites
      </button>

      <button
        class="btn btn-danger float-right"
        :disabled="
          !$store.getters.permissions.includes(
            `pages.qualification.companies.${company.id}.delete`
          )
        "
        v-on:click="formRemoveCompany.show()"
      >
        Remove '{{ company.name }}'
      </button>
      <button
        class="btn btn-secondary float-right"
        :disabled="
          !$store.getters.permissions.includes(
            `pages.qualification.companies.${company.id}.update`
          )
        "
        v-on:click="formEditCompany.show()"
      >
        Edit '{{ company.name }}'
      </button>
    </template>
  </content-panel>

  <modal
    title="Add services"
    v-on:modal-close="modalAddServices.hide()"
    :active="modalAddServices.visible.value"
    :width="70"
    :height="80"
    top="90"
  >
    <FormAddServiceToCompany
      :company="company"
      :modal="modalAddServices"
      @submit="reload(false)"
    ></FormAddServiceToCompany>
  </modal>

  <modal
    title="Create Site"
    v-on:modal-close="formCreateSite.close()"
    :active="formCreateSite.visible.value"
    :width="50"
    :height="65"
  >
    <form class="p-5 text-center" v-on:submit.prevent="formCreateSite.submit">
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>City (*)</th>
          <td>
            <input
              ref="createSite/city"
              class="w-100"
              type="text"
              placeholder="City"
              required
            />
          </td>
        </tr>
        <tr>
          <th>Label</th>
          <td>
            <input
              ref="createSite/label"
              class="w-100"
              type="text"
              placeholder="Label"
            />
          </td>
        </tr>
        <tr>
          <th>Country (*)</th>
          <td>
            <div class="d-flex">
              <select
                ref="createSite/country"
                class="form-control"
                v-model="selectedCountry"
                required
              >
                <option value="" selected disabled>Select a country</option>
                <option
                  v-for="item in countries"
                  :key="item.name"
                  :value="JSON.stringify(item)"
                  required
                >
                  {{ item.name }} ({{ item.continent }})
                </option>
              </select>
              <img
                v-if="selectedCountry != ''"
                class="rounded m-1"
                :src="getCountryFlagByName(JSON.parse(selectedCountry).name)"
                width="30"
                alt="selectedCountry.code"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th>FTEs</th>
          <td>
            <input
              ref="createSite/ftes"
              class="w-100"
              type="number"
              placeholder="FTEs"
            />
          </td>
        </tr>
        <tr>
          <th>Built in</th>
          <td>
            <input
              ref="createSite/build_at"
              class="w-100"
              type="number"
              placeholder="Built in"
              min="1850"
              max="2100"
            />
          </td>
        </tr>
      </table>
      <input
        ref="createSite/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <input
        ref="createSite/company_id"
        class="w-100"
        type="hidden"
        :value="company.id"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
    <p class="mx-3">(*) required</p>
  </modal>

  <modal
    :title="`Edit ${company.name}`"
    v-on:modal-close="formEditCompany.close()"
    :active="formEditCompany.visible.value"
    :width="50"
    :height="70"
  >
    <form class="p-5 text-center" v-on:submit.prevent="formEditCompany.submit">
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="editCompany/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="company.name"
              required
            />
          </td>
        </tr>
        <tr>
          <th>Domain Name</th>
          <td>
            <input
              ref="editCompany/domain_name"
              class="w-100"
              type="text"
              placeholder="www.domaine.tld"
              :value="company.domain_name"
              required
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <th>Ownership Type</th>
          <td>
            <select ref="editCompany/ownership_type" class="form-control">
              <option value="" selected disabled>
                Select a ownership type
              </option>
              <option
                v-for="item in ownershipType"
                :key="item"
                :value="item"
                :selected="company.ownership_type === item"
              >
                {{ item }}
              </option>
            </select>
          </td>
          <td></td>
        </tr>
        <tr>
          <th>Headquarter</th>
          <td>
            <select ref="editCompany/headquarter" class="form-control" required>
              <option value="" selected disabled>Select a headquarter</option>
              <option
                v-for="item in countries"
                :key="item.name"
                :value="JSON.stringify(item)"
                :selected="this.headquarter.name === item.name"
                required
              >
                {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>Annual number of FTEs</th>
          <td>
            <input
              ref="editCompany/annual_number_of_ftes"
              class="w-100"
              type="number"
              placeholder="Number"
              :value="company.annual_number_of_ftes"
            />
          </td>
        </tr>
        <tr>
          <th>Number of sites</th>
          <td>
            <input
              ref="editCompany/number_of_site"
              class="w-100"
              type="number"
              placeholder="Number"
              :value="company.number_of_site"
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <th>Max OEL</th>
          <td>
            <select
              ref="editCompany/max_oel"
              class="form-control"
              placeholder="Number"
            >
              <option value="0" selected disabled>Select max OEL</option>
              <option
                v-for="item in oel"
                :key="item"
                :value="item"
                :selected="company.max_oel === item"
                required
              >
                {{ item }}
              </option>
            </select>
          </td>
          <td></td>
        </tr>
        <tr>
          <th>Max Scale</th>
          <td>
            <input
              ref="editCompany/max_scale"
              class="w-100"
              type="number"
              placeholder="Number in liters"
              :value="company.max_scale"
              min="0"
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <th>Veterinary</th>
          <td>
            <div class="form-check">
              <input
                ref="editCompany/vet"
                class="form-check-input checkboxCustom"
                type="checkbox"
                :checked="company.vet == 'True'"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="editCompany/createdAt"
              class="w-100"
              type="text"
              placeholder="Date"
              :value="company.createdAt"
              disabled
            />
          </td>
        </tr>
      </table>
      <input
        ref="editCompany/id"
        class="w-100"
        type="hidden"
        :value="company.id"
      />
      <input
        ref="editCompany/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>

  <modal
    :title="`Remove ${company.name}`"
    v-on:modal-close="formRemoveCompany.close()"
    :active="formRemoveCompany.visible.value"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formRemoveCompany.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="removeCompany/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="company.name"
              disabled
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="removeCompany/createdAt"
              class="w-100"
              type="text"
              placeholder="Date"
              :value="company.createdAt"
              disabled
            />
          </td>
        </tr>
        <tr style="color: red">
          <th>Impact</th>
          <td>
            <p>
              This action will delete site '{{ company.name }}' and his
              associations.
            </p>
            <p>Reminder: ***</p>
          </td>
        </tr>
      </table>
      <input
        ref="removeCompany/id"
        class="w-100"
        type="hidden"
        :value="company.id"
      />
      <input
        ref="removeCompany/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>

  <modal
    :title="`Unlink ${formRemoveService.data_given.name}`"
    v-on:modal-close="formRemoveService.close()"
    :active="formRemoveService.visible.value"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formRemoveService.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="removeService/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="formRemoveService.data_given.name"
              disabled
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="removeService/createdAt"
              class="w-100"
              type="text"
              placeholder="Date"
              :value="formRemoveService.data_given.createdAt"
              disabled
            />
          </td>
        </tr>
        <tr style="color: red">
          <th>Impact</th>
          <td>
            <p>
              This action will remove '{{ formRemoveService.data_given.name }}'
              and his associations with '{{ company.name }}'.
            </p>
            <div v-if="removeServiceImpactedSite.length > 0">
              The following sites will lose their connection with '{{
                formRemoveService.data_given.name
              }}':
              <ul>
                <li
                  v-for="site in removeServiceImpactedSite"
                  :key="'impact-rm-serv' + site.id"
                >
                  {{ site.city }} ({{ site.label }})
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </table>
      <input
        ref="removeService/id"
        class="w-100"
        type="hidden"
        :value="formRemoveService.data_given.id"
      />
      <input
        ref="removeService/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>

  <modal
    :title="`Disassociate ${formRemoveSite.data_given.city}`"
    v-on:modal-close="formRemoveSite.close()"
    :active="formRemoveSite.visible.value"
    :width="50"
    :height="50"
  >
    <form class="p-5 text-center" v-on:submit.prevent="formRemoveSite.submit">
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="removeSite/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="formRemoveSite.data_given.city"
              disabled
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="removeSite/createdAt"
              class="w-100"
              type="text"
              placeholder="Date"
              :value="formRemoveSite.data_given.createdAt"
              disabled
            />
          </td>
        </tr>
        <tr style="color: red">
          <th>Impact</th>
          <td>
            <p>
              This action will delete '{{ formRemoveSite.data_given.city }}' and
              his associations.
            </p>
          </td>
        </tr>
      </table>
      <input
        ref="removeSite/id"
        class="w-100"
        type="hidden"
        :value="formRemoveSite.data_given.id"
      />
      <input
        ref="removeSite/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>

  <modal
    title="Add Service To Site (Drag and Drop)"
    v-on:modal-close="formAddServiceAtSite.close(), (siteServ = [])"
    :active="formAddServiceAtSite.visible.value"
    :width="80"
    :height="70"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formAddServiceAtSite.submit"
    >
      <div class="row" style="font-size: 1rem">
        <div class="col-sm pt-5">
          <div class="row">
            <h2>Services</h2>
            <information>
              <p>
                Select a service and drag it to the corresponding mfg. site on
                the right
              </p>
              <p>
                Move the mouse over a service already registered on a site to
                see its parent service
              </p>
            </information>
          </div>
          <section class="height-fit-content">
            <div class="w-100">
              <div style="border-top: none">
                <select
                  style="width: 270px"
                  ref="addServiceAtSite/country"
                  class="form-control"
                  v-model="filter_selectedTechnology"
                >
                  <option value="" disabled>
                    Select a modality/technology
                  </option>
                  <option
                    v-for="item in filter_technologies.items"
                    :key="'modality-' + item.id"
                    :value="item"
                  >
                    {{ item.modality.name }} / {{ item.name }}
                  </option>
                </select>
              </div>
              <table class="table table-striped w-100">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Parent</th>
                    <th scope="col">Typology</th>
                    <th scope="col">Column</th>
                  </tr>
                </thead>
                <tbody data-id="{{droparena}}" ref="droparena">
                  <tr
                    v-for="item in filter_addServiceAtSite"
                    :key="'service-' + item.id"
                    draggable="true"
                    @dragstart="startDrag($event, item)"
                    @dragend.prevent="dragEnd()"
                  >
                    <td>{{ item.name }}</td>
                    <td>{{ item.parent?.name }}</td>
                    <td>{{ item.typology?.name }}</td>
                    <td>{{ item.value_chain_column?.name }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex w-100">
                <pagination
                  class="w-75"
                  :index="paginationAddServiceAtSite.index"
                  :index-max="filter_servicesByTechnology.length"
                  :offset="paginationAddServiceAtSite.offset"
                  @previous-page="previousPageAddServiceAtSite"
                  @next-page="nextPageAddServiceAtSite"
                />
                <div class="w-25 text-right">
                  {{ filter_addServiceAtSite.length }} item(s)
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="col-sm pt-5">
          <h2>Sites</h2>
          <section class="height-fit-content">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">City</th>
                  <th scope="col">Label</th>
                  <th scope="col">Country</th>
                  <th scope="col">Services</th>
                </tr>
              </thead>
              <tbody data-id="{{dropzone}}" ref="dropzone" class="dropzone">
                <tr
                  class="dropzone-item"
                  v-for="item in filter_addService"
                  :key="'site-' + item.id"
                  @drop="onDrop($event, item)"
                  @dragover.prevent="dragOver($event)"
                  @dragenter.prevent="dragEnter($event)"
                  @dragleave.prevent="dragLeave($event)"
                >
                  <td class="">{{ item.city }}</td>
                  <td class="">{{ item.label }}</td>
                  <td class="">
                    {{ JSON.parse(item.country).name
                    }}<span style="padding-left: 10px"
                      ><img
                        class="rounded"
                        :src="
                          getCountryFlagByName(JSON.parse(item.country).name)
                        "
                        width="30"
                        alt="selectedCountry.code"
                    /></span>
                  </td>
                  <td class="w-50">
                    <span class="d-inline-block">
                      <span v-if="item.services.length > 0"
                        ><span
                          class="badge bg-dark text-white m-1"
                          v-for="serv in item.services"
                          :key="'service-' + serv.id"
                          tabindex="0"
                          data-toggle="tooltip"
                          :title="serv.parent?.name"
                          >{{ serv.name }}</span
                        ></span
                      >
                    </span>
                    <span v-if="siteServ[item.id]"
                      ><span
                        class="badge bg-primary text-white m-1"
                        v-for="serv in siteServ[item.id]"
                        :key="'service-' + serv.id"
                        v-on:click="removeNewService(item.id, serv.id)"
                        >{{ serv.name }}</span
                      ></span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex w-100">
              <pagination
                class="w-75"
                :index="paginationAddService.index"
                :index-max="company.sites.length"
                :offset="paginationAddService.offset"
                @previous-page="previousPageAddService"
                @next-page="nextPageAddService"
              />
              <div class="w-25 text-right">
                {{ filter_addService.length }} item(s)
              </div>
            </div>
          </section>
        </div>
      </div>
      <input
        ref="addServiceAtSite/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal";
import { createForm } from "../../assets/js/FormManager";
import Pagination from "../../components/Pagination";
import FormAddServiceToCompany from "@/forms/FormAddServiceToCompany";
import { UpdateMessage} from "@/assets/js/Alert";

import Information from '../../components/Information.vue';
import * as ModalClass from "@/assets/js/Modal";

export default {
  name: "CompanyItem",
  components: {
    ContentPanel,
    Modal,
    Pagination,
    FormAddServiceToCompany,
    Information,
  },
  props: {
    company_id: {
      required: true,
    },
  },
  data: function () {
    return {
      countries: this.$API.get("searches").COUNTRIES.sort((a, b) => {
        return a.name.localeCompare(b.name);
      }),
      associatedService: "",
      siteServ: {},
      filter_selectedTechnology: "",
      filter_technologies: [],
      oel: this.$API.get("companies").OEL,
      ownershipType: this.$API.get("companies").OWNERSHIPTYPE,
      value_chain_columns: [],
      services: [],
      companyServices: [],
      selectedValueChainColumn: [],
      selectedServices: [],
      selectedSites: [],
      selectedCountry: "",
      selectedTechnology: "",
      company: {
        id: null,
        name: null,
        headquarter: "{}",
        services: [],
        sites: [],
      },
      technologies: {
        id: null,
        name: null,
      },
      pagination: {
        index: 0,
        indexMax: 0,
        offset: 10,
      },
      paginationService: {
        index: 0,
        indexMax: 0,
        offset: 10,
      },
      paginationAddServiceAtSite: {
        index: 0,
        indexMax: 0,
        offset: 7,
      },
      paginationAddService: {
        index: 0,
        indexMax: 0,
        offset: 7,
      },
      formRemoveCompany: createForm(this, "removeCompany"),
      formEditCompany: createForm(this, "editCompany"),
      modalAddServices: new ModalClass.default(this, "associateServices"),
      formCreateSite: createForm(this, "createSite"),
      formRemoveService: createForm(this, "removeService"),
      formRemoveSite: createForm(this, "removeSite"),
      formAddServiceAtSite: createForm(this, "AddServiceAtSite"),
    };
  },
  created: async function () {
    await this.reload();

    this.filter_technologies = await this.$API
      .get("functions")
      .list("technology", 20, 0, this.$store.getters.token);

    this.formEditCompany.onSubmit(async (formData) => {
      let res = await this.$API
        .get("companies")
        .update(formData, this.$store.getters.token);
      this.$EventManager.triggerAlert(UpdateMessage(res!==null))
    });
    this.formEditCompany.afterSubmit(async () => {
      this.formEditCompany.close();
      await this.reload();
    });
    this.formRemoveCompany.onSubmit(async (formData) => {
      await this.$API
        .get("companies")
        .remove(formData, this.$store.getters.token);
    });
    this.formRemoveCompany.afterSubmit(async () => {
      await this.formRemoveCompany.close();
      this.$router.push({ name: "Companies" });
    });


    this.formCreateSite.onSubmit(async (formData) => {
      await this.$API.get("sites").create(formData, this.$store.getters.token);
    });
    this.formCreateSite.afterSubmit(async () => {
      this.formCreateSite.close();
      await this.reload();
    });
    this.formRemoveService.onSubmit(async (formData) => {
      await this.$API
        .get("companies")
        .remove_service(this.company.id, formData, this.$store.getters.token);
    });
    this.formRemoveService.afterSubmit(async () => {
      this.formRemoveService.close();
      await this.reload();
    });
    this.formRemoveSite.onSubmit(async (formData) => {
      await this.$API
        .get("companies")
        .remove_site(this.company.id, formData, this.$store.getters.token);
    });
    this.formRemoveSite.afterSubmit(async () => {
      this.formRemoveSite.close();
      await this.reload();
    });

    this.formAddServiceAtSite.onSubmit(async () => {
      this.$EventManager.triggerLoadingStart();
      let p = [];
      for (let [key, value] of Object.entries(this.siteServ)) {
        for (let i of value) {
          p.push(
            this.$API
              .get("sites")
              .add_service(key, i, this.$store.getters.token)
          );
        }
      }
      async () => await Promise.all(p);
    });
    this.formAddServiceAtSite.afterSubmit(async () => {
      this.formAddServiceAtSite.close();
      this.siteServ = {};
      let withTriggerLoading = false;
      await this.reload(withTriggerLoading);
      this.$EventManager.triggerLoadingEnd();
    });
  },
  computed: {
    headquarter: function () {
      return JSON.parse(this.company.headquarter);
    },
    filter_servicesByTechnology: function () {
      return this.companyServices.filter((service) => {
        if (this.filter_selectedTechnology) {
          return this.filter_selectedTechnology.value_chain_columns
            .map((vvc) => vvc.id)
            .includes(service.value_chain_column_id);
        }
        return true;
      });
    },
    filter_sitesOnPage: function () {
      return this.company.sites.slice(
        this.pagination.index,
        this.pagination.index + this.pagination.offset
      );
    },
    filter_serviceOnPage: function () {
      return this.filter_servicesByTechnology.slice(
        this.paginationService.index,
        this.paginationService.index + this.paginationService.offset
      );
    },
    filter_addService: function () {
      return this.company.sites.slice(
        this.paginationAddService.index,
        this.paginationAddService.index + this.paginationAddService.offset
      );
    },
    filter_addServiceAtSite: function () {
      return this.filter_servicesByTechnology.slice(
        this.paginationAddServiceAtSite.index,
        this.paginationAddServiceAtSite.index +
          this.paginationAddServiceAtSite.offset
      );
    },
    removeServiceImpactedSite: function () {
      return this.company.sites.filter(
        (elm) =>
          elm.services.find(
            (serv) => serv.id === this.formRemoveService.data_given?.id
          ) !== undefined
      );
    },
    servicesNotAlreadyLinkInCompany: function () {
      let companyServiceIds = this.companyServices.map((cs) => cs.id);
      return this.services.filter((s) => {
        return !companyServiceIds.includes(s.id);
      });
    },
  },
  methods: {
    reload: async function (withTriggerLoading = true) {
      if (withTriggerLoading) {
        this.$EventManager.triggerLoadingStart();
      }
      this.company = await this.$API
        .get("companies")
        .read(this.company_id, this.$store.getters.token);
      this.selectedSites = [...this.company.sites];
      this.pagination.indexMax = this.company.sites.length;
      this.servicesByCompany();
      if (withTriggerLoading) {
        this.$EventManager.triggerLoadingEnd();
      }
    },
    async servicesByCompany() {
      let res = await this.$API
        .get("companies")
        .getServicesByCompagnyId(this.company_id, this.$store.getters.token);
      this.companyServices = res.services;
    },
    getCountryFlagByName(name) {
      let country = this.countries.find((el) => el.name === name);
      var images = require.context("../../assets/flags/", false, /\.svg$/);
      return images("./" + country.code + ".svg");
    },
    previousPage: async function () {
      this.pagination.index -= this.pagination.offset;
    },
    nextPage: async function () {
      this.pagination.index += this.pagination.offset;
    },
    previousPageService: async function () {
      this.paginationService.index -= this.paginationService.offset;
    },
    nextPageService: async function () {
      this.paginationService.index += this.paginationService.offset;
    },
    previousPageAddServiceAtSite: async function () {
      this.paginationAddServiceAtSite.index -=
        this.paginationAddServiceAtSite.offset;
    },
    nextPageAddServiceAtSite: async function () {
      this.paginationAddServiceAtSite.index +=
        this.paginationAddServiceAtSite.offset;
    },
    previousPageAddService: async function () {
      this.paginationAddService.index -= this.paginationAddService.offset;
    },
    nextPageAddService: async function () {
      this.paginationAddService.index += this.paginationAddService.offset;
    },
    startDrag(e, service) {
      e.dataTransfer.dropEffect = "copy";
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("serviceID", service.id);
      this.$refs.dropzone.classList.add("drop-into-me");
      this.associatedService = e.path[0];
    },
    onDrop(e, site) {
      let serviceID = e.dataTransfer.getData("serviceID");
      let service = this.filter_addServiceAtSite.find(
        (service) => service.id == serviceID
      );
      let db_site = this.company.sites.find((elm) => elm.id == site.id);

      if (!this.siteServ[site.id]) {
        this.siteServ[site.id] = new Array();
      }

      if (
        this.siteServ[site.id].find((element) => element.id == service.id) ==
          undefined &&
        db_site.services.find((elm) => elm.id == service.id) == undefined
      ) {
        this.siteServ[site.id].push(service);
      }
      e.path[1].classList.remove("drop-into-me-item");
    },
    dragLeave(e) {
      e.path[1].classList.remove("drop-into-me-item");
      this.$refs.dropzone.classList.add("drop-into-me");
    },
    dragOver(e) {
      this.$refs.dropzone.classList.remove("drop-into-me");
      let tr = e.path.find((el) => el.localName == "tr");
      if (tr != undefined) {
        tr.classList.add("drop-into-me-item");
      }
    },
    dragEnter() {},
    dragEnd() {
      this.$refs.dropzone.classList.remove("drop-into-me");
    },
    removeNewService(site, id) {
      let position = this.siteServ[site].findIndex((el) => el.id == id);
      this.siteServ[site].splice(position, 1);
    },
  },
};
</script>

<style>
.checkboxCustom {
  transform: scale(2);
}
.red {
  background-color: #dc3545 !important;
}
.associatedService {
  background-color: #00946e !important;
}
.drop-into-me {
  animation-name: rotate;
  animation-duration: 0.07s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
}

.drop-into-me-item {
  border: 2px dashed black;
}

@keyframes rotate {
  0% {
    border: 0px dashed black;
  }
  100% {
    border: 2px dashed black;
  }
}
</style>
