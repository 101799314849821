<template>
  <form class="w-50" :class="addClass" v-on:submit.prevent="formSearch.submit">
    <input
      class="w-75"
      type="text"
      ref="formSearch/query"
      :placeholder="title"
    />

    <button>
      <img src="../assets/icons/magnifier.svg" alt="" class="icon" />
    </button>
    <button v-if="result != null" class="btn-danger" v-on:click.prevent="reset">
      <img src="../assets/icons/cancel.svg" alt="" class="icon" />
    </button>
  </form>
</template>

<script>
import { createForm } from "../assets/js/FormManager";
export default {
  name: "ModelSearchBar",
  props: {
    title: {
      default: "Search",
    },
    addClass: {
      default: "",
    },
    queryPrefix: {
      default: null,
    },
    disabled: {
      default: false,
    },
    offset: {
      default: 0,
    },
    limit: {
      default: 20,
    },
    model: {
      type: String,
      required: true,
    },
    useLoading:{
      type: Boolean,
      default: true,
    },
    useShaking:{
      type: Boolean,
      default: true,
    },
    emptyQueryFirst:{
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      formSearch: createForm(this, "formSearch"),
      result: null,
    };
  },
  created: async function () {
    this.formSearch.useLoading = false;
    this.formSearch.onSubmit(async (formData) => {
      if (this.useLoading){
        this.$EventManager.triggerLoadingMinSetValue(50);
      }
      this.result = await this.$API
        .get("functions")
        .finder(
          this.model,
          this.queryPrefix
            ? this.queryPrefix + " " + formData.query
            : formData.query,
          this.limit,
          this.offset,
          this.$store.getters.token
        );
      this.$router.replace(
        {
          ...this.$route,
          query: { ...this.$route.query, q: formData.query },
        },
        { silent: true }
      );
      if (this.useShaking){
        this.$EventManager.triggerShakeContentPanel();
      }
      if (this.useLoading){
        this.$EventManager.triggerLoadingMinSetValue(100);
      }
    });

    this.formSearch.afterSubmit(async () => {
      this.$emit("get-result", this.result);
    });
  },
  async mounted() {
    if (this.$route.query.q) {
      this.result = await this.$API
        .get("functions")
        .finder(
          this.model,
          this.queryPrefix
            ? this.queryPrefix + " " + this.$route.query.q
            : this.$route.query.q,
          this.limit,
          this.offset,
          this.$store.getters.token
        );
      this.$emit("get-result", this.result);
      setTimeout(() => this.$emit("get-result", this.result), 500);
    }else if (this.emptyQueryFirst){
      this.result = await this.$API
        .get("functions")
        .finder(
          this.model,
          this.queryPrefix
            ? this.queryPrefix + ""
            : "",
          this.limit,
          this.offset,
          this.$store.getters.token
        );
      this.$emit("get-result", this.result);
      setTimeout(() => this.$emit("get-result", this.result), 500);
    }
  },
  methods: {
    reset: function () {
      this.$refs["formSearch/query"].value = "";
      this.result = null;
      this.$router.replace(
        {
          ...this.$route,
          query: {},
        },
        { silent: true }
      );
      this.$emit("reset-result");
    },
  },
};
</script>

<style scoped></style>
