/**
 *  Logout
 */
export function triggerLogout(username) {
  document.dispatchEvent(
    new CustomEvent("app-logout", {
      detail: {
        username: username,
      },
    })
  );
}

export function onLogout(f) {
  document.addEventListener("app-logout", f);
}

/**
 *  Login
 */
export function triggerLogin() {
  document.dispatchEvent(new CustomEvent("app-login"));
}

export function onLogin(f) {
  document.addEventListener("app-login", f);
}

/**
 *  Loading
 */

export function triggerLoadingStart() {
  document.dispatchEvent(new CustomEvent("app-loading-start"));
}
export function onLoadingStart(f) {
  document.addEventListener("app-loading-start", f);
}

export function triggerLoadingEnd() {
  document.dispatchEvent(new CustomEvent("app-loading-end"));
}
export function onLoadingEnd(f) {
  document.addEventListener("app-loading-end", f);
}

/**
 *  Loading MIN
 */

export function triggerLoadingMinSetValue(value) {
  document.dispatchEvent(
    new CustomEvent("app-loading-min-start", {
      detail: { value: value },
    })
  );
}
export function onLoadingMinSetValue(f) {
  document.addEventListener("app-loading-min-start", f);
}

/**
 *  ALERT
 */

export function triggerAlert(message, type = 'primary') {

  if (!(message instanceof String)){
    type = message.type
    message = message.message
  }

  document.dispatchEvent(
    new CustomEvent("app-alert-message", {
      detail: { message, type },
    })
  );
}
export function onAlert(f) {
  document.addEventListener("app-alert-message", f);
}

/**
 *  Error
 */

export function triggerError(code, message) {
  document.dispatchEvent(
    new CustomEvent("app-error", {
      detail: {
        code,
        message,
      },
    })
  );
}
export function onError(f) {
  document.addEventListener("app-error", f);
}

/**
 *  Refresh user info
 */
export function triggerRefreshUserInfo(callback = () => {}) {
  document.dispatchEvent(
    new CustomEvent("app-refresh-user-info", { detail: { callback } })
  );
}

export function onRefreshUserInfo(f) {
  document.addEventListener("app-refresh-user-info", f);
}

/**
 *  Shake the content panel
 */
export function triggerShakeContentPanel() {
  document.dispatchEvent(new CustomEvent("app-shake-content-panel"));
}

export function onShakeContentPanel(f) {
  document.addEventListener("app-shake-content-panel", f);
}

export default {
  install: function (app) {
    app.config.globalProperties.$EventManager = {
      triggerLogin,
      onLogin,
      triggerLogout,
      onLogout,
      triggerLoadingStart,
      onLoadingStart,
      triggerLoadingEnd,
      onLoadingEnd,
      triggerError,
      onError,
      triggerRefreshUserInfo,
      onRefreshUserInfo,
      triggerLoadingMinSetValue,
      onLoadingMinSetValue,
      onShakeContentPanel,
      triggerShakeContentPanel,
      onAlert,
      triggerAlert
    };
  },
};
