<template>
<div class="w-100 pt-4">
  <ModelSearchBar :use-shaking="false" add-class="" :model="model"
    v-on:get-result="r => result = r"
    v-on:reset-result="reload" :limit="15"></ModelSearchBar>
  <div class="w-100 mt-4">
    <slot class="w-100" v-bind:result="result"></slot>
  </div>
</div>
</template>

<script>
import ModelSearchBar from "@/components/ModelSearchBar.vue";

export default {
  name: "ModelWidget",
  components: {ModelSearchBar},
  props: {
    model:String,
    queryPrefix: {
      default: null,
    },
  },
  data: function () {
    return {
      result: {items:[], count:0}
    }
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload(){
      this.result = await this.$API
        .get("functions")
        .finder(
          this.model,
          this.queryPrefix
            ? this.queryPrefix + ""
            : "",
          15,
          0,
          this.$store.getters.token
        );
    }
  }
}
</script>

<style scoped>

</style>
