<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-50">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / <router-link :to="{ name: 'Sites' }">Sites</router-link> /
        {{ site.city }}
      </div>
    </div>

    <div class="pt-5">
      <h2>Informations</h2>
      <section class="height-fit-content">
        <table class="table w-100">
          <thead>
            <tr>
              <th scope="col">Property</th>
              <th scope="col">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Company</td>
              <td>
                <router-link
                  v-if="site.companies.length > 0"
                  :to="{
                    name: 'CompanyItem',
                    params: { company_id: site.companies[0]?.id },
                  }"
                >
                  {{ site.companies[0]?.name }}
                </router-link>
              </td>
            </tr>
            <tr>
              <td>City</td>
              <td>
                {{ site.city }}
              </td>
            </tr>
            <tr>
              <td>label</td>
              <td>
                {{ site.label }}
              </td>
            </tr>
            <tr>
              <td>Country</td>
              <td>
                {{ JSON.parse(site.country).name }}
              </td>
            </tr>
            <tr>
              <td>Continent</td>
              <td>
                {{ JSON.parse(site.country).continent }}
              </td>
            </tr>
            <tr>
              <td>FTEs</td>
              <td>
                {{ site.ftes }}
              </td>
            </tr>
            <tr>
              <td>Built in</td>
              <td>
                {{ site.build_at }}
              </td>
            </tr>
            <tr>
              <td>CreatedAt</td>
              <td>
                <input class="w-100" type="text" :value="site.createdAt" />
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <div class="row mx-0 w-100 pt-5">
        <h2>Services</h2>
        <section class="height-fit-content">
          <div class="w-100">
            <table class="table table-striped w-100">
              <thead>
                <tr>
                  <th scope="col" class="w-25">Name</th>
                  <th scope="col" class="w-25">Parent</th>
                  <th scope="col" class="w-25">Typology</th>
                  <th scope="col" class="w-25">Column</th>
                  <th scope="col" class="w-25">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in site.services" :key="'service-' + item.id">
                  <td class="w-25">{{ item.name }}</td>
                  <td class="w-25">{{ item.parent?.name }}</td>
                  <td class="w-25">{{ item.typology?.name }}</td>
                  <td class="w-25">{{ item.value_chain_column?.name }}</td>
                  <td class="w-25">
                    <button
                      class="btn btn-danger ml-auto"
                      style="border-radius: 4px"
                      v-on:click="formRemoveServiceAtSite.show(item)"
                    >
                      <img
                        class="icon"
                        src="../../assets/icons/unlink.svg"
                        alt=""
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </div>

    <template #toolbar>
      <!--<button
        class="btn btn-success"
        v-on:click="formAssociateServices.show(), getModalities()"
      >
        Associate services
      </button>-->
      <button
        class="btn btn-danger float-right"
        v-on:click="formRemoveSite.show(site)"
      >
        Remove '{{ site.city }}'
      </button>
      <button
        class="btn btn-secondary float-right"
        v-on:click="formEditSite.show()"
      >
        Edit '{{ site.city }}'
      </button>
    </template>
  </content-panel>

  <modal
    :title="`Edit ${site.city}`"
    v-on:modal-close="formEditSite.close()"
    :active="formEditSite.visible.value"
    :width="50"
    :height="50"
  >
    <form class="p-5 text-center" v-on:submit.prevent="formEditSite.submit">
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Label</th>
          <td>
            <input
              ref="editSite/label"
              class="w-100"
              type="text"
              placeholder="Label"
              :value="site.label"
            />
          </td>
        </tr>
        <tr>
          <th>City</th>
          <td>
            <input
              ref="editSite/city"
              class="w-100"
              type="text"
              placeholder="City"
              :value="site.city"
              required
            />
          </td>
        </tr>
        <tr>
          <th>Country</th>
          <td>
            <select ref="editSite/country" class="form-control" required>
              <option value="" selected disabled>Country</option>
              <option
                v-for="item in countries"
                :key="item.name"
                :value="JSON.stringify(item)"
                :selected="this.site.country === JSON.stringify(item)"
              >
                {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>Company</th>
          <td>
            <input
              ref="editSite/company_name"
              class="form-control w-100 mb-1"
              type="text"
              placeholder="Company name"
              v-model="editSiteCompanyName"
              v-on:change="() => updateEditSiteCompaniesSelector()"
            />
            <select ref="editSite/company_id" class="form-control">
              <option value="" selected disabled>Select a company</option>
              <option
                v-for="item in editSiteCompanies.items"
                :key="item.name"
                :value="item.id"
                :selected="
                  this.site.companies.length > 0
                    ? this.site.companies[0].id === item.id
                    : false
                "
              >
                {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>FTEs</th>
          <td>
            <input
              ref="editSite/ftes"
              class="w-100"
              type="number"
              placeholder="FTEs"
              :value="site.ftes"
              required
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <th>Built in</th>
          <td>
            <input
              ref="editSite/build_at"
              class="w-100"
              type="number"
              placeholder="Built in"
              required
              min="1850"
              max="2100"
              :value="site.build_at"
            />
          </td>
          <td></td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="editSite/createdAt"
              class="w-100"
              type="text"
              placeholder="Date"
              :value="site.createdAt"
              disabled
            />
          </td>
        </tr>
      </table>
      <input ref="editSite/id" class="w-100" type="hidden" :value="site.id" />
      <input
        ref="editSite/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>

  <modal
    :title="`Remove ${formRemoveServiceAtSite.data_given.name}`"
    v-on:modal-close="formRemoveServiceAtSite.close()"
    :active="formRemoveServiceAtSite.visible.value"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formRemoveServiceAtSite.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="removeServiceAtSite/service"
              class="w-100"
              type="text"
              placeholder="City"
              :value="formRemoveServiceAtSite.data_given.name"
              disabled
            />
          </td>
        </tr>
        <tr v-if="formRemoveServiceAtSite.data_given.parent?.id">
          <th>Parent</th>
          <td>
            <input
              ref="removeServiceAtSite/parent"
              class="w-100"
              type="text"
              placeholder="City"
              :value="`${formRemoveServiceAtSite.data_given.parent.name} (${formRemoveServiceAtSite.data_given.parent.typology.name} / ${formRemoveServiceAtSite.data_given.parent.value_chain_column.name})`"
              disabled
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="removeServiceAtSite/createdAt"
              class="w-100"
              type="text"
              placeholder="Date"
              :value="formRemoveServiceAtSite.data_given.createdAt"
              disabled
            />
          </td>
        </tr>
        <tr style="color: red">
          <th>Impact</th>
          <td>
            <p>
              This action will remove the service '{{
                formRemoveServiceAtSite.data_given.name
              }}' from the '{{ site.city }} ({{ site.label }})' site.
            </p>
            <p>Reminder: ***</p>
          </td>
        </tr>
      </table>
      <input
        ref="removeServiceAtSite/id"
        class="w-100"
        type="hidden"
        :value="formRemoveServiceAtSite.data_given.id"
      />
      <input
        ref="removeServiceAtSite/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>

  <modal
    :title="`Remove ${formRemoveSite.data_given.city}`"
    v-on:modal-close="formRemoveSite.close()"
    :active="formRemoveSite.visible.value"
    :width="50"
    :height="50"
  >
    <form class="p-5 text-center" v-on:submit.prevent="formRemoveSite.submit">
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>City</th>
          <td>
            <input
              ref="removeSite/city"
              class="w-100"
              type="text"
              placeholder="City"
              :value="formRemoveSite.data_given.city"
              disabled
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="removeSite/createdAt"
              class="w-100"
              type="text"
              placeholder="Date"
              :value="formRemoveSite.data_given.createdAt"
              disabled
            />
          </td>
        </tr>
        <tr style="color: red">
          <th>Impact</th>
          <td>
            <p>
              This action will delete '{{ formRemoveSite.data_given.city }}' and
              his associations.
            </p>
            <p>Reminder: ***</p>
          </td>
        </tr>
      </table>
      <input
        ref="removeSite/id"
        class="w-100"
        type="hidden"
        :value="formRemoveSite.data_given.id"
      />
      <input
        ref="removeSite/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal";
import { createForm } from "../../assets/js/FormManager";
import {DeleteMessage, UpdateMessage} from "@/assets/js/Alert";

export default {
  name: "SiteItem",
  components: { ContentPanel, Modal },
  props: {
    site_id: {
      required: true,
    },
  },
  data: function () {
    return {
      site: {
        id: null,
        city: null,
        country: "{}",
        companies: [],
      },
      editSiteCompanyName: null,
      editSiteCompany: {
        name: null,
        id: null,
      },
      editSiteCompanies: [],
      editSiteCompanyNameSelectorAvailable: true,
      countries: this.$API.get("searches").COUNTRIES.sort((a, b) => {
        return a.name.localeCompare(b.name);
      }),
      formRemoveSite: createForm(this, "removeSite"),
      formEditSite: createForm(this, "editSite"),
      formRemoveServiceAtSite: createForm(this, "removeServiceAtSite"),
      showCreateForm: false,
      showformRemoveServiceAtSite: false,
    };
  },
  created: async function () {
    await this.reload();
    this.editSiteCompanyName =
      this.site.companies.length > 0 ? this.site.companies[0]?.name : null;

    this.formEditSite.beforeShow(() => this.updateEditSiteCompaniesSelector());
    this.formEditSite.onSubmit(async (formData) => {
      let res = await this.$API.get("sites").update(formData, this.$store.getters.token);
      this.$EventManager.triggerAlert(UpdateMessage(res!==null))
    });
    this.formEditSite.afterSubmit(async () => {
      this.formEditSite.close();
      await this.reload();
    });
    this.formRemoveSite.onSubmit(async (formData) => {
      let res = await this.$API.get("sites").remove(formData, this.$store.getters.token);
      this.$EventManager.triggerAlert(DeleteMessage(res!==null))
    });
    this.formRemoveSite.afterSubmit(async () => {
      await this.formRemoveSite.close();
      await this.$router.push({ name: "Sites" });
    });
    this.formRemoveServiceAtSite.onSubmit(async (formData) => {
      await this.$API
        .get("sites")
        .remove_service(this.site.id, formData, this.$store.getters.token);
    });
    this.formRemoveServiceAtSite.afterSubmit(async () => {
      this.formRemoveServiceAtSite.close();
      await this.reload();
    });
  },
  computed: {
    country: function () {
      return JSON.parse(this.site.country);
    },
  },
  methods: {
    reload: async function () {
      this.$EventManager.triggerLoadingStart();
      this.site = await this.$API
        .get("sites")
        .read(this.site_id, this.$store.getters.token);
      this.$EventManager.triggerLoadingEnd();
    },
    updateEditSiteCompaniesSelector: async function () {
      this.editSiteCompanyNameSelectorAvailable = false;
      this.editSiteCompanies = await this.$API
        .get("functions")
        .finder(
          "company",
          this.editSiteCompanyName ? this.editSiteCompanyName : "",
          10,
          0,
          this.$store.getters.token
        );
      this.editSiteCompanyNameSelectorAvailable = true;
    },
  },
};
</script>

<style></style>
