import Modalities from "../pages/qualification/Modalities";
import Technologies from "../pages/qualification/Technologies";
import TechnologyItem from "../pages/qualification/TechnologyItem";
import Generaltypologies from "../pages/qualification/Generaltypologies";
import Typologies from "../pages/qualification/Typologies";
import Companies from "../pages/qualification/Companies";
import CompanyItem from "../pages/qualification/CompanyItem";
import Filters from "../pages/qualification/Filters";
import Sites from "../pages/qualification/Site";
import SiteItem from "../pages/qualification/SiteItem";

export default [
  {
    name: "Modalities",
    path: "/modalities",
    component: Modalities,
    props: true,
    meta: {
      permissions: [],
    },
  },
  {
    name: "Technologies",
    path: "/modality/:modality_id/technologies",
    component: Technologies,
    props: true,
    meta: {
      permissions: [],
    },
  },
  {
    name: "TechnologyItem",
    path: "/modality/:modality_id/technology/:technology_id",
    component: TechnologyItem,
    props: true,
    meta: {
      permissions: [],
    },
  },
  {
    name: "Generaltypologies",
    path: "/generaltypologies",
    component: Generaltypologies,
    props: true,
    meta: {
      permissions: [],
    },
  },
  {
    name: "Typologies",
    path: "/generaltypologies/:general_typology_id/typologies",
    component: Typologies,
    props: true,
    meta: {
      permissions: [],
    },
  },
  {
    name: "Companies",
    path: "/companies",
    component: Companies,
    props: true,
    meta: {
      permissions: [],
    },
  },
  {
    name: "CompanyItem",
    path: "/company/:company_id",
    component: CompanyItem,
    props: true,
    meta: {
      permissions: [],
    },
  },
  {
    name: "Sites",
    path: "/sites",
    component: Sites,
    props: true,
    meta: {
      permissions: [],
    },
  },
  {
    name: "SiteItem",
    path: "/site/:site_id",
    component: SiteItem,
    props: true,
    meta: {
      permissions: [],
    },
  },
  {
    name: "Filters",
    path: "/filters",
    component: Filters,
    props: true,
    meta: {
      permissions: [],
    },
  },
];
