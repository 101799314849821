<template>
  <div class="trigger" style="display: inline">
    <img
      style="padding-bottom: 15px"
      class="icon"
      src="../assets/icons/info.svg"
      alt="icon info"
    />
    <div class="bubble">
      <div id="bubble" class="bubble-text item">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Information",
};
</script>

<style scoped>
/****bulles d'information*****/
#bubble a {
  color: #2a3587;
}

.trigger:hover .item {
  display: block;
}

.bubble-text {
  background: #139ee0;
  color: #fff;
  padding: 20px 15px;
  border-radius: 3px;
  width: 350px;
  text-align: left;
  display: none;
  position: absolute;
  margin-top: -5px;
  margin-left: 10px;
  font-family: "larka";
  font-size: 15px;
  line-height: 1.2rem;
}
</style>
