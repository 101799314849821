<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-50">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / Blacklists
      </div>
      <form v-on:submit.prevent="filterByName" class="w-50">
        <input
          class="w-75"
          type="text"
          v-model="filter.name"
          placeholder="Search"
        />

        <button>
          <img src="../../assets/icons/magnifier.svg" alt="" class="icon" />
        </button>

        <button
          v-if="filter.name"
          class="btn-danger"
          v-on:click.prevent="cancelFilterByName"
        >
          <img src="../../assets/icons/cancel.svg" alt="" class="icon" />
        </button>
      </form>
    </div>
    <div class="pt-5">
      <section class="height-fit-content">
        <table class="table table-striped w-100">
          <thead>
            <th scope="col">Name</th>
            <th scope="col">Current status</th>
            <th scope="col">Actions</th>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.name" :class="blacklistEnabled.find((el) => el === item.id) !== undefined? 'bg-success text-white' : ''">
              <td>{{ item.name }}</td>
              <td>{{blacklistEnabled.find((el) => el === item.id) === undefined ? "Disabled" : "Enabled"}}</td>
              <td>
                <div class="d-flex">
                  <div class="pt-2">
                    <input :id="'blacklist-switch-'+item.id" :checked="blacklistEnabled.find((el) => el === item.id) !== undefined" v-on:click="(e) => onClickBlacklistSwitch(e, item)" class="toggle" type="checkbox">
                  </div>
                  <LockedBtn
                    additional-class="btn btn-secondary"
                    v-on:click="
                    $router.push({
                      name: 'BlacklistItem',
                      params: { id: item.id },
                    })
                  "
                    :permission="`pages.main.blacklists.${item.id}.read`"
                    :lock-content-hided-on-lock="true"
                  >
                    <img
                      src="../../assets/icons/magnifier.svg"
                      alt=""
                      class="icon"
                    />
                  </LockedBtn>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex w-100">
          <pagination
            class="w-75"
            :index="pagination.index"
            :index-max="pagination.indexMax"
            :offset="pagination.offset"
            @previous-page="previousPage"
            @next-page="nextPage"
          />
          <div class="w-25 text-right">{{ items.length }} item(s)</div>
        </div>
      </section>
    </div>

    <template #toolbar>
      <LockedBtn
        additional-class="btn btn-success"
        v-on:click="showCreateBlacklist = true"
        permission="pages.main.blacklists.create"
      >
        Create
      </LockedBtn>
    </template>
  </content-panel>
  <modal
    title="Create blacklists"
    v-on:modal-close="(showCreateBlacklist = false), (createForm.name = '')"
    :active="showCreateBlacklist"
    :width="50"
    :height="50"
  >
    <form class="p-5 text-center" v-on:submit.prevent="create">
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              class="w-100"
              v-model="createForm.name"
              type="text"
              name="name"
              placeholder="Name"
            />
          </td>
        </tr>
      </table>
      <button
        class="btn btn-primary mt-5"
        type="submit"
        v-on:click="createBlacklist(), (createForm.name = '')"
      >
        Submit
      </button>
    </form>
  </modal>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal.vue";
import Pagination from "../../components/Pagination";
import LockedBtn from "@/components/LockedBtn";

export default {
  name: "Blacklists",
  components: { LockedBtn, ContentPanel, Modal, Pagination },
  data: function () {
    return {
      items: [],
      itemsEnabled: [],
      createForm: {
        name: null,
      },
      showCreateBlacklist: false,
      pagination: {
        index: 0,
        indexMax: 0,
        offset: 20,
      },
      filter: {
        name: null,
      },
    };
  },
  computed: {
    blacklistEnabled() {
      return this.itemsEnabled;
    },
  },

  methods: {
    async createBlacklist() {
      this.showCreateBlacklist = false;
      this.$EventManager.triggerLoadingMinSetValue(50);
      await this.$API
        .get("blacklists")
        .create(
          this.createForm.name,
          this.$store.getters.uid,
          this.$store.getters.token
        );
      this.$EventManager.triggerLoadingMinSetValue(80);
      this.$EventManager.triggerRefreshUserInfo(async () => {
        await this.refresh();
        this.$EventManager.triggerLoadingMinSetValue(100);
      });
    },
    onClickEnable(blacklist) {
      this.$API.get("blacklists").enable(blacklist);
      this.itemsEnabled = this.$API.get("blacklists").getEnabled();
    },
    onClickDisable(blacklist) {
      this.$API.get("blacklists").disable(blacklist);
      this.itemsEnabled = this.$API.get("blacklists").getEnabled();
    },
    refresh: async function () {
      if (this.filter.name && this.filter.name.trim() !== "") {
        await this.filterByName();
      } else {
        let res = await this.$API
          .get("blacklists")
          .list(
            this.pagination.offset,
            this.pagination.index,
            this.$store.getters.token
          );
        this.items = res.items;
        this.pagination.indexMax = res.count;
      }
    },
    previousPage: async function () {
      this.$EventManager.triggerLoadingStart();
      this.pagination.index -= this.pagination.offset;
      await this.refresh();
      document.body.scrollTop = 0;
      this.$EventManager.triggerLoadingEnd();
    },
    nextPage: async function () {
      this.$EventManager.triggerLoadingStart();
      this.pagination.index += this.pagination.offset;
      await this.refresh();
      document.body.scrollTop = 0;
      this.$EventManager.triggerLoadingEnd();
    },
    filterByName: async function () {
      if (this.filter.name && this.filter.name.trim() !== "") {
        let res = await this.$API
          .get("blacklists")
          .filter(
            this.filter.name,
            this.pagination.offset,
            this.pagination.index,
            this.$store.getters.token
          );
        this.items = res.items;
        this.pagination.indexMax = res.count;
      } else {
        await this.refresh();
      }
    },
    cancelFilterByName: async function () {
      this.filter.name = null;
      await this.refresh();
    },
    canCreateBlacklist: function () {
      return this.$store.getters.permissions.find(
        (perm) => perm === "pages.main.blacklists.create"
      );
    },
    canReadBlacklist: function (id) {
      return this.$store.getters.permissions.includes(
        `pages.main.blacklists.${id}.read`
      );
    },
    onClickBlacklistSwitch(e, item){
      if(this.blacklistEnabled.find((el) => el === item.id) !== undefined) {
        this.onClickDisable(item)
      } else{
        this.onClickEnable(item)
      }
    }
  },
  created: async function () {
    this.$EventManager.triggerLoadingStart();
    await this.refresh();
    this.itemsEnabled = this.$API.get("blacklists").getEnabled();
    this.$EventManager.triggerLoadingEnd();
  },
};
</script>

<style scoped>
.toggle, .toggle:before, .slot__label, .curtain {
  transition-property: background-color, transform, visibility;
  transition-duration: 0.25s;
  transition-timing-function: ease-in, cubic-bezier(0.6,0.2,0.4,1.5), linear;
}
.toggle:before, .slot, .slot__label {
  display: block;
}
.toggle:before, .curtain {
  position: absolute;
}
.toggle:checked, .curtain {
  background-color: white;
}
.toggle:focus {
  outline: transparent;
}
.toggle {
  border-radius: 0.75em;
  box-shadow: 0 0 0 0.1em inset;
  cursor: pointer;
  position: relative;
  margin-right: 0.25em;
  width: 3em;
  height: 1.5em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
}
.toggle:before {
  background: currentColor;
  border-radius: 50%;
  content: "";
  top: 0.2em;
  left: 0.2em;
  width: 1.1em;
  height: 1.1em;
}
.toggle:checked:before {
  transform: translateX(1.5em);
}
.toggle:checked ~ .slot .slot__label, .slot__label:nth-child(2) {
  transform: translateY(-50%) scaleY(0);
}
.toggle:checked ~ .slot .slot__label:nth-child(2) {
  transform: translateY(-100%) scaleY(1);
}
.toggle:checked ~ .curtain {
  transform: scaleX(1);
}
.slot{
  font-size: 15px;
  padding: 0px;
  margin: 0px;
}
.curtain {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scaleX(0);
  transform-origin: 0 50%;
  z-index: -1;
}
</style>
