import Utils from "../assets/js/Utils";

let server = {
  main: "http://localhost:5000",
};

if (Utils.isProduction()) {
  server = {
    main: "https://api.lensteam.larka.com",
  };
}

export const SERVER = { ...server };
