import {reactive} from "vue";

export default class Storage {
  constructor() {
    this.store = reactive({values: {}})
  }
  set(key, value = null) {
    this.store.values[key] = value;
  }

  get(key) {
    return this.store.values[key];
  }
}
