import EventBroker from "@/assets/js/EventBroker";
import {reactive} from "vue";

export default class Modal extends EventBroker{

  constructor(vue, name, visible = false) {
    super();
    this.visible = reactive({value: visible})
    this.name = reactive({value: name})
    this.vue = vue

    if (this.vue.$route.query?.modal === this.name.value){
      this.show({modifyUrl: false}).then(() =>{})
    }
  }

  beforeShow(callback) {
    this.subscribe("modal-before-show", callback)
  }

  afterShow(callback) {
    this.subscribe("modal-after-show", callback)
  }

  beforeHide(callback) {
    this.subscribe("modal-before-hide", callback)
  }

  afterHide(callback) {
    this.subscribe("modal-after-hide", callback)
  }

  async show(options = {modifyUrl: true}) {
    await this.trigger("modal-before-show");
    this.visible.value = true;
    await this.trigger("modal-after-show");
    if (options.modifyUrl){
      this.vue.$router.replace(
        {
          ...this.vue.$route,
          query: { ...this.vue.$route.query, modal: this.name.value },
        },
        { silent: true }
      );
    }
  }


  async hide(options = {modifyUrl: true}) {
    await this.trigger("modal-before-hide");
    this.visible.value = false;
    await this.trigger("modal-after-hide");

    if (options.modifyUrl){
      let new_route = { ...this.vue.$route.query, modal: null };

      delete new_route.modal;

      this.vue.$router.replace(
        {
          ...this.vue.$route,
          query: new_route,
        },
        { silent: true }
      );
    }
  }

}
