<template>
  <div id="metrics" class="pt-5">
    <div class="row m-auto">
      <div class="col-md-4">
        <section>
          <canvas id="myChart" width="400" height="400"></canvas>
        </section>
      </div>
      <div class="col-md-4">
        <section>
          <canvas id="myChart2" width="400" height="400"></canvas>
        </section>
      </div>
      <div class="col-md-4">
        <section>
          <canvas id="myChart5" width="400" height="400"></canvas>
        </section>
      </div>
      <div class="col-md-4">
        <section>
          <canvas id="myChart3" width="400" height="400"></canvas>
        </section>
      </div>
      <div class="col-md-4">
        <section>
          <canvas id="myChart4" width="400" height="400"></canvas>
        </section>
      </div>
      <div class="col-md-4">
        <section>
          <canvas id="myChart6" width="400" height="400"></canvas>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#metrics > .row section {
  margin-bottom: 20px;
}
</style>
