<template>
  <div class="mt-5 mx-auto text-center">
    <img class="mb-3" src="../../assets/icons/wrench.svg" width="200" alt="" />
    <h2 class="text-center">501</h2>
    <h3>Not Implemented</h3>
    <router-link class="btn btn-primary" :to="{ name: 'Home' }"
      >Go back home</router-link
    >
  </div>
</template>

<script>
export default {
  mounted() {
    this.$EventManager.triggerLoadingEnd();
  },
};
</script>

<style></style>
