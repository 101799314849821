<template>
  <div class="mt-5 mx-auto text-center">
    <img class="mb-3" src="../../assets/icons/walk.svg" width="200" alt="" />
    <h2 class="text-center">401</h2>
    <h3>Unauthorized</h3>
    <router-link class="btn btn-primary" :to="{ name: 'Home' }"
      >Go back to Login page</router-link
    >
  </div>
</template>

<script>
export default {
  name: "Err401",
  created: function () {
    this.$store.dispatch("logout", {
      callback: () => {
        this.$EventManager.triggerLogout();
      },
    });
  },
};
</script>

<style></style>
