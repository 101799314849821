import Request from "./request";
import Utils from "../Utils";

const SERVER = "main";

export async function list(limit, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `blacklist/paginate/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::blacklist::list", error);
  }
}

export async function filter(name, limit, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `blacklist/filter/${name}/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::blacklist::filter", error);
  }
}

export async function create(name, user, token) {
  try {
    return await Request.post(SERVER, `blacklist`, token, { name, user });
  } catch (error) {
    Utils.debugVar("Connectors::blacklist::create", error);
  }
}

export async function read(id, token) {
  try {
    return await Request.get(SERVER, `blacklist/${id}`, token);
  } catch (error) {
    Utils.debugVar("Connectors::blacklist::read", error);
  }
}

export async function update(blacklist, token) {
  try {
    return await Request.put(SERVER, `blacklist/${blacklist.id}`, token, {
      ...blacklist,
    });
  } catch (error) {
    Utils.debugVar("Connectors::blacklist::update", error);
  }
}

export async function remove(blacklistId, token) {
  try {
    return await Request.delete(SERVER, `blacklist/${blacklistId}`, token);
  } catch (error) {
    Utils.debugVar("Connectors::blacklist::remove", error);
  }
}

export async function addItem(blacklistName, blacklistId, domain, user, token) {
  try {
    return await Request.post(SERVER, `blacklist/${blacklistName}`, token, {
      blacklistId,
      domain,
      user,
    });
  } catch (error) {
    Utils.debugVar("Connectors::blacklist::addItem", error);
  }
}

export async function importItems(blacklist, domains_txt, user, token) {
  try {
    return await Request.post(
      SERVER,
      `blacklist/${blacklist.id}/import`,
      token,
      {
        blacklistId: blacklist.id,
        domains: domains_txt,
        user,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::blacklist::importItem", error);
  }
}

export async function exportItems(blacklist, token) {
  try {
    Number.prototype.padLeft = function (base, chr) {
      var len = String(base || 10).length - String(this).length + 1;
      return len > 0 ? new Array(len).join(chr || "0") + this : this;
    };

    let result = await Request.get(
      SERVER,
      `blacklist/${blacklist.id}/export`,
      token
    );

    let today = new Date();
    today =
      [
        today.getDate().padLeft(),
        (today.getMonth() + 1).padLeft(),
        today.getFullYear(),
      ].join("_") +
      ", " +
      [
        today.getHours().padLeft(),
        today.getMinutes().padLeft(),
        today.getSeconds().padLeft(),
      ].join("_");

    let fileURL = window.URL.createObjectURL(new Blob([result]));
    let fileLink = document.createElement("a");

    fileLink.href = fileURL;
    fileLink.setAttribute(
      "download",
      `blacklist-[${today}]-${blacklist.name.replaceAll(" ", "-")}.csv`
    );

    fileLink.click();
  } catch (error) {
    Utils.debugVar("Connectors::blacklist::exportItems", error);
  }
}

export async function removeItem(blacklistName, item, token) {
  try {
    return await Request.delete(
      SERVER,
      `blacklist/${blacklistName}/${item.id}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::blacklist::removeItem", error);
  }
}

export async function getItems(blacklist, limit, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `blacklist/${blacklist}/paginate/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::blacklist::getItems", error);
  }
}

export async function filterItems(domain, blacklist, limit, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `blacklist/${blacklist}/filter/${domain}/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::blacklist::filterItems", error);
  }
}

export async function readRolePermission(blacklist_id, role_id, token) {
  try {
    return await Request.get(
      SERVER,
      `blacklist/${blacklist_id}/permissions/role/${role_id}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::blacklists::readRolePermission", error);
  }
}

export async function addRolePermission(
  blacklist_id,
  role_id,
  permission,
  token
) {
  try {
    return await Request.post(
      SERVER,
      `blacklist/${blacklist_id}/permissions`,
      token,
      {
        role: role_id,
        permission,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::blacklist::addRolePermission", error);
  }
}

export async function removeRolePermission(
  blacklist_id,
  role_id,
  permission,
  token
) {
  try {
    return await Request.delete(
      SERVER,
      `blacklist/${blacklist_id}/permissions`,
      token,
      {
        role: role_id,
        permission,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::blacklist::addRolePermission", error);
  }
}

export function enable(blacklist) {
  let blacklistEnabled = getEnabled();
  blacklistEnabled.push(blacklist.id);
  setEnabled(blacklistEnabled);
}

export function disable(blacklist) {
  let blacklistEnabled = getEnabled();
  let index = blacklistEnabled.indexOf(blacklist.id);
  if (index >= 0) blacklistEnabled.splice(index, 1);
  setEnabled(blacklistEnabled);
}

export function getEnabled() {
  let blacklistEnabledJson = localStorage.getItem("blacklistEnabled");
  let enabled =
    blacklistEnabledJson != null ? JSON.parse(blacklistEnabledJson) : [];
  enabled.contains = function (blacklist) {
    return enabled.find((el) => el === blacklist.id) !== undefined;
  };
  return enabled;
}

export function clearEnabled() {
  setEnabled([]);
}
function setEnabled(blacklistEnabled) {
  let blacklistEnabledJson = JSON.stringify(blacklistEnabled);
  localStorage.setItem("blacklistEnabled", blacklistEnabledJson);
}
/* eslint-disable */
export function getDomainName(url){
  function isDomainName(domain){
    return domain.match(/^[a-zA-Z0-9][a-zA-Z0-9-\.]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/)!==null
  }

  if (url!==null && url.trim()!==""){
    let matches = url.match(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)/)

    if(matches!==null && matches?.length>=2) {
      let domain = matches[1]
      return isDomainName(domain)? domain : null
    }
  }

  return null
}

