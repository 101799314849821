<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-75">
        <img src="../../assets/icons/home.svg" alt="" class="icon" /> Home
      </div>
    </div>
    <div id="project" class="pt-5">
      <h2>Globals</h2>
      <div class="row mx-0">
        <div class="col-md-4">
          <section class="height-fit-content p-4 text-center">
            <h2 class="text-center pb-0">{{ numberWithSpaces(nbProjects) }}</h2>
            <h3 class="pt-0">Projects</h3>
          </section>
        </div>
        <div class="col-md-4">
          <section class="height-fit-content p-4 text-center">
            <h2 class="text-center pb-0">
              {{ numberWithSpaces(nbSearchesComputed) }}
            </h2>
            <h3 class="pt-0">Searches computed</h3>
          </section>
        </div>
        <div class="col-md-4">
          <section class="height-fit-content p-4 text-center">
            <h2 class="text-center pb-0">
              {{ numberWithSpaces(nbBlacklistItems) }}
            </h2>
            <h3 class="pt-0">Domains blacklisted</h3>
          </section>
        </div>
        <div class="col-md-6 mt-3">
          <section class="height-fit-content p-4 text-center">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Rank</th>
                  <th scope="col">Flag</th>
                  <th scope="col">Name</th>
                  <th scope="col">Nb searches</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(country, index) in top5Countries" :key="country[0]">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <img
                      class="rounded"
                      :src="getCountryFlag(country[0])"
                      width="30"
                      :alt="country[0]"
                    />
                  </td>
                  <td>{{ country[0] }}</td>
                  <td>{{ country[1] }}</td>
                </tr>
              </tbody>
            </table>
            <h3 class="pt-0">The 5 most used countries</h3>
          </section>
        </div>
        <div class="col-md-6 mt-3">
          <section class="height-fit-content p-4 text-center">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Rank</th>
                  <th scope="col">Word</th>
                  <th scope="col">Nb use</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(word, index) in top5Words" :key="word[0]">
                  <td>{{ index + 1 }}</td>
                  <td>{{ word[0] }}</td>
                  <td>{{ word[1] }}</td>
                </tr>
              </tbody>
            </table>
            <h3 class="pt-0">The 5 most used words</h3>
          </section>
        </div>
      </div>

      <h2 class="mt-5">Your latest searches</h2>
      <section class="height-fit-content">
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="w-25" scope="col">Date</th>
              <th class="w-25" scope="col">Project</th>
              <th class="w-25" scope="col">Query</th>
              <th class="w-25" scope="col">Country</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in latestSearches" :key="item.id">
              <td>{{ item.createdAt }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'ProjectItemGroup',
                    params: { id: item.projectId },
                  }"
                  >{{ item.project }}</router-link
                >
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'ProjectItem',
                    params: {
                      id: item.projectId,
                      runId: item.runId,
                      breadCrumbsQuery: item.query,
                    },
                  }"
                  >{{ item.query }}</router-link
                >
              </td>
              <td>
                <img
                  v-for="(item, index) in item.countries"
                  :key="index"
                  class="rounded mr-2"
                  :src="getCountryFlagByName(item)"
                  width="30"
                  :alt="item"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <template #toolbar> </template>
  </content-panel>
  <modal
    :title="'Remove ' + deleteItem.item.query"
    :active="deleteItem.show"
    :width="30"
    :height="30"
    v-on:modal-close="deleteItem.show = false"
  >
    <div class="px-5">
      <p>Are you sure ?</p>
      <p>You will remove the search '{{ deleteItem.item.query }}'</p>
      <p>
        (country: {{ deleteItem.item.country }}, date:
        {{ deleteItem.item.createdAt }})
      </p>
      <button
        class="btn btn-danger w-100 mt-3 py-3"
        v-on:click.prevent="remove"
      >
        <img class="icon" src="../../assets/icons/garbage.svg" alt="" />
        <span>Remove</span>
      </button>
    </div>
  </modal>

  <modal
    :title="viewItem.name"
    :active="viewItem.show"
    :width="90"
    :height="80"
    v-on:modal-close="viewItem.show = false"
  >
    <content-panel>
      <div class="w-100 h-100" style="overflow: scroll">
        <nav>
          <button
            class="btn"
            :class="!viewItem.tabBlacklist ? 'btn-primary' : 'btn-secondary'"
            v-on:click="viewItem.tabBlacklist = false"
          >
            Result
          </button>
          <button
            class="btn"
            :class="viewItem.tabBlacklist ? 'btn-primary' : 'btn-secondary'"
            :disabled="!viewItem.item.blacklist"
            v-on:click="viewItem.tabBlacklist = true"
          >
            Blacklist applied
          </button>
        </nav>
        <table
          v-if="!viewItem.tabBlacklist"
          class="w-100 h-100 table table-striped"
        >
          <tr v-for="(row, index) in viewItem.data" :key="index">
            <td
              v-for="(value, col) in row"
              :key="`${index}.${col}`"
              :class="value.length > 70 ? 'large-cell' : ''"
            >
              {{ value }}
            </td>
          </tr>
        </table>
        <table
          v-if="viewItem.tabBlacklist"
          class="w-100 mt-3 table table-striped"
        >
          <tr>
            <th>Blacklist</th>
            <th>Domain</th>
          </tr>
          <tr
            v-for="(blacklistItem, index) in viewItem.item.blacklist"
            :key="'blacklistItem' + index"
          >
            <td>
              {{ blacklistItem.blacklist }}
            </td>
            <td>
              {{ blacklistItem.domain }}
            </td>
          </tr>
        </table>

        <p
          v-if="viewItem.tabBlacklist && viewItem.item.blacklist.length == 0"
          class="mt-3"
        >
          No blacklist was apply during this search
        </p>
      </div>

      <template #toolbar>
        <button
          class="btn btn-warning"
          v-on:click="
            viewItem.item.id ? download(viewItem.item) : downloadSelection()
          "
        >
          <img src="../../assets/icons/download.svg" alt="" class="icon" />
        </button>
        <button
          class="btn"
          :class="viewItem.copied ? 'btn-success' : 'btn-secondary'"
          :disabled="viewItem.copied"
          v-on:click="copy(viewItem.item)"
        >
          <img src="../../assets/icons/copy.svg" alt="" class="icon" />
        </button>
        <span v-if="viewItem.copied" class="ml-1">Copied...</span>
        <button
          class="btn btn-danger float-right"
          v-on:click="
            () => {
              if (viewItem.item.id) {
                viewItem.show = false;
                deleteItem.show = true;
                deleteItem.item = viewItem.item;
              } else {
                viewItem.show = false;
                selectedItems = [];
              }
            }
          "
        >
          <img src="../../assets/icons/garbage.svg" alt="" class="icon" />
        </button>
      </template>
    </content-panel>
  </modal>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal";

export default {
  name: "Projects",
  components: { ContentPanel, Modal },
  data: function () {
    return {
      countries: this.$API.get("searches").COUNTRIES.sort((a, b) => {
        return a.name.localeCompare(b.name);
      }),
      nbBlacklistItems: 0,
      nbProjects: 0,
      nbSearchesComputed: 0,
      latestSearches: [],
      top5Countries: [],
      top5Words: [],
      item: {
        query: null,
        blacklist: [],
      },
      deleteItem: {
        item: {
          query: null,
        },
        show: false,
      },
      viewItem: {
        name: null,
        data: [],
        show: false,
        item: {
          query: null,
          blacklist: [],
        },
        copied: false,
        tabBlacklist: false,
      },
    };
  },
  methods: {
    numberWithSpaces(x) {
      return (x || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    getCountryFlag(name) {
      let country = this.$API
        .get("searches")
        .COUNTRIES.find((el) => el.name === name);
      var images = require.context("../../assets/flags/", false, /\.svg$/);
      return images("./" + country.code + ".svg");
    },
    getCountryFlagByName(name) {
      let country = this.countries.find((el) => el.name === name);
      var images = require.context("../../assets/flags/", false, /\.svg$/);
      return images("./" + country.code + ".svg");
    },
    download: async function (item) {
      let project = await this.$API
        .get("projects")
        .read(item.projectId, this.$store.getters.token);
      this.$API.get("searches").download(project, item);
    },
    remove: async function () {
      await this.$API
        .get("searches")
        .remove(
          this.deleteItem.item.id,
          this.$store.getters.uid,
          this.$store.getters.token
        );
      this.deleteItem.show = false;
      this.latestSearches = await this.$API
        .get("searches")
        .latest(this.$store.getters.token);
    },
    view: async function (item) {
      this.viewItem.item = item;
      let project = await this.$API
        .get("projects")
        .read(item.projectId, this.$store.getters.token);
      this.viewItem.name = `[${item.createdAt}]-${project.name.replaceAll(
        " ",
        "-"
      )}-${item.query.replaceAll(" ", "-")}-${item.country.replaceAll(
        " ",
        "-"
      )}.csv`;
      this.viewItem.data = this.$API.get("searches").toArray(item);
      this.viewItem.tabBlacklist = false;
      this.viewItem.show = true;
    },
    viewSelection: function () {
      this.showSelection = false;
      this.viewItem.name = `Selection`;
      let res = this.$API.get("searches").selectionToArray(this.selectedItems);
      this.viewItem.data = res.data;
      this.viewItem.item = res.fakeItem;
      this.viewItem.tabBlacklist = false;
      this.viewItem.show = true;
    },
    copy: function (item) {
      navigator.clipboard.writeText(item.result);
      this.viewItem.copied = true;
      setTimeout(() => (this.viewItem.copied = false), 3000);
    },
  },
  created: async function () {
    this.$EventManager.triggerLoadingStart();
    this.nbBlacklistItems = await this.$API
      .get("stats")
      .countBlacklistItem(this.$store.getters.token);
    this.nbProjects = await this.$API
      .get("stats")
      .countProject(this.$store.getters.token);
    this.nbSearchesComputed = await this.$API
      .get("stats")
      .countCountry(this.$store.getters.token);
    this.latestSearches = await this.$API
      .get("searches")
      .latest(this.$store.getters.token);
    this.top5Countries = await this.$API
      .get("stats")
      .top5Countries(this.$store.getters.token);
    this.top5Words = await this.$API
      .get("stats")
      .top5WordsUsed(this.$store.getters.token);
    this.$EventManager.triggerLoadingEnd();
  },
};
</script>

<style></style>
