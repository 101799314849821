import { createApp } from "vue";
import App from "./App.vue";

/**
 * Custom Plugin
 */
import Utils from "./assets/js/Utils";
import EventManager from "./assets/js/EventManager";

/**
 * Connectors Plugin
 */
import * as Firebase from "./assets/js/Connectors/firebase";
import * as Blacklists from "./assets/js/Connectors/blacklists";
import * as Logs from "./assets/js/Connectors/logs";
import * as Metrics from "./assets/js/Connectors/metrics";
import * as Projects from "./assets/js/Connectors/projects";
import * as Searches from "./assets/js/Connectors/searches";
import * as Users from "./assets/js/Connectors/users";
import * as Stats from "./assets/js/Connectors/stats";
import * as GeneralTypologies from "./assets/js/Connectors/general_typologies";
import * as Typologies from "./assets/js/Connectors/typologies";
import * as Modalities from "./assets/js/Connectors/modalities";
import * as Technologies from "./assets/js/Connectors/technologies";
import * as ValueChainColumns from "./assets/js/Connectors/value_chain_columns";
import * as Services from "./assets/js/Connectors/services";
import * as Companies from "./assets/js/Connectors/companies";
import * as Sites from "./assets/js/Connectors/sites";
import * as Filters from "./assets/js/Connectors/filters";
import * as Functions from "./assets/js/Connectors/functions";
import * as CheckApi from "./assets/js/Connectors/check_api";
import Connectors from "./assets/js/Connectors/index";

/**
 * Store
 */
import Store from "./store/index";

Connectors.set("blacklists", Blacklists);
Connectors.set("logs", Logs);
Connectors.set("metrics", Metrics);
Connectors.set("projects", Projects);
Connectors.set("searches", Searches);
Connectors.set("users", Users);
Connectors.set("firebase", Firebase);
Connectors.set("stats", Stats);
Connectors.set("general_typologies", GeneralTypologies);
Connectors.set("typologies", Typologies);
Connectors.set("modalities", Modalities);
Connectors.set("technologies", Technologies);
Connectors.set("value_chain_columns", ValueChainColumns);
Connectors.set("services", Services);
Connectors.set("companies", Companies);
Connectors.set("sites", Sites);
Connectors.set("filters", Filters);
Connectors.set("functions", Functions);
Connectors.set("check_api", CheckApi);

/**
 * Router
 */
import Router from "./router/index";
import { initFirewall } from "./router/index";
initFirewall(Store);

/**
 * CSS
 */
import "./assets/css/default.css";
import "./assets/css/layout.css";
import "./assets/css/section.css";

/**
 * INITIALISATION
 */
createApp(App)
  .use(Utils)
  .use(EventManager)
  .use(Router)
  .use(Store)
  .use(Connectors)
  .mount("#app");
