<template>
  <div v-show="props.slider.slide.value.id === props.index" :style="{width, height}" class="form-group p-2">
    <div style="min-height: 75%">
      <slot></slot>
    </div>
    <p v-if="showRequired"><RequiredIcon /> required</p>
    <p v-else class="py-3"></p>
    <div class="bg-secondary border rounded p-3 d-flex">
      <div class="w-50">
        <button v-if="useNavigation" v-show="!hidePrevious" :class="previousBtn.class" class="btn" :disabled="slider.slide.value.prev===null || disableBtnPrevious" @click.prevent="goPrevious">
          {{ previousBtn.text }} {{confirmation.previous.value? '(Confirm)' : ''}}
        </button>
      </div>
      <div class="w-50">
        <button v-if="useSubmit" :class="submitBtn.class" class="btn float-right ml-1" :disabled="disableBtnSubmit" @click="submit">{{ submitBtn.text }}</button>
        <button v-if="useNavigation" v-show="!hideNext" :class="nextBtn.class" class="btn float-right" :disabled="props.slider.slide.value.next===null || disableBtnNext" @click.prevent="goNext">
          {{ nextBtn.text }} {{confirmation.next.value? '(Confirm)' : ''}}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, ref} from "vue"
import {Slider} from "@/assets/js/Slider";
import RequiredIcon from "@/components/RequiredIcon.vue";

const props = defineProps({
  slider: {
    type: Slider,
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  height: {
    type: String,
    default: "95%"
  },
  width: {
    type: String,
    default: "100%"
  },
  previousBtn: {
    default: {
      class: "",
      text: "Previous"
    }
  },
  nextBtn: {
    default: {
      class: "btn-secondary",
      text: "Next"
    }
  },
  submitBtn: {
    default: {
      class: "btn-success",
      text: "Submit"
    }
  },
  useNavigation: {
    default: true
  },
  useSubmit: {
    default: true
  },
  needConfirmationPrevious: {
    default: false,
  },
  needConfirmationNext: {
    default: false,
  },
  showRequired: {
    default: true,
  },
  hideNext: {
    default: false,
  },
  hidePrevious: {
    default: false,
  },
  disableBtnNext: {
    default: false,
  },
  disableBtnPrevious: {
    default: false,
  },
  disableBtnSubmit: {
    default: false,
  },
})

const emit = defineEmits(["submit", "next", "previous"])

const confirmation = {
  previous: ref(false),
  next: ref(false),
}

const goPrevious = function () {
  if ((props.needConfirmationPrevious && confirmation.previous.value) || !props.needConfirmationPrevious) {
    props.slider.previousSlide()
    emit("previous")
    confirmation.previous.value = false
    confirmation.next.value = false
  } else if (props.needConfirmationPrevious) {
    confirmation.previous.value = true
  }
}

const goNext = function () {
  if ((props.needConfirmationNext && confirmation.next.value) || !props.needConfirmationNext) {
    props.slider.nextSlide()
    emit("next")
    confirmation.previous.value = false
    confirmation.next.value = false
  } else if (props.needConfirmationNext) {
    confirmation.next.value = true
  }
}

const submit = function () {
  emit("submit")
}



</script>

<style scoped>

</style>
