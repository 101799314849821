import Request from "./request";
import Utils from "../Utils";

const SERVER = "main";

export async function finder(model, query, limit, offset = 0, token) {
  try {
    return await Request.post(
      SERVER,
      `function/finder/paginate/${limit}/${offset}`,
      token,
      {
        model,
        query,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::finder::execute", error);
  }
}

export async function list(model, limit, offset = 0, token) {
  try {
    return await Request.post(
      SERVER,
      `function/list/paginate/${limit}/${offset}`,
      token,
      {
        model,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::list::execute", error);
  }
}


export async function filters(wanted, filters, limit, offset = 0, token) {
  try {
    return await Request.post(
      SERVER,
      `function/filters/paginate/${limit}/${offset}`,
      token,
      {
        wanted,
        filters,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::function::filters", error);

  }
}
