<template>
  <form class="w-50" :class="addClass" v-on:submit.prevent="formSearch.submit">
    <input
      class="w-75"
      type="text"
      ref="formSearch/query"
      :placeholder="title"
    />

    <button>
      <img src="../assets/icons/magnifier.svg" alt="" class="icon" />
    </button>
    <button v-if="result != null" class="btn-danger" v-on:click.prevent="reset">
      <img src="../assets/icons/cancel.svg" alt="" class="icon" />
    </button>
  </form>
</template>

<script>
import { createForm } from "@/assets/js/FormManager";

export default {
  name: "ArraySearchBar",
  props: {
    title: {
      default: "Search",
    },
    addClass: {
      default: "",
    },
    disabled: {
      default: false,
    },
    array: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      formSearch: createForm(this, "formSearch"),
      result: null,
    };
  },
  created: function () {
    this.formSearch.useLoading = false;
    this.formSearch.onSubmit((formData) => {
      this.$EventManager.triggerLoadingMinSetValue(50);
      this.result = this.array.filter((elm) => {
        return JSON.stringify(elm)
          .toLowerCase()
          .includes(formData.query.trim().toLowerCase());
      });
      this.$EventManager.triggerLoadingMinSetValue(100);
    });

    this.formSearch.afterSubmit(async () => {
      this.$emit("get-result", this.result);
    });
  },
  methods: {
    reset: function () {
      this.$refs["formSearch/query"].value = "";
      this.result = null;
      this.$emit("reset-result");
    },
  },
};
</script>

<style scoped></style>
