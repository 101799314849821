/* eslint-disable */
import DevConf from "../../../config/firebase.config.dev";
import ProdConf from "../../../config/firebase.config.prod";
import Utils from "../Utils";

import { getAuth as _getAuth,
  signInWithEmailAndPassword as _signInWithEmailAndPassword,
  signOut as _signOut,
  onAuthStateChanged as _onAuthStateChanged 
} from "firebase/auth";

import {
  initializeApp as _initializeApp
} from "firebase/app"



let firebaseConfig;
if (Utils.isProduction()) {
  firebaseConfig = ProdConf;
} else if (Utils.isDevelopment() || Utils.isTest()) {
  firebaseConfig = DevConf;
} else {
  firebaseConfig = DevConf;
}

_initializeApp(firebaseConfig);

const auth = _getAuth();

export async function signIn(email, password) {
  try {
    const userCredential = await _signInWithEmailAndPassword(auth, email, password);
    let user = userCredential.user;
    window.localStorage.setItem("uid", user.uid);
    window.localStorage.setItem("accessToken", user.accessToken);
    window.localStorage.setItem("email", user.email);
    window.localStorage.setItem("displayName", user.displayName);

    Utils.debug("signIn :: USER :", user);
    return true;
  }catch(error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    Utils.debug("signIn :: ERROR : ", error);
    return false;
  };
}

export async function signOut() {
  try{
    await _signOut(auth);
    window.localStorage.removeItem("uid");
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("email");
    window.localStorage.removeItem("displayName");
  }catch(error) {
    // An error happened.
  };
}

export function isConnected() {
  return window.localStorage.getItem("uid")!==null;
}

export function getUser() {
  return {
    uid: window.localStorage.getItem("uid"),
    accessToken: window.localStorage.getItem("accessToken"),
    email: window.localStorage.getItem("email"),
    displayName: window.localStorage.getItem("displayName"),
  };
}

export async function checkToken() {
  let expirationTime = auth.currentUser.stsTokenManager.expirationTime;
  if (expirationTime -(10*60) < (new Date()).getTime()) {
    let token = await auth.currentUser.getIdToken(true);
    window.localStorage.setItem("accessToken", token);
    return false
  }
  return true
}
/* eslint-enable */
