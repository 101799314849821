<template>
  <div v-if="isVisible" class="alert w-50 mx-auto" :class="'alert-'+type" role="alert">
    <button class="btn p-0 m-0 float-right" v-on:click="isVisible = false">X</button>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props:{
    type:{
      type: String,
      default: "primary"
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isVisible: false
    }
  },
  watch:{
    visible: function (value) {
      this.isVisible = value
    }
  }
}
</script>

<style scoped>
.alert{
  position: absolute;
  left: 50%;
  top: 25px;
  transform: translateX(-50%);
  z-index: 1000;
}
</style>
