<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-75">
        <img src="../../assets/icons/home.svg" alt="" class="icon" /> /
        <router-link :to="{ name: 'Modalities' }"> Modalities </router-link> /
        {{ modality.name }} /
        <router-link :to="{ name: 'Technologies' }"> Technologies </router-link
        >/
        {{ technology.name }}
      </div>
    </div>
    <div id="project" class="pt-5">
      <div class="row mx-0">
        <div class="col-md-6 mt-3">
          <div class="row">
            <h2>
              Typologies
              <button
                class="btn btn-success"
                v-on:click="formLinkTypology.show()"
              >
                <img
                  class="icon icon-white"
                  src="../../assets/icons/plus.svg"
                  alt=""
                />
              </button>
            </h2>
            <information class="ml-2">
              <p>
                Click on
                <button class="btn-success">
                  <img
                    style="width: 15px"
                    class="icon icon-white"
                    src="/img/plus.c767ee98.svg"
                  />
                </button>
                to associate a typology to your modality/techno universe.
              </p>
              <p>
                Click on
                <button class="btn-danger">
                  <img
                    style="width: 15px"
                    src="/img/broken-link.deeb77fc.svg"
                  />
                </button>
                to unassociate typology.
              </p>
            </information>
          </div>
          <section class="height-fit-content p-4">
            <table class="table table-striped scrollable">
              <thead>
                <tr>
                  <th scope="col">Typology</th>
                  <th scope="col">General Typology</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in sortedTypologies" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>{{ item.general_typology.name }}</td>
                  <td>
                    <button
                      class="btn btn-danger"
                      v-on:click="formUnlinkTypology.show(item)"
                    >
                      <img
                        class="icon"
                        src="../../assets/icons/broken-link.svg"
                        alt=""
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>

        <div class="col-md-6 mt-3">
          <div class="row">
            <h2>
              Value Chain columns
              <button
                class="btn btn-success"
                v-on:click="formCreateVChainC.show()"
              >
                <img
                  class="icon icon-white"
                  src="../../assets/icons/plus.svg"
                  alt=""
                />
              </button>
            </h2>
            <information class="ml-2">
              <p>The default value chain is already instantiated.</p>
              <p>
                The position determines the place of the column in the value
                chain from left to right.
              </p>
              <p>
                DD indicates if the column is integrated to the drug discovery.
              </p>
              <p>
                Click on
                <button class="btn-success">
                  <img
                    style="width: 15px"
                    class="icon icon-white"
                    src="/img/plus.c767ee98.svg"
                    alt=""
                  />
                </button>
                to add a new column.
              </p>
              <p>
                Click on
                <button class="btn-secondary">
                  <img style="width: 15px" src="/img/edit.99a3f983.svg" />
                </button>
                to edit a column.
              </p>
              <p>
                Click on
                <button class="btn-danger" data-v-12fbfcfa="">
                  <img style="width: 15px" src="/img/garbage.f32c8a85.svg" />
                </button>
                to remove a column.
              </p>
            </information>
          </div>
          <section class="height-fit-content p-4">
            <table class="table table-striped scrollable">
              <thead>
                <tr>
                  <th scope="col" class="w-25">Name</th>
                  <th scope="col">Position</th>
                  <th scope="col">DD</th>
                  <th scope="col">Date</th>
                  <th scope="col" class="w-25">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in sortedValueChainColumns" :key="item.id">
                  <td class="w-25">{{ item.name }}</td>
                  <td>{{ item.position }}</td>
                  <td>{{ item.is_drug_discovery ? "Yes" : "No" }}</td>
                  <td>{{ item.createdAt }}</td>
                  <td class="w-25">
                    <button
                      class="btn btn-secondary"
                      v-on:click="formUpdateVChainC.show(item)"
                    >
                      <img
                        class="icon"
                        src="../../assets/icons/edit.svg"
                        alt=""
                      />
                    </button>
                    <button
                      class="btn btn-danger"
                      v-on:click="formRemoveVChainC.show(item)"
                    >
                      <img
                        class="icon"
                        src="../../assets/icons/garbage.svg"
                        alt=""
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      </div>
      <div class="row">
        <h2 class="mt-5">
          Services
          <button class="btn btn-success" v-on:click="formCreateService.show()">
            <img
              class="icon icon-white"
              src="../../assets/icons/plus.svg"
              alt=""
            />
          </button>
        </h2>
        <information class="ml-2 mt-5">
          <p>
            Click on
            <button class="btn-success">
              <img
                style="width: 15px"
                class="icon icon-white"
                src="/img/plus.c767ee98.svg"
              />
            </button>
            to create a service .
          </p>
          <p>
            Click on
            <button class="btn-secondary">
              <img style="width: 15px" src="/img/edit.99a3f983.svg" />
            </button>
            to edit a service.
          </p>
          <p>
            Click on
            <button class="btn-success">
              <img style="width: 15px" src="/img/plus.c767ee98.svg" />
            </button>
            to add a sub-service a the current service.
          </p>
          <p>
            Click on
            <button class="btn-danger">
              <img style="width: 15px" src="/img/garbage.f32c8a85.svg" />
            </button>
            to remove a service.
          </p>
          <p>
            Click on
            <button class="btn-danger">Remove 'technology name'</button> to
            delete technology.
          </p>
          <p>
            Click on
            <button class="btn-secondary">Edit 'technology name'</button> to
            edit technology.
          </p>
        </information>
      </div>
      <section class="height-fit-content">
        <ArraySearchBar
          :array="services"
          v-on:get-result="(res) => (searchInServicesResult = res)"
          v-on:reset-result="
            (searchInServicesResult = null), (pagination.index = 0)
          "
        ></ArraySearchBar>
        <table class=" table table-striped">
          <thead>
            <tr>
              <th scope="col" >Name</th>
              <th scope="col">Typology</th>
              <th scope="col">Column</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filter_serviceOnPage" :key="item.id" >

              <td >{{ item.name }}</td>
              <td>{{ item.typology?.name }}</td>
              <td>{{ item.value_chain_column?.name }}</td>
              <!--<td>{{ item.createdAt }}</td>-->
              <td>
                <button
                  class="btn btn-secondary"
                  v-on:click="() => formEditService.show(item)"
                >
                  <img class="icon" src="../../assets/icons/edit.svg" alt="" />
                </button>
                <button
                  class="btn btn-success"
                  v-on:click="() => formCreateService.show(item)"
                >
                  <img class="icon" src="../../assets/icons/plus.svg" alt="" />
                </button>
                <button
                  class="btn btn-danger"
                  v-on:click="() => formRemoveService.show(item)"
                >
                  <img
                    class="icon"
                    src="../../assets/icons/garbage.svg"
                    alt=""
                  />
                </button>
                <button v-if="item.children_length > 0 && !item?.children" class="btn btn-outline-secondary"
                        v-on:click="get_children(index)"
                >
                  <img
                    class="icon"
                    src="@/assets/icons/folderTree.svg"
                    alt=""
                  />
                </button>
                <button v-if="item.children_length > 0 && item?.children" class="btn btn-warning"
                        v-on:click="item.children = null"
                >
                  <img
                    class="icon"
                    src="@/assets/icons/folderTree.svg"
                    alt=""
                  />
                </button>
              </td>
                  <table class="col-12"  v-show="item?.children">
                    <thead>
                    <tr >
                      <th scope="col" >Name</th>
                      <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr v-for="child in item?.children" :key="child.id">
                        <td class="w-25">{{ child.name }}</td>
                        <td>
                          <button
                          class="btn btn-secondary"
                          v-on:click="() => formEditService.show(child)"
                        >
                          <img class="icon" src="../../assets/icons/edit.svg" alt="" />
                        </button>
                          <button
                            class="btn btn-success"
                            v-on:click="() => formCreateService.show(child)"
                          >
                            <img class="icon" src="../../assets/icons/plus.svg" alt="" />
                          </button>
                          <button
                            class="btn btn-danger"
                            v-on:click="() => formRemoveService.show(child)"
                          >
                            <img
                              class="icon"
                              src="../../assets/icons/garbage.svg"
                              alt=""
                            />
                          </button>
                          <button v-if="child.children_length > 0 && !child?.children" class="btn btn-primary"
                                  v-on:click="get_children(index)"
                          >
                            sub-services
                          </button>
                          <button v-if="child.children_length > 0 && child?.children" class="btn btn-primary"
                                  v-on:click="child.children = null"
                          >
                            sub-services c
                          </button>
                          </td>
                      </tr>
                    </tbody>
                  </table>
            </tr>
          </tbody>
        </table>
        <div class="d-flex w-100">
          <pagination
            class="w-75"
            :index="pagination.index"
            :index-max="
              searchInServicesResult
                ? searchInServicesResult.length
                : services_without_parents.length
            "
            :offset="pagination.offset"
            @previous-page="previousPage"
            @next-page="nextPage"
          />
          <div class="w-25 text-right">
            {{ filter_serviceOnPage.length }} item(s)
          </div>
        </div>
      </section>
    </div>

    <template #toolbar>
      <button
        class="btn btn-danger float-right"
        v-on:click="formRemoveTechnology.show"
      >
        Remove '{{ technology.name }}'
      </button>
      <button
        class="btn btn-secondary float-right"
        v-on:click="formEditTechnology.show"
      >
        Edit '{{ technology.name }}'
      </button>
    </template>
  </content-panel>

  <modal :title="'delete'" :active="false" :width="30" :height="30">
    <div class="px-5">
      <p>Are you sure ?</p>
      <p>You will remove the project named</p>
      <button class="btn btn-danger w-100 mt-3 py-3">
        <img class="icon" src="../../assets/icons/garbage.svg" alt="" />
        <span>Remove</span>
      </button>
    </div>
  </modal>

  <modal
    title="Link typology"
    v-on:modal-close="formLinkTypology.close()"
    :active="formLinkTypology.visible.value"
    :width="50"
    :height="50"
  >
    <form class="p-5 text-center" v-on:submit.prevent="formLinkTypology.submit">
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>
            General Typology
            <div
              v-if="formLinkTypology.storage.selected_general_typology_loading"
              class="spinner-border spinner-border-sm ml-2"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </th>
          <td class="w-50">
            <select
              ref="linkTypology/general_typology"
              class="form-control"
              required
              v-model="formLinkTypology.storage.selected_general_typology"
              v-on:change="
                formLinkTypology.trigger(
                  'selectGeneralTypo',
                  formLinkTypology.storage.selected_general_typology
                )
              "
            >
              <option value="" selected disabled>
                Select a general typology
              </option>
              <option
                v-for="item in formLinkTypology.storage.general_typologies"
                :key="item.id"
                :value="JSON.stringify(item)"
              >
                {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>
            Typology
            <div
              v-if="formLinkTypology.storage.selected_typology_loading"
              class="spinner-border spinner-border-sm ml-2"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </th>
          <td class="w-50">
            <select ref="linkTypology/typology" class="form-control" required>
              <option value="" selected disabled>Select a typology</option>
              <option
                v-for="item in formLinkTypology.storage.typologies"
                :key="item.id"
                :value="JSON.stringify(item)"
              >
                {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
      </table>
      <input
        ref="linkTypology/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Link</button>
    </form>
  </modal>
  <modal
    title="Unlink Typology"
    v-on:modal-close="formUnlinkTypology.close()"
    :active="formUnlinkTypology.visible.value"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formUnlinkTypology.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="unlinkTypology/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="formUnlinkTypology.data_given.name"
              disabled
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="unlinkTypology/createdAt"
              class="w-100"
              type="text"
              placeholder="Date"
              :value="formUnlinkTypology.data_given.createdAt"
              disabled
            />
          </td>
        </tr>
        <tr style="color: red">
          <th>Impact</th>
          <td>
            <p>
              This action will delete the link between the typology named '{{
                formUnlinkTypology.data_given.name
              }}' and the technology named '{{ technology.name }}'.
            </p>
            <p>
              The existant services which depend on this typology will no be
              deleted but will encour some issues
            </p>
          </td>
        </tr>
      </table>
      <input
        ref="unlinkTypology/id"
        class="w-100"
        type="hidden"
        :value="formUnlinkTypology.data_given.id"
      />
      <input
        ref="unlinkTypology/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-danger mt-5" type="submit">remove</button>
    </form>
  </modal>

  <modal
    title="Create a new column"
    v-on:modal-close="formCreateVChainC.close()"
    :active="formCreateVChainC.visible.value"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formCreateVChainC.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="createVChainC/name"
              class="w-100"
              type="text"
              placeholder="Name"
            />
          </td>
        </tr>
        <tr>
          <th>Position</th>
          <td>
            <input
              ref="createVChainC/position"
              class="w-100"
              type="number"
              placeholder="Position"
            />
          </td>
        </tr>
        <tr>
          <th>Is Drug Discovery ?</th>
          <td>
            <input
              ref="createVChainC/is_drug_discovery"
              class=""
              type="checkbox"
              placeholder="is_drug_discovery"
            />
          </td>
        </tr>
      </table>
      <input
        ref="createVChainC/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>

  <modal
    title="Edit a column"
    v-on:modal-close="formUpdateVChainC.close()"
    :active="formUpdateVChainC.visible.value"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formUpdateVChainC.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="updateVChainC/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="formUpdateVChainC.data_given.name"
            />
          </td>
        </tr>
        <tr>
          <th>Position</th>
          <td>
            <input
              ref="updateVChainC/position"
              class="w-100"
              type="number"
              placeholder="Position"
              :value="formUpdateVChainC.data_given.position"
            />
          </td>
        </tr>
        <tr>
          <th>Is Drug Discovery ?</th>
          <td>
            <input
              ref="updateVChainC/is_drug_discovery"
              class=""
              type="checkbox"
              placeholder="is_drug_discovery"
              :checked="formUpdateVChainC.data_given.is_drug_discovery"
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="updateVChainC/createdAt"
              class="w-100"
              type="text"
              placeholder="date"
              disabled
              :value="formUpdateVChainC.data_given.createdAt"
            />
          </td>
        </tr>
      </table>
      <input
        ref="updateVChainC/id"
        class="w-100"
        type="hidden"
        :value="formUpdateVChainC.data_given.id"
      />
      <input
        ref="updateVChainC/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>

  <modal
    title="Remove a column"
    v-on:modal-close="formRemoveVChainC.close()"
    :active="formRemoveVChainC.visible.value"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formRemoveVChainC.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="removeVChainC/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="formRemoveVChainC.data_given.name"
              disabled
            />
          </td>
        </tr>
        <tr>
          <th>Position</th>
          <td>
            <input
              ref="removeVChainC/position"
              class="w-100"
              type="number"
              placeholder="Position"
              :value="formRemoveVChainC.data_given.position"
              disabled
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="removeVChainC/createdAt"
              class="w-100"
              type="text"
              placeholder="createdAt"
              :value="formRemoveVChainC.data_given.createdAt"
              disabled
            />
          </td>
        </tr>
        <tr>
          <th>Is Drug Discovery ?</th>
          <td>
            <input
              ref="removeVChainC/is_drug_discovery"
              class="w-100"
              type="checkbox"
              placeholder="is_drug_discovery"
              :checked="formRemoveVChainC.data_given.is_drug_discovery"
              disabled
            />
          </td>
        </tr>
      </table>
      <input
        ref="removeVChainC/id"
        class="w-100"
        type="hidden"
        :value="formRemoveVChainC.data_given.id"
      />
      <input
        ref="removeVChainC/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-danger mt-5" type="submit">Remove</button>
    </form>
  </modal>

  <modal
    :title="
      formCreateService.data_given?.id ? 'Create sub-service' : 'Create service'
    "
    v-on:modal-close="formCreateService.close()"
    :active="formCreateService.visible.value"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formCreateService.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr v-if="formCreateService.data_given?.id">
          <th>Parent</th>
          <td>
            <select ref="createService/parent_id" class="form-control" disabled>
              <option :value="formCreateService.data_given.id" selected>
                {{ formCreateService.data_given.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="createService/name"
              class="w-100"
              type="text"
              placeholder="Name"
              required
            />
          </td>
        </tr>
        <tr v-show="!formCreateService.data_given?.id">
          <th>Typology</th>
          <td>
            <select
              ref="createService/typology_id"
              class="form-control"
              required
              :disabled="formCreateService.data_given?.id"
            >
              <option
                value=""
                :selected="!formCreateService.data_given?.id"
                disabled
              >
                Select a typology
              </option>
              <option
                v-for="item in this.typologies"
                :key="item.id"
                :value="item.id"
                :selected="
                  formCreateService.data_given?.typology?.id === item.id
                "
              >
                {{ item.general_typology.name }} / {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr v-show="!formCreateService.data_given?.id">
          <th>Value chain column</th>
          <td>
            <select
              ref="createService/value_chain_column_id"
              class="form-control"
              required
              :disabled="formCreateService.data_given?.id"
            >
              <option
                value=""
                :selected="!formCreateService.data_given?.id"
                disabled
              >
                Select a column
              </option>
              <option
                v-for="item in this.value_chain_columns"
                :key="item.id"
                :value="item.id"
                :selected="
                  formCreateService.data_given?.value_chain_column?.id ===
                  item.id
                "
              >
                {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>Sub-service</th>
          <td>
            <ul class="list-unstyled">
              <li
                v-for="(child, index) in formCreateService.storage.children"
                :key="index"
                class="d-flex"
              >
                <input
                  v-model="formCreateService.storage.children[index]"
                  class="form-control"
                  type="text"
                  placeholder="Sub-service Name"
                  v-on:keydown="
                    () => {
                      if (
                        formCreateService.storage.children[index] == '' &&
                        index == formCreateService.storage.children.length - 1
                      ) {
                        formCreateService.storage.children.push('');
                      }
                    }
                  "
                />
                <button
                  :disabled="
                    index == formCreateService.storage.children.length - 1
                  "
                  v-on:click.prevent="
                    () => {
                      formCreateService.storage.children.splice(index, 1);
                    }
                  "
                  class="btn btn-danger"
                >
                  -
                </button>
              </li>
            </ul>
          </td>
        </tr>
      </table>
      <input
        v-if="!formCreateService.data_given?.id"
        ref="createService/parent_id"
        class="w-100"
        type="hidden"
        :value="null"
      />
      <input
        ref="createService/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>
  <modal
    title="Edit service"
    v-on:modal-close="formEditService.close()"
    :active="formEditService.visible.value"
    :width="50"
    :height="50"
  >
    <form class="p-5 text-center" v-on:submit.prevent="formEditService.submit">
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Parent</th>
          <td>
            <select
              ref="editService/parent_id"
              class="form-control"
              :disabled="formEditService.data_given.parent?.id"
            >
              <option
                value=""
                :selected="!formEditService.data_given.parent?.id"
                disabled
              >
                No parent
              </option>
              <option
                v-for="item in this.services"
                :key="item.id"
                :value="item.id"
                :selected="formEditService.data_given?.parent_id === item.id"
              >
                {{ item.name }}
                <i
                  >({{ item.typology.name }} /
                  {{ item.value_chain_column.name }})</i
                >
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="editService/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="formEditService.data_given.name"
            />
          </td>
        </tr>
        <tr v-show="!formEditService.data_given.parent?.id">
          <th>Typology</th>
          <td>
            <select
              ref="editService/typology_id"
              class="form-control"
              required
              :disabled="formEditService.data_given.parent?.id"
            >
              <option
                value=""
                :selected="!formEditService.data_given.parent?.id"
                disabled
              >
                Select a typology
              </option>
              <option
                v-for="item in this.typologies"
                :key="item.id"
                :value="item.id"
                :selected="formEditService.data_given?.typology?.id === item.id"
              >
                {{ item.general_typology.name }} / {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr v-show="!formEditService.data_given.parent?.id">
          <th>Value chain column</th>
          <td>
            <select
              ref="editService/value_chain_column_id"
              class="form-control"
              required
              :disabled="formEditService.data_given.parent?.id"
            >
              <option
                v-for="item in this.value_chain_columns"
                :key="item.id"
                :value="item.id"
                :selected="
                  formEditService.data_given?.value_chain_column?.id === item.id
                "
              >
                {{ item.name }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="editService/createdAt"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="formEditService.data_given.createdAt"
              disabled
            />
          </td>
        </tr>
      </table>
      <input
        ref="editService/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <input
        ref="editService/id"
        class="w-100"
        type="hidden"
        :value="formEditService.data_given.id"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>

  <modal
    title="Remove service"
    v-on:modal-close="formRemoveService.close()"
    :active="formRemoveService.visible.value"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formRemoveService.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="removeService/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="formRemoveService.data_given.name"
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="removeService/createdAt"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="formRemoveService.data_given.createdAt"
              disabled
            />
          </td>
        </tr>
        <tr style="color: red">
          <th>Impact</th>
          <td>
            <p>This action will:</p>
            <ul>
              <li>
                delete the service named '{{
                  formRemoveService.data_given.name
                }}'
              </li>
              <li>remove all of his sub-service</li>
              <li>
                remove the service from all sites/companies where it was added
              </li>
            </ul>
          </td>
        </tr>
      </table>
      <input
        ref="removeService/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />

      <input
        ref="removeService/id"
        class="w-100"
        type="hidden"
        :value="formRemoveService.data_given.id"
      />

      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>

  <modal
    :title="`Edit ${technology.name}`"
    v-on:modal-close="formEditTechnology.close()"
    :active="formEditTechnology.visible.value"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formEditTechnology.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="editTechnology/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="technology.name"
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="editTechnology/createdAt"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="technology.createdAt"
              disabled
            />
          </td>
        </tr>
      </table>
      <input
        ref="editTechnology/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />

      <input
        ref="editTechnology/id"
        class="w-100"
        type="hidden"
        :value="technology.id"
      />

      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>

  <modal
    :title="`Remove ${technology.name}`"
    v-on:modal-close="formRemoveTechnology.close()"
    :active="formRemoveTechnology.visible.value"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formRemoveTechnology.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="removeTechnology/name"
              class="w-100"
              type="text"
              placeholder="Name"
              :value="technology.name"
              disabled
            />
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            <input
              ref="removeTechnology/createdAt"
              class="w-100"
              type="text"
              placeholder="Date"
              :value="technology.createdAt"
              disabled
            />
          </td>
        </tr>
        <tr style="color: red">
          <th>Impact</th>
          <td>
            <p>
              This action will delete the technology named '{{
                technology.name
              }}' and his associations.
            </p>
            <p>
              Reminder: A technology is link with value chain columns, services,
              typologies, companies, sites, etc..
            </p>
          </td>
        </tr>
      </table>
      <input
        ref="removeTechnology/id"
        class="w-100"
        type="hidden"
        :value="technology.id"
      />
      <input
        ref="removeTechnology/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal.vue";
import { createForm } from "../../assets/js/FormManager";
import Pagination from "../../components/Pagination";
import ArraySearchBar from "../../components/ArraySearchBar";
import Information from "../../components/Information";

export default {
  name: "TechnologieItem",
  components: { ArraySearchBar, ContentPanel, Modal, Pagination, Information },
  props: {
    technology_id: {
      required: true,
    },
    modality_id: {
      required: true,
    },
    modality_json: {
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      technology: {
        id: null,
        name: null,
        createdAt: null,
      },
      modality: {
        id: null,
        name: null,
        createdAt: null,
      },
      pagination: {
        index: 0,
        indexMax: 0,
        offset: 10,
      },
      typologies: [],
      value_chain_columns: [],
      services: [],
      searchInServicesResult: null,
      isShow: false,
      formLinkTypology: createForm(this, "linkTypology"),
      formUnlinkTypology: createForm(this, "unlinkTypology"),
      formCreateVChainC: createForm(this, "createVChainC"),
      formRemoveVChainC: createForm(this, "removeVChainC"),
      formUpdateVChainC: createForm(this, "updateVChainC"),
      formCreateService: createForm(this, "createService"),
      formEditService: createForm(this, "editService"),
      formRemoveService: createForm(this, "removeService"),
      formEditTechnology: createForm(this, "editTechnology"),
      formRemoveTechnology: createForm(this, "removeTechnology"),
    };
  },
  created: async function () {
    this.$EventManager.triggerLoadingStart();
    if (this.modality_json) {
      this.modality = JSON.parse(this.modality_json);
    } else {
      this.modality = await this.$API
        .get("modalities")
        .read(this.modality_id, this.$store.getters.token);
    }
    this.$EventManager.triggerLoadingEnd();

    this.formEditTechnology.onSubmit(async (formData) => {
      await this.$API
        .get("technologies")
        .update(this.modality_id, formData, this.$store.getters.token);
    });
    this.formEditTechnology.afterSubmit(async () => {
      this.formEditTechnology.close();
      await this.reload();
    });

    this.formRemoveTechnology.onSubmit(async (formData) => {
      await this.$API
        .get("technologies")
        .remove(this.modality_id, formData.id, this.$store.getters.token);
    });
    this.formRemoveTechnology.afterSubmit(async () => {
      this.formEditTechnology.close();
      this.$router.push({
        name: "Technologies",
        params: { modality_id: this.modality_id },
      });
    });

    this.formLinkTypology.storage = {
      selected_general_typology: "",
      selected_general_typology_loading: false,
      general_typologies: [],
      typologies: [],
    };

    this.formCreateService.storage = {
      children: [""],
    };

    this.formLinkTypology.beforeShow(async () => {
      this.formLinkTypology.storage.selected_general_typology_loading = true;
      let res = await this.$API
        .get("general_typologies")
        .list(50, 0, this.$store.getters.token);
      this.formLinkTypology.storage.general_typologies = res.items;
      this.formLinkTypology.storage.selected_general_typology_loading = false;
    });

    this.formLinkTypology.onSubmit(async (formData) => {
      let typo = JSON.parse(formData.typology);
      await this.$API
        .get("technologies")
        .add_typology(
          this.modality_id,
          this.technology_id,
          typo,
          this.$store.getters.token
        );
      this.formLinkTypology.close();
      await this.reload();
    });

    this.formLinkTypology.subscribe("selectGeneralTypo", async (data) => {
      this.formLinkTypology.storage.selected_typology_loading = true;
      let gtypo = JSON.parse(data[0]);
      let res = await this.$API
        .get("typologies")
        .list(gtypo.id, 50, 0, this.$store.getters.token);
      this.formLinkTypology.storage.typologies = res.items;
      this.formLinkTypology.storage.selected_typology_loading = false;
    });

    this.formUnlinkTypology.onSubmit(async (formData, item) => {
      await this.$API
        .get("technologies")
        .remove_typology(
          this.modality_id,
          this.technology_id,
          item,
          this.$store.getters.token
        );
      this.formUnlinkTypology.close();
      await this.reload();
    });

    this.formCreateVChainC.onSubmit(async (formData) => {
      await this.$API
        .get("value_chain_columns")
        .create(
          this.modality_id,
          this.technology_id,
          formData,
          this.$store.getters.token
        );
      this.formCreateVChainC.close();
      await this.reload();
    });

    this.formRemoveVChainC.onSubmit(async (formData) => {
      await this.$API
        .get("value_chain_columns")
        .remove(
          this.modality_id,
          this.technology_id,
          formData,
          this.$store.getters.token
        );
      this.formRemoveVChainC.close();
      await this.reload();
    });

    this.formUpdateVChainC.onSubmit(async (formData) => {
      await this.$API
        .get("value_chain_columns")
        .update(
          this.modality_id,
          this.technology_id,
          formData,
          this.$store.getters.token
        );
      this.formUpdateVChainC.close();
      await this.reload();
    });

    this.formCreateService.onSubmit(async (formData) => {
      let service = await this.$API
        .get("services")
        .create(
          this.modality_id,
          this.technology_id,
          formData,
          this.$store.getters.token
        );
      this.formCreateService.close();

      let children = this.formCreateService.storage.children.filter(
        (el) => el !== ""
      );

      let promises = children.map((child) => {
        return this.$API.get("services").create(
          this.modality_id,
          this.technology_id,
          {
            ...formData,
            name: child,
            parent_id: service.id,
          },
          this.$store.getters.token
        );
      });

      await Promise.all(promises);

      this.formCreateService.storage = {
        children: [""],
      };
      await this.reload();
    });

    this.formEditService.onSubmit(async (formData) => {
      await this.$API
        .get("services")
        .update(
          this.modality_id,
          this.technology_id,
          formData,
          this.$store.getters.token
        );
      this.formEditService.close();
      this.formCreateService.storage = {
        children: [""],
      };
      await this.reload();
    });

    this.formRemoveService.onSubmit(async (formData) => {
      await this.$API
        .get("services")
        .remove(
          this.modality_id,
          this.technology_id,
          formData.id,
          this.$store.getters.token
        );
      this.formRemoveService.close();
      await this.reload();
    });

    await this.reload();
  },
  computed: {
    services_without_parents: function (){
      return this.services.filter( (service) => service.parent_id == "None")
    },

    sortedTypologies: function () {
      return this.typologies;
    },
    sortedServices: function () {
      return [...this.services_without_parents].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
    sortedValueChainColumns: function () {
      return [...this.value_chain_columns]
        .sort((a, b) => {
          return a.position - b.position;
        })
        .map((vcc) => {
          return {
            ...vcc,
            is_drug_discovery: vcc.is_drug_discovery == "True",
          };
        });
    },
    filter_serviceOnPage: function () {
      let arr = this.searchInServicesResult
        ? this.searchInServicesResult
        : this.services_without_parents;
      return [...arr]
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
        .slice(
          this.pagination.index,
          this.pagination.index + this.pagination.offset
        );
    },
  },
  methods: {
    reload: async function (withTriggerLoading = true) {
      if (withTriggerLoading) {
        this.$EventManager.triggerLoadingStart();
      }
      this.technology = await this.$API
        .get("technologies")
        .read(this.modality_id, this.technology_id, this.$store.getters.token);

      let get_typologies = await this.$API
        .get("technologies")
        .get_typologies(
          this.modality_id,
          this.technology_id,
          this.$store.getters.token
        );
      this.typologies = get_typologies.typologies;

      let get_value_chain_columns = await this.$API
        .get("value_chain_columns")
        .list(this.modality_id, this.technology_id, this.$store.getters.token);
      this.value_chain_columns = get_value_chain_columns.value_chain_columns;

      let get_services = await this.$API
        .get("technologies")
        .get_services(
          this.modality_id,
          this.technology_id,
          this.$store.getters.token
        );
      this.services = get_services.services;


      if (withTriggerLoading) {
        this.$EventManager.triggerLoadingEnd();
      }
    },

    async  get_children (index){
      let res = await this.$API
        .get("functions")
        .finder("service",`parent_id=${this.filter_serviceOnPage[index].id}`,20,0,this.$store.getters.token);
      this.filter_serviceOnPage[index].children = res.items;
    },

    previousPage: async function () {
      this.pagination.index -= this.pagination.offset;
    },
    nextPage: async function () {
      this.pagination.index += this.pagination.offset;
    },
  },
};
</script>

<style scoped>
table.scrollable tbody {
  display: block;
  max-height: 350px;
  overflow-y: scroll;
  /*-ms-overflow-style: none;
  scrollbar-width: none;*/
}
table.scrollable thead,
table.scrollable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
/*
table.scrollable tbody::-webkit-scrollbar {
  display: none;
}*/
</style>
