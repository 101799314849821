import {computed, reactive, ref, watch} from "vue";
import EventBroker from "@/assets/js/EventBroker";


export class Slider extends EventBroker{
    constructor() {
        super()
        this.slides = reactive([])

        this.currentSlideId = ref(0)

        this.slide = computed(() => {
            return this.slides.find(s => s.id == this.currentSlideId.value)
        })

        this.dynamicSlides = computed(() => {
            return this.slides.filter(s => s.dynamic)
        })


        this.firstSlide = computed(() => {
            return this.slides.find(s => s.prev === null)
        })

        this.lastSlide = computed(() => {
            return this.slides.find(s => s.next === null)
        })

      watch(this.slide, (n, o) => {
        this.trigger("slider-slide-change", n, o)
      })
    }

    nextSlide() {
        if (this.slide.value && this.slide.value.next !== null) {
            let current = this.slides.find(s => s.id === this.currentSlideId.value)
            this.currentSlideId.value = current.next
        }
    }
    previousSlide() {
        if (this.slide.value && this.slide.value.prev !== null) {
            this.currentSlideId.value = this.slide.value.prev
        }
    }

    addDynamicSlide(id, number = 1, isProtected = false, returned_ids = []) {
        let targetPosition = this.slides.findIndex(s => id === s.id)
        if (targetPosition > -1 && number > 0) {
            let s = {
                id: this.slides.length,
                next: this.slides[targetPosition].next,
                prev: this.slides[targetPosition].id,
                protected: isProtected,
                dynamic: true
            }
            let nextPosition = this.slides.findIndex(ss => ss.id === this.slides[targetPosition].next)
            if (nextPosition>-1){
                this.slides[nextPosition].prev = s.id
            }
            this.slides[targetPosition].next = s.id
            this.slides.push(s)
            return this.addDynamicSlide(s.id, number - 1, isProtected, [...returned_ids, s.id])
        } else if (number <= 0){
          return returned_ids
        }
    }

    addStaticSlide(id, next, prev, isProtected, isDynamic) {
        this.slides.push({id, next, prev, protected: isProtected, dynamic: isDynamic})
    }

    removeSlide(id, number = 1) {
        let targetPosition = this.slides.findIndex(s => id === s.id)
        if (targetPosition > -1 && number > 0 && this.currentSlideId.value !== id && !this.slides[targetPosition].protected) {
            let prevPosition = this.slides.findIndex(s => s.id === this.slides[targetPosition].prev)
            let nextPosition = this.slides.findIndex(s => s.id === this.slides[targetPosition].next)

            if (prevPosition > -1) this.slides[prevPosition].next = this.slides[targetPosition].next;
            if (nextPosition > -1) this.slides[nextPosition].prev = this.slides[targetPosition].prev;

            this.removeSlide(this.slides[targetPosition].next, number - 1)
            this.slides.splice(targetPosition, 1)
        }
    }
}

export const useSlider = function (elements = []){
    const slider = new Slider()
    for (const element of elements) {
        slider.slides.push(element)
    }
    slider.currentSlideId.value = slider.firstSlide.value.id
    return slider
}
