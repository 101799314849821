import Request from "./request";
import Utils from "../Utils";

const SERVER = "main";

export async function list(general_typology_id, limit, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `general_typology/${general_typology_id}/typologies/paginate/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::typologies::list", error);
  }
}

//  TODO: not implemented in the API
export async function filter(
  general_typology_id,
  name,
  limit,
  offset = 0,
  token
) {
  try {
    return await Request.get(
      SERVER,
      `general_typology/${general_typology_id}/typologies/filter/${name}/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::typologies::filter", error);
  }
}

export async function create(general_typology_id, typology, token) {
  try {
    return await Request.post(
      SERVER,
      `general_typology/${general_typology_id}/typologies`,
      token,
      {
        typology,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::typologies::create", error);
  }
}

export async function read(general_typology_id, id, token) {
  try {
    return await Request.get(
      SERVER,
      `general_typology/${general_typology_id}/typology/${id}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::typology::read", error);
  }
}

export async function update(general_typology_id, typology, token) {
  try {
    return await Request.put(
      SERVER,
      `general_typology/${general_typology_id}/typology/${typology.id}`,
      token,
      {
        typology,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::typology::update", error);
  }
}

export async function remove(general_typology_id, typology, token) {
  try {
    return await Request.delete(
      SERVER,
      `general_typology/${general_typology_id}/typology/${typology.id}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::typology::remove", error);
  }
}
