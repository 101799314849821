<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-50">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / Filters
      </div>
      <form class="w-50">
        <input class="w-75" type="text" name="" id="" placeholder="Search" />
        <button>
          <img src="../../assets/icons/magnifier.svg" alt="" class="icon" />
        </button>
      </form>
    </div>
    <div class="pt-5">
      <section class="height-fit-content">
        <div class="row mx-0">
          <filter-item
            v-for="item in filters"
            :key="item.uid"
            @remove-item="(uid) => removeFilter(uid)"
            @update="(update) => updateFilter(item.uid, update)"
            :name="item.uid"
            :has-next="item.uid !== filters[filters.length - 1].uid"
            :is-wanted="item.uid === filters[0].uid"
          />
          <filter-item :create-btn="true" @create-item="createFilter" />
        </div>
      </section>
    </div>

    <div v-if="items.length > 0" class="pt-5">
      <section class="height-fit-content">
        <table class="table table-striped w-100">
          <thead>
            <tr>
              <th v-for="(field, index) in itemFields" :key="index">
                {{ field }}
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td
                v-for="(elm, index) in itemFields"
                :key="index + '.' + item.id"
              >
                {{ item[elm] }}
              </td>
              <td>
                <router-link
                  :to="item.route"
                  class="btn btn-secondary"
                  target="_blank"
                  ><img
                    src="../../assets/icons/magnifier.svg"
                    alt=""
                    class="icon"
                /></router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
    <div v-else>
      <p v-if="!loading" class="w-100 text-center p-5">No result found !</p>
    </div>

    <template #toolbar>
      <button
        class="btn btn-success"
        :disabled="filters.length < 1"
        v-on:click="execute"
      >
        Compute
      </button>
      <button class="btn btn-danger float-right" v-on:click="filters = []">
        Clear all
      </button>
    </template>
  </content-panel>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import FilterItem from "../../components/FilterItem";
export default {
  name: "Filters",
  components: { ContentPanel, FilterItem },
  data: function () {
    return {
      filters: [],
      items: [],
      count: 0,
      loading: false,
    };
  },
  computed: {
    itemFields: function () {
      return this.items.length > 0
        ? Object.keys(this.items[0]).filter((elm) => elm !== "route")
        : [];
    },
  },
  methods: {
    createFilter: function () {
      this.filters.push({ uid: this.$uid() });
    },
    removeFilter: function (uid) {
      let index = this.filters.findIndex((elm) => elm.uid === uid);
      this.filters.splice(index, 1);
    },
    updateFilter: function (uid, update) {
      let index = this.filters.findIndex((elm) => elm.uid === uid);
      this.filters[index] = update;
      this.$router.replace(
        {
          name: "Filters",
          query: { q: btoa(encodeURIComponent(JSON.stringify(this.filters))) },
        },
        { silent: true }
      );
    },

    execute: async function () {
      this.$EventManager.triggerLoadingMinSetValue(20);
      this.loading = true;
      this.items = [];
      this.count = 0;
      let filters_copy = [...this.filters];
      for (let index = 0; index < filters_copy.length; index++) {
        filters_copy[index].entity = filters_copy[index].entity
          .toLowerCase()
          .replaceAll(" ", "_");
        let f = {};
        for (const key of Object.keys(filters_copy[index].fields)) {
          f[key.toLowerCase().replaceAll(" ", "_")] =
            filters_copy[index].fields[key];
        }
        filters_copy[index].fields = f;
      }

      let wanted = filters_copy[0];
      if (Object.keys(wanted.fields).length == 0) {
        filters_copy.shift();
      }
      let res = await this.$API
        .get("filters")
        .execute(wanted, filters_copy, 20, 0, this.$store.getters.token);

      if (wanted.entity === "site") {
        this.items = res.items.map((elm) => {
          return {
            route: {
              name: "SiteItem",
              params: { site_id: elm.id },
            },
            City: elm.city,
            Label: elm.label,
            Country: JSON.parse(elm.country).name,
            Continent: JSON.parse(elm.country).continent,
            "Build At": elm.build_at,
          };
        });
      } else if (wanted.entity === "company") {
        this.items = res.items.map((elm) => {
          return {
            route: {
              name: "CompanyItem",
              params: { company_id: elm.id },
            },
            Name: elm.name,
            Headquarter: `${JSON.parse(elm.headquarter).name} (${
              JSON.parse(elm.headquarter).continent
            })`,
          };
        });
      } else {
        this.items = res.items.map((elm) => {
          return {
            route: { name: "Home" },
            ...elm,
          };
        });
      }
      this.count = res.count;
      this.loading = false;
      this.$EventManager.triggerLoadingMinSetValue(100);
    },
  },
};
</script>

<style></style>
