
export default {
  state() {
    return {
      data: {
        API_IS_DOWN: false
      },
    };
  },
  getters: {
    API_IS_DOWN: function (state) {
      return state.data.API_IS_DOWN;
    },
  },
  actions: {

  },
  mutations: {
    setApiStatus: function (state, value) {
      state.data.API_IS_DOWN = value;
    },
  },
};
