

export const Message = (message, type = 'primary') => {
  return {
    message,
    type
  }
}

export const CreateMessage = (success = false) => {
  return Message(
    success? "Your item have been added": "Your item have not been saved",
    success? "success" : "danger"
  )
}

export const UpdateMessage = (success = false) => {
  return Message(
    success? "Your changes have been saved": "Your changes have not been saved",
    success? "success" : "danger"
  )
}

export const DeleteMessage = (success = false) => {
  return Message(
    success? "Your item have been deleted": "Your item have not been deleted",
    success? "success" : "danger"
  )
}

