import { createStore } from "vuex";
import UserModule from "./modules/user";
import SysModule from "./modules/sys";

export default createStore({
  modules: {
    user: UserModule,
    sys: SysModule,
  },
});
