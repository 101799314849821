<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-50">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / Logs
      </div>
      <form class="w-50">
        <input class="w-75" type="text" name="" id="" placeholder="Search" />
        <button>
          <img src="../../assets/icons/magnifier.svg" alt="" class="icon" />
        </button>
      </form>
    </div>

    <div class="pt-5">
      <section class="height-fit-content">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items.sort().reverse()" :key="item">
              <td class="p-4">{{ item }}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-secondary"
                  v-on:click="displaylog(item, file)"
                >
                  <img
                    class="icon"
                    src="../../assets/icons/magnifier.svg"
                    alt="magnifier"
                  />
                </button>
                <button
                  type="button"
                  class="btn btn-warning"
                  v-on:click="downloadFile(item)"
                  :download="item"
                >
                  <img
                    class="icon"
                    src="../../assets/icons/download.svg"
                    alt="download"
                  />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <template #toolbar> </template>
  </content-panel>
  <modal
    :title="'log ' + logDetails.name"
    :active="logDetails.show"
    :width="70"
    :height="70"
    v-on:modal-close="logDetails.show = false"
  >
    <div class="w-100 h-100">
      <textarea
        class="w-100 h-100"
        disabled
        :value="logDetails.content"
      ></textarea>
    </div>
  </modal>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal.vue";
export default {
  name: "Logs",
  components: { ContentPanel, Modal },
  data() {
    return {
      items: [],
      logDetails: {
        show: false,
        name: null,
        content: null,
      },
    };
  },
  methods: {
    async downloadFile(file) {
      await this.$API.get(`logs`).download(file, this.$store.getters.token);
    },
    async displaylog(item) {
      this.logDetails.show = true;
      this.logDetails.name = item;
      let content = await this.$API
        .get(`logs`)
        .read(item, this.$store.getters.token);
      let contentTab = content.split("\n");
      this.logDetails.content = contentTab.reverse().join("\n");
    },
  },
  created: async function () {
    this.$EventManager.triggerLoadingStart();
    this.items = await this.$API.get("logs").list(this.$store.getters.token);
    this.$EventManager.triggerLoadingEnd();
  },
};
</script>

<style></style>
