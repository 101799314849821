<template>
  <div id="login" class="mx-auto">
    <div v-if="$store.getters.API_IS_DOWN" class="alert alert-danger text-center">
      <b>API IS DOWN !</b>
    </div>
    <section class="height-fit-content p-5" v-if="!$store.getters.isConnected">
      <form v-on:submit.prevent="login">
        <div class="w-100 mb-3 text-center">
          <img src="../assets/icons/user.svg" width="100" alt="" />
        </div>
        <input
          class="w-100 p-3 m-2 border"
          v-model="form.email"
          type="text"
          name="email"
          placeholder="Email"
          required
        />
        <input
          class="w-100 p-3 m-2 border"
          v-model="form.password"
          type="password"
          name="password"
          placeholder="**********"
          minlength="5"
          required
        />
        <div class="mb-5 w-100">
          <router-link :to="{ name: 'ForgotPassword' }" class="float-right"
            >Forgot password?</router-link
          >
        </div>
        <input
          :disabled="$store.getters.API_IS_DOWN"
          class="w-100 p-3 m-2 btn btn-primary lrk-bg-dark-forced"
          type="submit"
          value="Login"
        />
      </form>
    </section>
    <section class="height-fit-content p-5" v-else>
      <div class="w-100 mb-3 text-center">
        <img src="../assets/icons/user.svg" width="100" alt="" />
      </div>
      <div class="text-center">
        <h2 class="text-center">{{ $store.getters.username }}</h2>
        <p v-if="$route.query.redirect">If you have not been redirected</p>
        <router-link
          v-if="$route.query.redirect"
          :to="$route.query.redirect"
          class="btn btn-primary"
          >Click here</router-link
        >
        <router-link v-else :to="{ name: 'Home' }" class="btn btn-primary"
          >Go back to Home</router-link
        >
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
  },
  data: function () {
    return {
      form: {
        email: null,
        password: null,
      },
    };
  },
  mounted() {
    if (this.$route.query.redirect) {
      setTimeout(() => {
        //  Vue refesh que s'il y a une modification
        // Dans le cas ou il est redirigé vers la page de login à nouveau, Vue n'executera pas ce code 2x
        this.$router.push(this.$route.query.redirect);
      }, 1500);
    }
  },
  methods: {
    login: async function () {
      this.$EventManager.triggerLoadingStart();
      this.$store.dispatch("login", {
        ...this.form,
        callback: () => {
          this.$EventManager.triggerLogin();
          this.$EventManager.triggerLoadingEnd();
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            this.$router.push({ name: "Home" });
          }
        },
      });
    },
  },
};
</script>

<style scoped>
#login {
  margin-top: 10%;
  width: 500px;
}
</style>
