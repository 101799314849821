<template>
  <div class="customModal" :class="isActive ? 'active' : ''">
    <div class="background"></div>
    <div
      class="foreground"
      :style="
        'width: calc(' +
        width +
        '%);height:' +
        height +
        '%;margin: ' +
        (100 - top) / 2 +
        '% ' +
        (100 - width) / 2 +
        '%;'
      "
    >
      <div class="header lrk-bg-dark">
        <h3 id="title" class="p-1">{{ title }}</h3>
        <button
          v-if="enableCloseBtn"
          class="btn btn-danger ml-auto"
          style="border-radius: 4px"
          v-on:click="closeModal"
        >
          <img class="icon" src="../assets/icons/close.svg" alt="" />
        </button>
      </div>
      <div class="content">
        <slot></slot>
      </div>
      <div class="footer"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Modal",
  props: {
    width: {
      default: 60,
    },
    height: {
      default: 60,
    },
    top: {
      default: 85,
    },
    title: {
      requied: true,
      type: String,
    },
    active: {
      default: false,
    },
    enableCloseBtn: {
      default: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    active(value) {
      this.show = value;
    },
  },
  computed: {
    isActive() {
      return this.show;
    },
  },
  methods: {
    closeModal() {
      this.show = false;
      this.$emit("modal-close");
    },
  },
};
</script>
<style scoped>
.customModal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
}
.background {
  background: black;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.foreground {
  position: absolute;
  top: 0;
  /*width: calc(100% - 40%); /* 100% - margin left - margin right */
  /*height: 50%;*/
  /*margin: 10% 20%;*/
  background: white;
  border-radius: 10px;
}
.header {
  position: absolute;
  display: flex;
  height: 50px;
  width: 100%;
  padding: 5px;
  color: white;
  z-index: 1000;
  border-radius: 10px 10px 0 0;
}
.header > button {
  margin: 0 0 0 auto;
  border-radius: 0;
}
.content {
  margin: auto;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  border-radius: 10px;
}
.content::-webkit-scrollbar {
  display: none;
}
.active {
  display: block;
}

.scroll {
  overflow-x: hidden;
}
</style>
