<template>
  <button
    :class="'btn btn-secondary ' + addClass"
    v-on:click="show"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
  <modal
    title="Manage permissions"
    :active="showModal"
    :width="40"
    :height="60"
    v-on:modal-close="(tab = 0), (showModal = false), (editEnabled = false)"
  >
    <content-panel>
      <div class="px-5">
        <nav>
          <button
            v-for="(item, index) in tabs"
            :key="'tabs' + index"
            class="btn"
            :class="tabs[tab] !== item ? 'btn-secondary' : 'btn-primary'"
            v-on:click="(editEnabled = false), (tab = tabs.indexOf(item))"
          >
            {{ item }}
          </button>
        </nav>
        <div v-for="(item, index) in tabs" :key="'tabs-content' + index">
          <table
            v-if="tabs[tab] === item && item !== 'Help'"
            class="table table-striped"
          >
            <thead>
              <th class="w-50">{{ item }}</th>
              <th v-for="perm in permissions" :key="item + 'Tab' + perm.name">
                {{ perm.name }}
              </th>
            </thead>
            <tbody>
              <tr v-for="content in tabContents" :key="content.id">
                <td>
                  {{ content.name }}<br />{{
                    content.roles ? `(${content.roles})` : ""
                  }}
                </td>
                <td
                  v-for="perm in permissions"
                  :key="'TabContentChx' + perm.name"
                >
                  <input
                    type="checkbox"
                    :checked="isChecked(item, content.id, perm.value)"
                    v-on:change="
                      chxbxChange(
                        item,
                        content,
                        perm,
                        isChecked(item, content.id, perm.value)
                      )
                    "
                    :disabled="!editEnabled"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table
            v-if="tabs[tab] === item && item === 'Help'"
            class="table table-striped"
          >
            <thead>
              <th class="w-50">Permission</th>
              <th>Detail</th>
            </thead>
            <tbody>
              <tr v-for="content in tabContents" :key="content.id">
                <td>{{ content.name }}</td>
                <td>
                  <p>{{ content.detail }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template #toolbar>
        <button
          class="btn btn-success"
          v-if="!editEnabled"
          v-on:click="editEnabled = true"
        >
          Edit permissions
        </button>
        <button class="btn btn-danger" v-else v-on:click="editEnabled = false">
          Stop edit
        </button>
      </template>
    </content-panel>
  </modal>
</template>

<script>
import Modal from "./Modal";
import ContentPanel from "../layout-components/ContentPanel";
export default {
  components: { Modal, ContentPanel },
  props: {
    addClass: {
      default: "",
    },
    permissions: {
      type: Array,
      required: true,
    },
    ressource: {
      type: String,
      required: true,
    },
    ressourceId: {
      required: true,
    },
    disabled: {
      default: false,
    },
  },
  data: function () {
    return {
      showModal: false,
      roles: [],
      users: [],
      ressourceItem: null,
      tab: 0,
      tabs: [
        "Roles",
        //"Users", // WIP
        "Help",
      ],
      ressourcePermissions: {
        Roles: [],
        Users: [],
      },
      editEnabled: false,
    };
  },
  created: async function () {},
  computed: {
    tabContents: function () {
      if (this.tabs[this.tab] === "Roles") {
        return this.roles;
      } else if (this.tabs[this.tab] === "Users") {
        return this.users.map((u) => {
          return { id: u.uid, name: u.displayName, roles: u.roles.join(", ") };
        });
      } else {
        //  Help tab
        return this.permissions.map((p, index) => {
          return { id: index, name: p.name, detail: p.help };
        });
      }
    },
  },
  methods: {
    show: async function () {
      this.$EventManager.triggerLoadingStart();
      let roles = await this.$API
        .get("users")
        .roleList(this.$store.getters.token);
      this.ressourcePermissions["Roles"] = [];
      for (let role of roles) {
        this.ressourcePermissions["Roles"][role.id] = await this.$API
          .get(this.ressource)
          .readRolePermission(
            this.ressourceId,
            role.id,
            this.$store.getters.token
          );
      }
      let users_res = await this.$API
        .get("users")
        .list(null, this.$store.getters.token);
      let users = users_res.items;
      this.ressourcePermissions["Users"] = [];
      for (let user of users) {
        this.ressourcePermissions["Users"][user.uid] = await this.$API
          .get("users")
          .readRessourcePermission(
            this.ressource.substring(0, this.ressource.length - 1),
            this.ressourceId,
            user.uid,
            this.$store.getters.token
          );
      }
      this.ressourceItem = await this.$API
        .get(this.ressource)
        .read(this.ressourceId, this.$store.getters.token);
      this.roles = roles;
      this.users = users;
      this.showModal = true;
      this.$EventManager.triggerLoadingEnd();
    },
    chxbxChange: async function (tab, content, perm, checked) {
      if (!this.editEnabled) return;
      let permission = this.permissions.find((elm) => elm.value === perm.value);
      if (!permission) return;

      switch (tab) {
        case "Roles":
          if (!checked) {
            await this.$API
              .get(this.ressource)
              .addRolePermission(
                this.ressourceId,
                content.id,
                permission,
                this.$store.getters.token
              );
          } else {
            await this.$API
              .get(this.ressource)
              .removeRolePermission(
                this.ressourceId,
                content.id,
                permission,
                this.$store.getters.token
              );
          }
          this.ressourcePermissions[tab][content.id] = await this.$API
            .get(this.ressource)
            .readRolePermission(
              this.ressourceId,
              content.id,
              this.$store.getters.token
            );
          break;
        case "Users":
          this.ressourcePermissions[tab][content.id] = await this.$API
            .get("users")
            .readRessourcePermission(
              this.ressource.substring(0, this.ressource.length - 1),
              this.ressourceId,
              content.id,
              this.$store.getters.token
            );
          break;

        default:
          break;
      }
    },
    isChecked: function (tab, id, value) {
      return this.ressourcePermissions[tab][id]
        ? this.ressourcePermissions[tab][id].find(
            (elm) => elm.value === value
          ) !== undefined
        : false;
    },
  },
};
</script>

<style></style>
