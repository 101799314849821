<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-50">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / <router-link :to="{ name: 'Projects' }">Projects</router-link> /
        <router-link :to="{ name: 'ProjectItemGroup', params: { id: id } }">{{
          project.name
        }}</router-link>
        / {{ breadCrumbsQuery }}
      </div>
      <form v-on:submit.prevent="filterByQuery" class="w-50">
        <input
          class="w-75"
          type="text"
          v-model="filter.value"
          placeholder="Search"
        />
        <button>
          <img src="../../assets/icons/magnifier.svg" alt="" class="icon" />
        </button>
        <button
          v-if="filter.value"
          class="btn-danger"
          v-on:click.prevent="cancelFilterByQuery"
        >
          <img src="../../assets/icons/cancel.svg" alt="" class="icon" />
        </button>
      </form>
    </div>

    <div class="py-5">
      <section class="height-fit-content">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">
                <input
                  type="checkbox"
                  :checked="items.every(itemIsSelected)"
                  v-on:change="onCheckAllItems"
                />
              </th>
              <th scope="col">Date</th>
              <th scope="col">Query</th>
              <th scope="col">Country</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <th scope="row">
                <input
                  type="checkbox"
                  :checked="itemIsSelected(item)"
                  v-on:change="onCheckItem(item)"
                />
              </th>
              <td>{{ item.created_at }}</td>
              <td>{{ item.value }}</td>
              <td>
                <img
                  class="rounded"
                  :src="getCountryFlagByName(item.country)"
                  width="30"
                  :alt="item.country"
                />
                {{ item.country }}
              </td>
              <td>
                <button class="btn btn-secondary" v-on:click="view(item)">
                  <img
                    src="../../assets/icons/magnifier.svg"
                    alt=""
                    class="icon"
                  />
                </button>
                <button class="btn btn-warning" v-on:click="download(item)">
                  <img
                    src="../../assets/icons/download.svg"
                    alt=""
                    class="icon"
                  />
                </button>
                <button
                  class="btn btn-danger"
                  v-on:click="
                    (deleteItem.show = true), (deleteItem.item = item)
                  "
                >
                  <img
                    src="../../assets/icons/garbage.svg"
                    alt=""
                    class="icon"
                  />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex w-100">
          <pagination
            class="w-75"
            :index="pagination.index"
            :index-max="pagination.indexMax"
            :offset="pagination.offset"
            @previous-page="previousPage"
            @next-page="nextPage"
          />
          <div class="w-25 text-right">{{ items.length }} item(s)</div>
        </div>
      </section>
    </div>

    <template #toolbar>
      <router-link class="btn btn-secondary" :to="{ name: 'ProjectItemGroup' }"
        >Retour</router-link
      >
      <!--<button class="btn btn-success" v-on:click.prevent="showSearchForm=true">Create</button>-->
      <button
        class="btn btn-secondary"
        :disabled="selectedItems.length === 0"
        v-on:click="showSelection = true"
      >
        Selection ({{ selectedItems.length }})
      </button>
      <button
        v-if="false"
        class="btn btn-danger float-right"
        v-on:click="deleteProject.show = true"
        :disabled="!canDeleteProject"
      >
        Remove '{{ project.name }}'
      </button>
    </template>
  </content-panel>

  <modal
    title="Search"
    :active="showSearchForm"
    :width="50"
    :height="50"
    :enableCloseBtn="!searchProcessing"
    v-on:modal-close="showSearchForm = false"
  >
    <form
      v-if="!searchProcessing"
      class="px-5"
      v-on:submit.prevent="createSearches()"
    >
      <input
        class="py-3 w-100 mt-3 border text-center"
        type="text"
        v-model="value"
        placeholder=""
        required
      />
      <select
        class="py-3 w-100 mt-1 mb-3 border text-center bg-white"
        v-model="nblines"
        required
      >
        <option value="" disabled selected>nb lines</option>
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
      <label>Countries:</label>
      <ul class="row mx-0 list-unstyled">
        <li class="col-md-4" v-for="item in countries" :key="item.code">
          <input
            v-on:change="clickOnCountry(item)"
            class="mr-2"
            type="checkbox"
            :name="item.name"
            :id="item.name"
          />
          <label :for="item.name">
            <img
              class="rounded"
              :src="getCountryFlag(item.code)"
              width="30"
              :alt="item.name"
            />
            {{ item.name }}</label
          >
        </li>
      </ul>
      <button class="btn btn-success w-100 py-3 my-3">
        <img class="icon mr-2" src="../../assets/icons/save.svg" alt="save" />
        <span>Submit</span>
      </button>
    </form>
    <div v-else class="pt-5 px-3">
      <p class="text-center">
        <img src="../../assets/icons/clock.svg" width="200" alt="" />
      </p>

      Loading ({{ createSearchCount }} / {{ selectedCountries.length }})
      <div class="progress">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{
            width: `${(createSearchCount / selectedCountries.length) * 100}%`,
          }"
          :aria-valuenow="(createSearchCount / selectedCountries.length) * 100"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{
            `${parseInt(createSearchCount / selectedCountries.length) * 100}%`
          }}
        </div>
      </div>

      <button
        v-on:click="(searchProcessing = false), (showSearchForm = false)"
        class="btn btn-success w-100 py-3 my-3"
        :disabled="
          parseInt(createSearchCount / selectedCountries.length) * 100 != 100
        "
      >
        <img
          class="icon mr-2"
          src="../../assets/icons/magnifier.svg"
          alt="view results"
        />
        <span>View results</span>
      </button>
    </div>
  </modal>

  <modal
    :title="viewItem.name"
    :active="viewItem.show"
    :width="90"
    :height="80"
    v-on:modal-close="viewItem.show = false"
  >
    <content-panel>
      <div class="w-100 h-100" style="overflow: scroll">
        <nav>
          <button
            class="btn"
            :class="!viewItem.tabBlacklist ? 'btn-primary' : 'btn-secondary'"
            v-on:click="viewItem.tabBlacklist = false"
          >
            Result
          </button>
          <button
            class="btn"
            :class="viewItem.tabBlacklist ? 'btn-primary' : 'btn-secondary'"
            :disabled="!viewItem.item.blacklist"
            v-on:click="viewItem.tabBlacklist = true"
          >
            Blacklist applied
          </button>
        </nav>
        <table
          v-if="!viewItem.tabBlacklist"
          class="w-100 h-100 table table-striped"
        >
          <tr v-for="(row, index) in viewItem.data" :key="index">
            <td
              v-for="(value, col) in row"
              :key="`${index}.${col}`"
              :class="value.length > 70 ? 'large-cell' : ''"
            >
              {{ value }}
            </td>
          </tr>
        </table>
        <ArraySearchBar
          class="mt-3"
          v-if="viewItem.tabBlacklist"
          :array="viewItem.item.blacklist"
          @reset-result="viewItem.tabBlackListItems = viewItem.item.blacklist"
          @get-result="(result) => (viewItem.tabBlackListItems = result)"
        />

        <table
          v-if="viewItem.tabBlacklist"
          class="w-100 mt-3 table table-striped"
        >
          <tr>
            <th>Domain</th>
          </tr>
          <tr
            v-for="(blacklistItem, index) in viewItem.tabBlackListItems"
            :key="'blacklistItem' + index"
          >
            <td>
              {{ blacklistItem }}
            </td>
          </tr>
        </table>

        <p
          v-if="viewItem.tabBlacklist && viewItem.item.blacklist.length == 0"
          class="mt-3"
        >
          No blacklist was apply during this search
        </p>
      </div>

      <template #toolbar>
        <button
          class="btn btn-warning"
          v-on:click="
            viewItem.item.id ? download(viewItem.item) : downloadSelection()
          "
        >
          <img src="../../assets/icons/download.svg" alt="" class="icon" />
        </button>
        <button
          class="btn"
          :class="viewItem.copied ? 'btn-success' : 'btn-secondary'"
          :disabled="viewItem.copied"
          v-on:click="copy(viewItem.item)"
        >
          <img src="../../assets/icons/copy.svg" alt="" class="icon" />
        </button>
        <span v-if="viewItem.copied" class="ml-1">Copied...</span>
        <button
          class="btn btn-danger float-right"
          v-on:click="
            () => {
              if (viewItem.item.id) {
                viewItem.show = false;
                deleteItem.show = true;
                deleteItem.item = viewItem.item;
              } else {
                viewItem.show = false;
                selectedItems = [];
              }
            }
          "
        >
          <img src="../../assets/icons/garbage.svg" alt="" class="icon" />
        </button>
      </template>
    </content-panel>
  </modal>

  <modal
    :title="'Remove ' + deleteItem.item.value"
    :active="deleteItem.show"
    :width="30"
    :height="35"
    v-on:modal-close="deleteItem.show = false"
  >
    <div class="px-5">
      <p>Are you sure ?</p>
      <p>You will remove the search '{{ deleteItem.item.value }}'</p>
      <p>
        (country: {{ deleteItem.item.country }}, date:
        {{ deleteItem.item.created_at }})
      </p>
      <button
        class="btn btn-danger w-100 mt-3 py-3"
        v-on:click.prevent="remove"
      >
        <img class="icon" src="../../assets/icons/garbage.svg" alt="" />
        <span>Remove</span>
      </button>
    </div>
  </modal>

  <modal
    title="Selection"
    :active="showSelection"
    :width="60"
    :height="60"
    v-on:modal-close="showSelection = false"
  >
    <content-panel>
      <div class="px-5">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Query</th>
              <th scope="col">Country</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in selectedItems" :key="index">
              <td>{{ item.created_at }}</td>
              <td class="w-50">{{ item.value }}</td>
              <td>
                <img
                  class="rounded"
                  :src="getCountryFlagByName(item.country)"
                  width="30"
                  :alt="item.country"
                />
                {{ item.country }}
              </td>
              <td>
                <button class="btn btn-danger" v-on:click="onCheckItem(item)">
                  <img
                    src="../../assets/icons/cancel.svg"
                    alt=""
                    class="icon"
                  />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <template #toolbar>
        <button
          class="btn btn-warning float-left"
          v-on:click.prevent="downloadSelection"
          :disabled="selectedItems.length == 0"
        >
          Download
        </button>
        <button
          class="btn btn-secondary"
          v-on:click.prevent="viewSelection"
          :disabled="selectedItems.length == 0"
        >
          View
        </button>
        <button
          class="btn btn-danger float-right"
          v-on:click.prevent="selectedItems = []"
          :disabled="selectedItems.length == 0"
        >
          Clear
        </button>
      </template>
    </content-panel>
  </modal>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal";
import Pagination from "../../components/Pagination";
import ArraySearchBar from "@/components/ArraySearchBar.vue";

export default {
  name: "ProjectItem",
  components: { ArraySearchBar, ContentPanel, Modal, Pagination },
  props: {
    id: {
      required: true,
    },
    runId: {
      required: true,
    },
    breadCrumbsQuery: {
      required: true,
    },
  },
  data: function () {
    return {
      project: {
        id: null,
        name: null,
      },
      searchesDisplay: [],
      items: [],
      countries: this.$API.get("searches").COUNTRIES.sort((a, b) => {
        return a.name.localeCompare(b.name);
      }),
      selectedCountries: [],
      selectedItems: [],
      searchesNumber: 0,
      showSearchForm: false,
      showSelection: false,
      searchProcessing: false,
      createSearchCount: 0,
      value: null,
      nblines: "",
      viewItem: {
        name: null,
        data: [],
        show: false,
        item: {
          value: null,
          blacklist: [],
        },
        copied: false,
        tabBlacklist: false,
        tabBlackListItems: [],
      },
      deleteItem: {
        item: {
          value: null,
        },
        show: false,
      },
      deleteProject: {
        item: {
          value: null,
        },
        show: false,
      },
      pagination: {
        index: 0,
        indexMax: 0,
        offset: 20,
      },
      filter: {
        value: null,
      },
      managePermission: {
        roles: [],
      },
    };
  },
  created: async function () {
    this.$EventManager.triggerLoadingStart();
    await this.refresh();
    this.$EventManager.triggerLoadingEnd();
  },
  computed: {
    canChangeProject: function () {
      return (
        this.$store.getters.permissions.find(
          (perm) => perm === "pages.main.projects.update"
        ) ||
        this.$store.getters.permissions.find(
          (perm) => perm === `pages.main.projects.${this.id}.update`
        )
      );
    },
    canDeleteProject: function () {
      return (
        this.$store.getters.permissions.find(
          (perm) => perm === "pages.main.projects.delete"
        ) ||
        this.$store.getters.permissions.find(
          (perm) => perm === `pages.main.projects.${this.id}.delete`
        )
      );
    },
  },
  methods: {
    createSearches: async function () {
      this.createSearchCount = 0;
      this.searchProcessing = true;
      let promises = [];
      let runId = this.value + Date.now() + this.$store.getters.uid;
      for (const country of this.selectedCountries) {
        promises.push(this.makeASearch(country, runId));
      }
      Promise.all(promises).then(() => {
        this.value = null;
        this.nblines = "";
        this.refresh();
      });
    },
    makeASearch: async function (country, runId) {
      await this.$API
        .get("searches")
        .create(
          this.value,
          this.nblines,
          country,
          this.id,
          this.$API.get("blacklists").getEnabled(),
          this.$store.getters.uid,
          runId,
          this.$store.getters.token
        );
      this.createSearchCount++;
    },
    getCountryFlag(code) {
      var images = require.context("../../assets/flags/", false, /\.svg$/);
      return images("./" + code + ".svg");
    },
    getCountryFlagByName(name) {
      let country = this.countries.find((el) => el.name === name);
      var images = require.context("../../assets/flags/", false, /\.svg$/);
      return images("./" + country.code + ".svg");
    },
    clickOnCountry: function (country) {
      if (this.selectedCountries.includes(country.name)) {
        let index = this.selectedCountries.indexOf(country.name);
        this.selectedCountries.splice(index, 1);
      } else {
        this.selectedCountries.push(country.name);
      }
    },
    download: function (item) {
      this.$EventManager.triggerLoadingMinSetValue(80);
      this.$API.get("searches").download(this.project, item);
      this.$EventManager.triggerLoadingMinSetValue(100);
    },
    downloadSelection: function () {
      this.$API
        .get("searches")
        .downloadSelection(this.project, this.selectedItems);
    },
    view: function (item) {
      this.viewItem.item = item;
      this.viewItem.tabBlackListItems = item.blacklist;
      this.viewItem.name = `[${item.created_at}]-${this.project.name.replaceAll(
        " ",
        "-"
      )}-${item.value.replaceAll(" ", "-")}-${item.country.replaceAll(
        " ",
        "-"
      )}.csv`;
      this.viewItem.data = this.$API.get("searches").toArray(item);
      this.viewItem.tabBlacklist = false;
      this.viewItem.show = true;
    },
    viewSelection: function () {
      this.showSelection = false;
      this.viewItem.name = `Selection`;
      let res = this.$API.get("searches").selectionToArray(this.selectedItems);
      this.viewItem.data = res.data;
      this.viewItem.item = res.fakeItem;
      this.viewItem.tabBlacklist = false;
      this.viewItem.show = true;
    },
    copy: function (item) {
      navigator.clipboard.writeText(item.result);
      this.viewItem.copied = true;
      setTimeout(() => (this.viewItem.copied = false), 3000);
    },
    remove: async function () {
      await this.$API
        .get("searches")
        .remove(
          this.deleteItem.item.id,
          this.$store.getters.uid,
          this.$store.getters.token
        );
      this.deleteItem.show = false;
      if (this.items.length - 1 > 0) {
        this.$EventManager.triggerLoadingMinSetValue(50);
        await this.refresh();
        this.$EventManager.triggerLoadingMinSetValue(100);
      } else {
        this.$router.push({
          name: "ProjectItemGroup",
          params: { id: this.project.id },
        });
      }
    },
    refresh: async function () {
      if (this.filter.value && this.filter.value.trim() !== "") {
        await this.filterByQuery();
      } else {
        this.project = await this.$API
          .get("projects")
          .read(this.id, this.$store.getters.token);
        let res = await this.$API
          .get("searches")
          .getByRunId(
            this.runId,
            this.pagination.offset,
            this.pagination.index,
            this.$store.getters.token
          );
        this.items = res.items;
        this.searchesNumber = res.count;
        this.pagination.indexMax = res.count;
      }
    },
    previousPage: async function () {
      this.$EventManager.triggerLoadingStart();
      this.pagination.index -= this.pagination.offset;
      await this.refresh();
      document.body.scrollTop = 0;
      this.$EventManager.triggerLoadingEnd();
    },
    nextPage: async function () {
      this.$EventManager.triggerLoadingStart();
      this.pagination.index += this.pagination.offset;
      await this.refresh();
      document.body.scrollTop = 0;
      this.$EventManager.triggerLoadingEnd();
    },
    filterByQuery: async function () {
      if (this.filter.value && this.filter.value.trim() !== "") {
        this.project = await this.$API
          .get("projects")
          .read(this.id, this.$store.getters.token);
        let res = await this.$API
          .get("searches")
          .filterRunid(
            this.runId,
            this.filter.value,
            this.pagination.offset,
            this.pagination.index,
            this.$store.getters.token
          );
        this.items = res.items;
        this.searchesNumber = res.count;
        this.pagination.indexMax = res.count;
      } else {
        await this.refresh();
      }
    },
    cancelFilterByQuery: async function () {
      this.filter.value = null;
      await this.refresh();
    },
    onCheckItem: function (item) {
      if (this.itemIsSelected(item)) {
        let index = this.selectedItems.findIndex((i) => i.id === item.id);
        this.selectedItems.splice(index, 1);
      } else {
        this.selectedItems.push(item);
      }
    },
    onCheckAllItems: function () {
      if (this.items.every(this.itemIsSelected)) {
        for (const item of this.items) {
          let index = this.selectedItems.findIndex((i) => i.id === item.id);
          if (index >= 0) {
            this.selectedItems.splice(index, 1);
          }
        }
      } else {
        for (const item of this.items) {
          let index = this.selectedItems.findIndex((i) => i.id === item.id);
          if (index === -1) {
            this.selectedItems.push(item);
          }
        }
      }
    },
    itemIsSelected: function (item) {
      return this.selectedItems.find((i) => i.id === item.id) !== undefined;
    },
    onClickManagePermission: async function () {
      this.managePermission.roles = await this.$API
        .get("users")
        .roleList(this.$store.getters.token);
    },
  },
};
</script>

<style scoped>
.large-cell {
  min-width: 400px;
}
</style>
