import Modal from "@/assets/js/Modal";
import Storage from "@/assets/js/Storage";

export default class FormManager extends Modal{
  constructor(vue, formName) {
    super(vue, formName, false);
    this.inputs = [];
    this.data = {};
    this.data_given = {};
    this.beforeSubmitCallback = async () => {};
    this.afterSubmitCallBack = async () => {};
    this.onSubmitCallback = async () => {};
    this.useLoading = true;
    this.storage = new Storage();
    this.triggers = {};

  }

  beforeSubmit(callback) {
    this.beforeSubmitCallback = callback;
  }

  afterSubmit(callback) {
    this.afterSubmitCallBack = callback;
  }


  onSubmit(callback) {
    this.onSubmitCallback = callback;
  }

  store(key, value = null) {
    this.storage.set(key, value);
  }

  get(key) {
    return this.storage.get(key);
  }

  resetInputs() {
    this.inputs = [];
    this.data = {};
    let keys = Object.keys(this.vue.$refs).filter((k) =>
      k.match(`^${this.name.value}/`)
    );
    for (let k of keys) {
      this.inputs.push(this.vue.$refs[k]);
      switch (this.vue.$refs[k].type) {
        case "checkbox":
          this.data[k.replace(RegExp(`^${this.name.value}/`), "")] =
            this.vue.$refs[k].checked;
          break;
        case "number":
          this.data[k.replace(RegExp(`^${this.name.value}/`), "")] = parseFloat(
            this.vue.$refs[k].value
          );
          break;

        default:
          this.data[k.replace(RegExp(`^${this.name.value}/`), "")] =
            this.vue.$refs[k].value;
          break;
      }
    }
  }

  async submit() {
    if (this.useLoading) {
      this.vue.$EventManager.triggerLoadingStart();
    }

    this.resetInputs();

    await this.trigger("before-submit");

    await this.beforeSubmitCallback(this.inputs);

    await this.onSubmitCallback(this.data, this.data_given, this.inputs);

    await this.afterSubmitCallBack();

    await this.trigger("after-submit");

    if (this.useLoading) {
      this.vue.$EventManager.triggerLoadingEnd();
    }
  }

  async show(data = {}) {
    this.data_given = data;
    await super.show();
  }

  async close() {
    await this.hide();
    this.resetInputs();
    for (let input of this.inputs) {
      switch (input.type) {
        case "checkbox":
          input.checked = false;
          break;

        default:
          input.value = "";
          break;
      }
    }
    this.data_given = {};
    this.data = {};
  }
}

export function createForm(vue, formName) {
  return new FormManager(vue, formName);
}
