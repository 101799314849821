<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-50">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / <router-link :to="{ name: 'Blacklists' }">Blacklists</router-link> /
        {{ blacklist.name }}
      </div>
      <form v-on:submit.prevent="refresh" class="w-50">
        <input
          class="w-75"
          type="text"
          v-model="filter.domain"
          placeholder="Search"
        />
        <button>
          <img src="../../assets/icons/magnifier.svg" alt="" class="icon" />
        </button>
        <button
          v-if="filter.domain"
          class="btn-danger"
          v-on:click.prevent="cancelFilterByDomain"
        >
          <img src="../../assets/icons/cancel.svg" alt="" class="icon" />
        </button>
      </form>
    </div>

    <div class="pt-5">
      <section class="height-fit-content">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>{{ item.domain }}</td>
              <td>
                <button
                  class="btn btn-danger"
                  v-on:click="deleteItem(item)"
                  :disabled="!canChangeBlacklist"
                >
                  <img
                    src="../../assets/icons/garbage.svg"
                    alt=""
                    class="icon"
                  />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex w-100">
          <pagination
            class="w-75"
            :index="pagination.index"
            :index-max="pagination.indexMax"
            :offset="pagination.offset"
            @previous-page="previousPage"
            @next-page="nextPage"
          />
          <div class="w-25 text-right">{{ items.length }} item(s)</div>
        </div>
      </section>
    </div>

    <template #toolbar>
      <button
        v-if="false"
        class="btn btn-success"
        v-on:click="showCreateForm = true"
        :disabled="!canChangeBlacklist"
      >
        Add item
      </button>
      <button
        class="btn btn-success"
        v-on:click="importFromTextForm.show = true"
        :disabled="!canChangeBlacklist"
      >
        Add items
      </button>
      <button
        class="btn btn-warning"
        v-on:click="exportItems()"
        :disabled="!canChangeBlacklist"
      >
        <img src="@/assets/icons/download-slim.svg" alt="" class="icon" /> to
        csv
      </button>
      <button
        class="btn btn-danger float-right"
        v-on:click="deleteConfirm.show = true"
        :disabled="!canDeleteBlacklist"
      >
        Remove '{{ blacklist.name }}'
      </button>
      <button
        class="btn btn-secondary"
        v-on:click="showUpdateForm = true"
        :disabled="!canChangeBlacklist"
      >
        Rename '{{ blacklist.name }}'
      </button>
      <manage-permission-btn
        add-class="float-right"
        :permissions="[
          {
            name: 'Add domain',
            value: `pages.main.blacklists.${id}.create_domain`,
            ressource: 'blacklist',
            ressource_id: id,
            action: 'create',
            help: 'Add domain in this blacklist',
          },
          {
            name: 'Read',
            value: `pages.main.blacklists.${id}.read`,
            ressource: 'blacklist',
            ressource_id: id,
            action: 'read',
            help: 'Access to the blacklist',
          },
          {
            name: 'Update',
            value: `pages.main.blacklists.${id}.update`,
            ressource: 'blacklist',
            ressource_id: id,
            action: 'update',
            help: 'Update blacklist info and permissions',
          },
          {
            name: 'Delete',
            value: `pages.main.blacklists.${id}.delete`,
            ressource: 'blacklist',
            ressource_id: id,
            action: 'delete',
            help: 'Delete the blacklist',
          },
        ]"
        ressource="blacklists"
        :ressource-id="id"
        :disabled="!canChangeBlacklist"
        >Manage permissions</manage-permission-btn
      >
    </template>
  </content-panel>

  <modal
    :title="'Remove blacklist ' + blacklist.name"
    :active="deleteConfirm.show"
    :width="30"
    :height="30"
    v-on:modal-close="deleteConfirm.show = false"
  >
    <div class="px-5">
      <p>Are you sure ?</p>
      <p>You will remove the blacklist named '{{ blacklist.name }}'</p>
      <button
        class="btn btn-danger w-100 mt-3 py-3"
        v-on:click="deleteBlacklist()"
      >
        <img class="icon" src="../../assets/icons/garbage.svg" alt="" />
        <span>Remove</span>
      </button>
    </div>
  </modal>

  <modal
    title="Add item"
    v-on:modal-close="showCreateForm = false"
    :active="showCreateForm"
    :width="50"
    :height="50"
  >
    <form class="p-5 text-center" v-on:submit.prevent="createItem">
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Domain</th>
          <td>
            <input
              class="w-100"
              v-model="createForm.domain"
              type="text"
              name="domain"
              placeholder="domain"
            />
          </td>
        </tr>
      </table>
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>
  <modal
    title="Add items"
    v-on:modal-close="importFromTextForm.show = false"
    :active="importFromTextForm.show"
    :width="50"
    :height="60"
  >
    <form class="p-5 text-center h-75" v-on:submit.prevent="importItems">
      <table class="table table-striped w-100 h-100 text-left">
        <tr class="h-100">
          <th>Domains</th>
          <td class="h-100">
            <textarea
              class="w-100 h-100 form-control"
              placeholder="www.domain1.tld&#10;www.domain2.tld"
              v-model="importFromTextForm.value"
            ></textarea>
          </td>
        </tr>
      </table>
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>
  <modal
    :title="'Update ' + blacklist.name"
    v-on:modal-close="showUpdateForm = false"
    :active="showUpdateForm"
    :width="50"
    :height="50"
  >
    <form class="p-5 text-center" v-on:submit.prevent="create">
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              class="w-100"
              v-model="blacklist.name"
              type="text"
              name="name"
              placeholder="name"
            />
          </td>
        </tr>
      </table>
      <button
        class="btn btn-primary mt-5"
        type="submit"
        v-on:click="updateItem()"
      >
        Submit
      </button>
    </form>
  </modal>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal.vue";
import Pagination from "../../components/Pagination";
import ManagePermissionBtn from "../../components/ManagePermissionBtn";

export default {
  name: "BlacklistItem",
  components: { ContentPanel, Modal, Pagination, ManagePermissionBtn },
  props: {
    id: {
      required: true,
    },
  },
  data: function () {
    return {
      blacklist: {
        name: null,
      },
      items: [],
      createForm: {
        domain: null,
      },
      showCreateForm: false,
      updateForm: {
        name: null,
      },
      importFromTextForm: {
        value: null,
        show: false,
      },
      showUpdateForm: false,
      deleteConfirm: {
        show: false,
      },
      pagination: {
        index: 0,
        indexMax: 0,
        offset: 20,
      },
      filter: {
        domain: null,
      },
    };
  },
  computed: {
    canChangeBlacklist: function () {
      return (
        this.$store.getters.permissions.find(
          (perm) => perm === "pages.main.blacklists.update"
        ) ||
        this.$store.getters.permissions.find(
          (perm) => perm === `pages.main.blacklists.${this.id}.update`
        )
      );
    },
    canDeleteBlacklist: function () {
      return (
        this.$store.getters.permissions.find(
          (perm) => perm === "pages.main.blacklists.delete"
        ) ||
        this.$store.getters.permissions.find(
          (perm) => perm === `pages.main.blacklists.${this.id}.delete`
        )
      );
    },
  },
  methods: {
    async deleteBlacklist() {
      await this.$API
        .get(`blacklists`)
        .remove(this.id, this.$store.getters.token);
      this.deleteConfirm.show = false;
      this.$router.push({ name: "Blacklists" });
    },
    async deleteItem(id) {
      await this.$API
        .get(`blacklists`)
        .removeItem(this.name, id, this.$store.getters.token);
      await this.refresh();
    },
    async createItem() {
      await this.$API
        .get(`blacklists`)
        .addItem(
          this.blacklist.name,
          this.id,
          this.createForm.domain,
          this.$store.getters.uid,
          this.$store.getters.token
        );
      await this.refresh();
      this.showCreateForm = false;
      this.createForm.domain = null;
    },
    async updateItem() {
      await this.$API.get(`blacklists`).update(
        {
          name: this.blacklist.name,
          id: this.id,
        },
        this.$store.getters.token
      );
      await this.refresh();
      this.showUpdateForm = false;
    },
    refresh: async function (withLoading = false) {
      if (withLoading) {
        this.$EventManager.triggerLoadingMinSetValue(10);
      }
      if (this.filter.domain && this.filter.domain.trim() !== "") {
        await this.filterByDomain();
      } else {
        this.blacklist = await this.$API
          .get(`blacklists`)
          .read(this.id, this.$store.getters.token);
        let res = await this.$API
          .get("blacklists")
          .getItems(
            this.id,
            this.pagination.offset,
            this.pagination.index,
            this.$store.getters.token
          );
        this.items = res.items;
        this.pagination.indexMax = res.count;
      }
      if (withLoading) {
        this.$EventManager.triggerLoadingMinSetValue(100);
      }
    },
    previousPage: async function () {
      this.$EventManager.triggerLoadingStart();
      this.pagination.index -= this.pagination.offset;
      await this.refresh();
      document.body.scrollTop = 0;
      this.$EventManager.triggerLoadingEnd();
    },
    nextPage: async function () {
      this.$EventManager.triggerLoadingStart();
      this.pagination.index += this.pagination.offset;
      await this.refresh();
      document.body.scrollTop = 0;
      this.$EventManager.triggerLoadingEnd();
    },
    filterByDomain: async function () {
      if (this.filter.domain && this.filter.domain.trim() !== "") {
        let res = await this.$API
          .get("blacklists")
          .filterItems(
            this.filter.domain,
            this.id,
            this.pagination.offset,
            this.pagination.index,
            this.$store.getters.token
          );
        this.items = res.items;
        this.pagination.indexMax = res.count;
        this.pagination.index = 0;
      }
    },
    cancelFilterByDomain: async function () {
      this.filter.domain = null;
      await this.refresh(true);
    },
    exportItems: async function () {
      await this.$API
        .get("blacklists")
        .exportItems(this.blacklist, this.$store.getters.token);
    },
    importItems: async function () {
      this.$EventManager.triggerLoadingStart();
      let domains = this.importFromTextForm.value.split('\n')
      domains = domains.map(d => this.$API.get("blacklists").getDomainName(d)).filter(d => d!==null)
      await this.$API
        .get("blacklists")
        .importItems(
          this.blacklist,
          [...new Set(domains)].join("\n"),
          this.$store.getters.uid,
          this.$store.getters.token
        );
      this.importFromTextForm.value = null;
      this.importFromTextForm.show = false;
      await this.refresh();
      this.$EventManager.triggerLoadingEnd();
    },
  },
  created: async function () {
    this.$EventManager.triggerLoadingStart();
    await this.refresh();
    this.$EventManager.triggerLoadingEnd();
  },
};
</script>

<style></style>
