import Request from "./request";
import Utils from "../Utils";

const SERVER = "main";

export async function list(limit, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `sites/paginate/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::sites::list", error);
  }
}

//  TODO: not implemented in the API
export async function filter(name, limit, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `sites/filter/${name}/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::sites::filter", error);
  }
}

export async function create(site, token) {
  try {
    return await Request.post(SERVER, "sites", token, { site });
  } catch (error) {
    Utils.debugVar("Connectors::sites::create", error);
  }
}

export async function read(id, token) {
  try {
    return await Request.get(SERVER, `site/${id}`, token);
  } catch (error) {
    Utils.debugVar("Connectors::site::read", error);
  }
}

export async function update(site, token) {
  try {
    return await Request.put(SERVER, `site/${site.id}`, token, {
      site,
    });
  } catch (error) {
    Utils.debugVar("Connectors::site::update", error);
  }
}

export async function remove(site, token) {
  try {
    return await Request.delete(SERVER, `site/${site.id}`, token);
  } catch (error) {
    Utils.debugVar("Connectors::site::remove", error);
  }
}

export async function add_service(siteId, service, token) {
  try {
    return await Request.patch(SERVER, `site/${siteId}/add_service`, token, {
      service,
    });
  } catch (error) {
    Utils.debugVar("Connectors::site::add_service", error);
  }
}

export async function remove_service(siteId, service, token) {
  try {
    return await Request.patch(SERVER, `site/${siteId}/remove_service`, token, {
      service,
    });
  } catch (error) {
    Utils.debugVar("Connectors::site::remove_service", error);
  }
}
