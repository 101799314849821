import Axios from "axios";
import Utils from "../Utils";
import * as EventManager from "../EventManager";
import { SERVER } from "../../../config/app";

async function get(server, path, token, headers = {}) {
  return await request(server, "get", path, token, {}, headers);
}
async function post(server, path, token, data, headers = {}) {
  return await request(server, "post", path, token, data, headers);
}
async function put(server, path, token, data, headers = {}) {
  return await request(server, "put", path, token, data, headers);
}
async function patch(server, path, token, data, headers = {}) {
  return await request(server, "patch", path, token, data, headers);
}
async function remove(server, path, token, data = {}, headers = {}) {
  return await request(server, "delete", path, token, data, headers);
}
async function request(server, protocole, path, token, data, headers) {
  try {
    let response;
    switch (protocole) {
      case "get":
        response = await Axios[protocole](`${SERVER[server]}/${path}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            ...headers,
          },
        });
        break;
      case "delete":
        response = await Axios[protocole](`${SERVER[server]}/${path}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            ...headers,
          },
          data: {
            ...data,
          },
        });
        break;
      default:
        response = await Axios[protocole](
          `${SERVER[server]}/${path}`,
          {
            ...data,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              ...headers,
            },
          }
        );
        break;
    }
    if (response && response.data) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    onError(error);
  }
}

function onError(error) {
  switch (error.response.status) {
    case 400:
      document.dispatchEvent(new CustomEvent("app-logout"));
      break;
    case 403:
      EventManager.triggerError(403, error.response.data.message);
      break;
    case 401:
      EventManager.triggerError(401, error.response.data);
      break;
    case 500:
      document.dispatchEvent(
        new CustomEvent("app-on-error", { detail: error.response.data })
      );
      EventManager.triggerError(500, error.response.data);
      Utils.debug(error.response);
      break;
  }
}

export default {
  get,
  post,
  patch,
  put,
  delete: remove,
};
