<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-50">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / <router-link :to="{ name: 'Projects' }">Projects</router-link> /
        {{ project.name }}
      </div>
      <form v-on:submit.prevent="filterByQuery" class="w-50">
        <input
          class="w-75"
          type="text"
          v-model="filter.query"
          placeholder="Search"
        />
        <button>
          <img src="../../assets/icons/magnifier.svg" alt="" class="icon" />
        </button>
        <button
          v-if="filter.query"
          class="btn-danger"
          v-on:click.prevent="cancelFilterByQuery"
        >
          <img src="../../assets/icons/cancel.svg" alt="" class="icon" />
        </button>
      </form>
    </div>

    <div class="py-5">
      <section class="height-fit-content">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">
                <input
                  type="checkbox"
                  :checked="items.every(itemIsSelected)"
                  v-on:change="onCheckAllItems"
                />
              </th>
              <th scope="col">Date</th>
              <th scope="col">Query</th>
              <th scope="col" :style="{ width: '300px' }">Tags</th>
              <th scope="col">Country</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <th scope="row">
                <input
                  type="checkbox"
                  :checked="itemIsSelected(item)"
                  v-on:change="onCheckItem(item)"
                />
              </th>
              <td>{{ item.created_at }}</td>
              <td>{{ item.value }}</td>
              <td>
                <span
                  v-for="tag in JSON.parse(item.tags)"
                  :key="item.run_id + tag"
                  class="badge bg-dark text-white m-1"
                  v-on:click="filterByTag(tag)"
                  >{{ tag }}</span
                >
              </td>
              <td>
                <span
                  v-for="(item, index) in item.countries.sort()"
                  :key="index"
                  tabindex="0"
                  data-toggle="tooltip"
                  :title="item"
                >
                  <img
                    class="rounded mr-2"
                    :src="getCountryFlagByName(item)"
                    width="30"
                    :alt="item"
                  />
                </span>
              </td>
              <td>
                <router-link
                  class="btn btn-secondary"
                  :to="{
                    name: 'ProjectItem',
                    params: {
                      id: id,
                      runId: item.run_id,
                      breadCrumbsQuery: item.value,
                    },
                  }"
                >
                  <img
                    src="../../assets/icons/magnifier.svg"
                    alt=""
                    class="icon"
                  />
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex w-100">
          <pagination
            class="w-75"
            :index="pagination.index"
            :index-max="pagination.indexMax"
            :offset="pagination.offset"
            @previous-page="previousPage"
            @next-page="nextPage"
          />
          <div class="w-25 text-right">{{ items.length }} item(s)</div>
        </div>
      </section>
    </div>

    <template #toolbar>
      <button
        class="btn btn-success"
        v-on:click.prevent="showSearchForm = true"
      >
        Create
      </button>
      <button
        class="btn btn-secondary"
        :disabled="selectedItems.length === 0"
        v-on:click="showSelection = true"
      >
        Selection ({{ selectedItems.length }})
      </button>

      <button
        class="btn btn-danger float-right"
        v-on:click="deleteProject.show = true"
        :disabled="!canDeleteProject"
      >
        Remove '{{ project.name }}'
      </button>
      <button
        class="btn btn-warning float-right"
        v-on:click.prevent="() => download()"
        :disabled="disableDownload || items.length == 0"
      >
        <img src="@/assets/icons/download.svg" alt="" class="icon" /> '{{
          project.name
        }}'
      </button>
      <manage-permission-btn
        add-class="float-right"
        :permissions="[
          {
            name: 'Searches',
            value: `pages.main.projects.${id}.create_searches`,
            ressource: 'project',
            ressource_id: id,
            action: 'create',
            help: 'Create searches in this project',
          },
          {
            name: 'Read',
            value: `pages.main.projects.${id}.read`,
            ressource: 'project',
            ressource_id: id,
            action: 'read',
            help: 'Access to the project',
          },
          {
            name: 'Update',
            value: `pages.main.projects.${id}.update`,
            ressource: 'project',
            ressource_id: id,
            action: 'update',
            help: 'Update project info and permissions',
          },
          {
            name: 'Delete',
            value: `pages.main.projects.${id}.delete`,
            ressource: 'project',
            ressource_id: id,
            action: 'delete',
            help: 'Delete the project',
          },
        ]"
        ressource="projects"
        :ressource-id="id"
        :disabled="!$store.getters.roles.includes('Admin')"
        >Manage permissions</manage-permission-btn
      >
    </template>
  </content-panel>

  <modal
    title="Search"
    :active="showSearchForm"
    :width="60"
    :height="80"
    :top="95"
    :enableCloseBtn="!searchProcessing"
    v-on:modal-close="(showSearchForm = false), (showTags = false)"
  >
    <form
      v-if="!searchProcessing"
      class="px-5"
      v-on:submit.prevent="createSearchesV2()"
    >
      <input
        v-show="createSearchForm.switchTextArea === false"
        v-on:keydown="createSearchesCheckKeyDown"
        v-on:keyup="createSearchesCheckKeyUp"
        v-model="createSearchForm.queries"
        ref="createSearchesInput"
        class="form-control mt-3 py-4 text-center"
        type="text"
        placeholder="Query"
      />
      <div v-show="createSearchForm.switchTextArea" style="height: 200px">
        <textarea
          ref="createSearchesTextarea"
          class="form-control"
          style="height: 200px"
          placeholder="Queries"
          v-model="createSearchForm.queries"
        ></textarea>
      </div>
      <select
        class="py-2 w-100 mt-1 mb-3 border text-center bg-white"
        v-model="nblines"
        required
      >
        <option value="" disabled>nb lines</option>
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
      <div>
        <button class="btn m-1" :class="countriesByPercentageEnabled?'':'btn-secondary'" @click.prevent="countriesByPercentageEnabled = true">Most used countries</button>
        <button class="btn m-1" :class="countriesByPercentageEnabled?'btn-secondary':''" @click.prevent="countriesByPercentageEnabled = false">Countries</button>
        <div>
          <label class=""
          >
            <span v-show="selectedCountries.length > 0" style="color: gray"
            >selected: {{ selectedCountries.length }}</span
            ></label
          >
          <ul v-if="!countriesByPercentageEnabled" class="row mx-0 list-unstyled">
            <li class="col-md-4" v-for="item in countries" :key="item.code">
              <input
                v-on:change="clickOnCountry(item)"
                :checked="selectedCountries.includes(item.name)"
                class="mr-2"
                type="checkbox"
                :name="item.name"
                :id="item.name"
              />
              <label :for="item.name">
                <img
                  class="rounded"
                  :src="getCountryFlag(item.code)"
                  width="30"
                  :alt="item.name"
                />
                {{ item.name }}</label
              >
            </li>
          </ul>
          <ul v-else class="row mx-0 list-unstyled">
            <li class="col-md-4" v-for="item in countriesByPercentage" :key="'countriesByPercentage-'+item.name">
              <input
                v-on:change="clickOnCountry(item)"
                :checked="selectedCountries.includes(item.name)"
                class="mr-2"
                type="checkbox"
                :name="item.name"
                :id="item.name"
              />
              <label :for="item.name">
                <img
                  class="rounded"
                  :src="getCountryFlagByName(item.name)"
                  width="30"
                  :alt="item.name"
                />
                {{ item.name }} ({{ item.percentage.toFixed(2) }}%)</label
              >
            </li>
          </ul>
        </div>
      </div>

      <button v-if="!showTags" class="btn btn-secondary m-1 w-100" v-on:click.prevent="showTags = true">Add tags</button>
      <button v-else class="btn btn-secondary m-1 w-100" v-on:click.prevent="showTags = false, tags = ''">Remove tags</button>
      <input
        v-if="showTags"
        class="py-3 w-100 my-3 border text-center"
        type="text"
        v-model="tags"
        placeholder="Tags (separated by comma ',')"
      />
      <button class="btn btn-secondary w-100 m-1" v-on:click.prevent="showBlacklistSettings()">Blacklist settings</button>
      <div class="w-100" v-if="blacklistSettings.show">
          <ModelWidget model="blacklist">
            <template class="w-100" v-slot:default="slotData">
              <div class="row mx-0 w-100">
                <div class="card m-1" v-for="item in slotData.result.items" :key="'card--'+item.id">
                  <div class="card-body" :class="blacklistSettings.blacklistEnabled.find((el) => el === item.id) !== undefined? 'bg-success text-white' : ''" style="padding: 1rem">
                    <h5 class="card-title my-0">{{ item.name }}</h5>
                    <label for="toggle" class="slot" style="color: black !important;">
                      {{blacklistSettings.blacklistEnabled.find((el) => el === item.id) === undefined ? "Disabled" : "Enabled"}}
                    </label>
                    <input :id="'blacklist-switch-'+item.id" :checked="blacklistSettings.blacklistEnabled.find((el) => el === item.id) !== undefined" v-on:click="(e) => onClickBlacklistSwitch(e, item)" class="toggle" type="checkbox">
                  </div>
                </div>
              </div>
            </template>
          </ModelWidget>
      </div>

      <button class="btn btn-success w-100 py-3 my-3">
        <img class="icon mr-2" src="../../assets/icons/save.svg" alt="save" />
        <span>Submit</span>
      </button>
    </form>
    <div v-else class="pt-5 px-3">
      <p class="text-center">
        <img src="../../assets/icons/clock.svg" width="200" alt="" />
      </p>

      Loading ({{ createSearchCount }} /
      {{ selectedCountries.length * queries.length }})
      <div class="progress">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{
            width: `${
              (createSearchCount /
                (selectedCountries.length * queries.length)) *
              100
            }%`,
          }"
          :aria-valuenow="
            parseInt(
              (createSearchCount /
                (selectedCountries.length * queries.length)) *
                100
            )
          "
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{
            parseInt(
              createSearchCount / (selectedCountries.length * queries.length)
            ) * 100
          }}%
        </div>
      </div>

      <button
        v-on:click="
          (searchProcessing = false),
            (showSearchForm = false),
            (createSearchForm.queries = null),
            (createSearchForm.switchTextArea = false)
        "
        class="btn btn-success w-100 py-3 my-3"
        :disabled="
          parseInt(
            createSearchCount / (selectedCountries.length * queries.length)
          ) *
            100 !=
          100
        "
      >
        <img
          class="icon mr-2"
          src="../../assets/icons/magnifier.svg"
          alt="view results"
        />
        <span>View results</span>
      </button>
    </div>
  </modal>

  <modal
    :title="'delete ' + project.name"
    :active="deleteProject.show"
    :width="30"
    :height="30"
    v-on:modal-close="deleteProject.show = false"
  >
    <div class="px-5">
      <p>Are you sure ?</p>
      <p>You will remove the project named '{{ project.name }}'</p>
       <button
        class="btn btn-danger w-100 mt-3 py-3"
        v-on:click="onDeleteProject"
      >
        <img class="icon" src="../../assets/icons/garbage.svg" alt="" />
        <span>Remove</span>
      </button>
    </div>
  </modal>

  <modal
    title="Selection"
    :active="showSelection"
    :width="60"
    :height="60"
    v-on:modal-close="showSelection = false"
  >
    <content-panel>
      <div class="px-5">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Query</th>
              <th scope="col">Tags</th>
              <th scope="col">Country</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in selectedItems" :key="index">
              <td>{{ item.created_at }}</td>
              <td class="w-25">{{ item.value }}</td>
              <td>
                <span
                  v-for="tag in JSON.parse(item.tags)"
                  :key="item.run_id + tag"
                  class="badge bg-dark text-white m-1"
                  >{{ tag }}</span
                >
              </td>
              <td>
                <img
                  v-for="country in item.countries"
                  :key="'selection-' + country"
                  class="rounded mr-1"
                  :src="getCountryFlagByName(country)"
                  width="30"
                  :alt="country"
                />
                {{ item.country }}
              </td>
              <td>
                <button class="btn btn-danger" v-on:click="onCheckItem(item)">
                  <img
                    src="../../assets/icons/cancel.svg"
                    alt=""
                    class="icon"
                  />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <template #toolbar>
        <button
          class="btn btn-warning float-left"
          v-on:click.prevent="downloadSelection"
          :disabled="selectedItems.length == 0"
        >
          Download
        </button>
        <button
          class="btn btn-danger float-right"
          v-on:click.prevent="selectedItems = []"
          :disabled="selectedItems.length == 0"
        >
          Clear
        </button>
      </template>
    </content-panel>
  </modal>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal";
import Pagination from "../../components/Pagination";
import ManagePermissionBtn from "../../components/ManagePermissionBtn";
import ModelWidget from "@/components/ModelWidget.vue";

export default {
  name: "ProjectItem",
  components: {ModelWidget, ContentPanel, Modal, Pagination, ManagePermissionBtn },
  props: {
    id: {
      required: true,
    },
  },
  data: function () {
    return {
      project: {
        name: null,
      },
      items: [],
      countries: this.$API.get("searches").COUNTRIES.sort((a, b) => {
        return a.name.localeCompare(b.name);
      }),
      countriesByPercentage: [],
      countriesByPercentageEnabled: true,
      disableDownload: false,
      selectedCountries: [],
      selectedItems: [],
      searchesNumber: 0,
      showSearchForm: false,
      showSelection: false,
      searchProcessing: false,
      createSearchCount: 0,
      query: null,
      createSearchForm: {
        queries: null,
        switchTextArea: false,
        key18Down: false,
        key13Down: false,
        requestLimit: {
          value: 0,
          max: 10,
        },
      },
      tags: null,
      showTags: false,
      nblines: "",
      viewItem: {
        name: null,
        data: [],
        show: false,
        item: {
          query: null,
          blacklist: [],
        },
        copied: false,
        tabBlacklist: false,
      },
      deleteItem: {
        item: {
          query: null,
        },
        show: false,
      },
      deleteProject: {
        item: {
          query: null,
        },
        show: false,
      },
      pagination: {
        index: 0,
        indexMax: 0,
        offset: 20,
      },
      filter: {
        query: null,
      },
      blacklistSettings: {
        show: false,
        blacklistEnabled: [],
        checked: {}
      },
    };
  },
  created: async function () {
    this.$EventManager.triggerLoadingStart();
    await this.refresh();
    this.$EventManager.triggerLoadingEnd();
  },
  computed: {
    queries: function () {
      return this.createSearchForm.switchTextArea
        ? this.createSearchForm.queries
            .split("\n")
            .map((q) => q.trim())
            .filter((q) => q.trim() !== "")
        : [this.createSearchForm.queries?.trim()];
    },
    canChangeProject: function () {
      return (
        this.$store.getters.permissions.find(
          (perm) => perm === "pages.main.projects.update"
        ) ||
        this.$store.getters.permissions.find(
          (perm) => perm === `pages.main.projects.${this.id}.update`
        )
      );
    },
    canDeleteProject: function () {
      return (
        this.$store.getters.permissions.find(
          (perm) => perm === "pages.main.projects.delete"
        ) ||
        this.$store.getters.permissions.find(
          (perm) => perm === `pages.main.projects.${this.id}.delete`
        )
      );
    },
  },
  methods: {
    createSearchesV2: async function () {
      this.createSearchCount = 0;
      this.searchProcessing = true;

      for (
        let i = 0;
        i < this.queries.length;
        i += this.createSearchForm.requestLimit.max
      ) {
        let qs = this.queries.slice(
          i,
          i + this.createSearchForm.requestLimit.max
        );
        await Promise.all(qs.map((q) => this.createSearches(q)));
      }

      if (this.queries.length > 1) {
        this.refresh();
      }
      this.createSearchForm = {
        queries: this.createSearchForm.queries,
        switchTextArea: this.createSearchForm.switchTextArea,
        key18Down: false,
        key13Down: false,
        requestLimit: {
          value: 0,
          max: 10,
        },
      };
    },
    createSearches: async function (query = null) {
      if (query == null) {
        query = this.query;
        this.createSearchCount = 0;
        this.searchProcessing = true;
      }
      let promises = [];
      let runId =
        query.replaceAll(" ", "-").trim() +
        Date.now() +
        this.$store.getters.uid;
      for (const country of this.selectedCountries) {
        promises.push(this.makeASearch(country, runId, query));
      }
      await Promise.all(promises).then(() => {
        if (this.queries.length <= 1) {
          this.query = null;
          this.refresh();
        }
      });
    },
    makeASearch: async function (country, runId, query = null) {
      if (query == null) {
        query = this.query;
      }
      await this.$API
        .get("searches")
        .create(
          query.trim(),
          this.nblines,
          country,
          this.tags,
          this.id,
          this.$API.get("blacklists").getEnabled(),
          this.$store.getters.uid,
          runId,
          this.$store.getters.token
        );
      this.createSearchCount++;
    },
    getGroups: async function () {
      await this.$API
        .get("searches")
        .getSearchByGroup(this.$store.getters.token);
    },
    getCountryFlag(code) {
      var images = require.context("../../assets/flags/", false, /\.svg$/);
      return images("./" + code + ".svg");
    },
    getCountryFlagByName(name) {
      let country = this.countries.find((el) => el.name === name);
      var images = require.context("../../assets/flags/", false, /\.svg$/);
      return images("./" + country.code + ".svg");
    },
    clickOnCountry: function (country) {
      if (this.selectedCountries.includes(country.name)) {
        let index = this.selectedCountries.indexOf(country.name);
        this.selectedCountries.splice(index, 1);
      } else {
        this.selectedCountries.push(country.name);
      }
    },
    download: async function () {
      this.disableDownload = true;
      await this.$API
        .get("projects")
        .download(this.project, this.$store.getters.token);
      this.disableDownload = false;
    },
    downloadSelection: function () {
      this.$API
        .get("projects")
        .downloadSelectionGroup(
          this.project,
          this.selectedItems,
          this.$store.getters.token
        );
    },
    view: function (item) {
      this.viewItem.item = item;
      this.viewItem.name = `[${item.createdAt}]-${this.project.name.replaceAll(
        " ",
        "-"
      )}-${item.query.replaceAll(" ", "-")}-${item.country.replaceAll(
        " ",
        "-"
      )}.csv`;
      this.viewItem.data = this.$API.get("searches").toArray(item);
      this.viewItem.tabBlacklist = false;
      this.viewItem.show = true;
    },
    viewSelection: function () {
      this.showSelection = false;
      this.viewItem.name = `Selection`;
      let res = this.$API.get("searches").selectionToArray(this.selectedItems);
      this.viewItem.data = res.data;
      this.viewItem.item = res.fakeItem;
      this.viewItem.tabBlacklist = false;
      this.viewItem.show = true;
    },
    copy: function (item) {
      navigator.clipboard.writeText(item.result);
      this.viewItem.copied = true;
      setTimeout(() => (this.viewItem.copied = false), 3000);
    },
    remove: async function () {
      await this.$API
        .get("searches")
        .remove(
          this.deleteItem.item.id,
          this.$store.getters.uid,
          this.$store.getters.token
        );
      this.deleteItem.show = false;
      this.refresh();
    },
    refresh: async function () {
      if (this.filter.query && this.filter.query.trim() !== "") {
        await this.filterByQuery();
      } else {
        this.project = await this.$API
          .get("projects")
          .read(this.id, this.$store.getters.token);

        let res = await this.$API
          .get("searches")
          .getSearchByGroup(
            this.id,
            this.pagination.offset,
            this.pagination.index,
            this.$store.getters.token
          );
        this.items = res.items;
        this.searchesNumber = res.count;
        this.pagination.indexMax = res.count;
      }
      this.countriesByPercentage = (await this.$API
        .get("stats").countriesByPercentageUsed(this.$store.getters.token)).map(el => {
          return {
            name:el[0],
            count:el[1],
            percentage:el[2],
          }
      })
    },
    async onDeleteProject() {
      await this.$API
        .get("projects")
        .remove(this.id, this.$store.getters.uid, this.$store.getters.token);
      this.$EventManager.triggerRefreshUserInfo();
      this.$router.push({ name: "Projects" });
    },
    previousPage: async function () {
      this.$EventManager.triggerLoadingStart();
      this.pagination.index -= this.pagination.offset;
      await this.refresh();
      document.body.scrollTop = 0;
      this.$EventManager.triggerLoadingEnd();
    },
    nextPage: async function () {
      this.$EventManager.triggerLoadingStart();
      this.pagination.index += this.pagination.offset;
      await this.refresh();
      document.body.scrollTop = 0;
      this.$EventManager.triggerLoadingEnd();
    },
    filterByQuery: async function () {
      if (this.filter.query && this.filter.query.trim() !== "") {
        this.project = await this.$API
          .get("projects")
          .read(this.id, this.$store.getters.token);
        let res = await this.$API
          .get("searches")
          .filterQueryAndCountry(
            this.id,
            this.filter.query,
            this.pagination.offset,
            this.pagination.index,
            this.$store.getters.token
          );
        this.items = res.items;
        this.searchesNumber = res.count;
        this.pagination.indexMax = res.count;
      } else {
        await this.refresh();
      }
    },
    cancelFilterByQuery: async function () {
      this.filter.query = null;
      await this.refresh();
    },
    onCheckItem: function (item) {
      if (this.itemIsSelected(item)) {
        let index = this.selectedItems.findIndex(
          (i) => i.run_id === item.run_id
        );
        this.selectedItems.splice(index, 1);
      } else {
        this.selectedItems.push(item);
      }
    },
    onCheckAllItems: function () {
      if (this.items.every(this.itemIsSelected)) {
        for (const item of this.items) {
          let index = this.selectedItems.findIndex(
            (i) => i.run_id === item.run_id
          );
          if (index >= 0) {
            this.selectedItems.splice(index, 1);
          }
        }
      } else {
        for (const item of this.items) {
          let index = this.selectedItems.findIndex(
            (i) => i.run_id === item.run_id
          );
          if (index === -1) {
            this.selectedItems.push(item);
          }
        }
      }
    },
    itemIsSelected: function (item) {
      return (
        this.selectedItems.find((i) => i.run_id === item.run_id) !== undefined
      );
    },
    filterByTag: function (tag) {
      this.filter.query = `"${tag}"`;
      this.filterByQuery();
    },
    createSearchesCheckKeyDown: function (e) {
      if (e.keyCode === 13) {
        this.createSearchForm.key13Down = true;
      }
      if (e.keyCode === 18) {
        this.createSearchForm.key18Down = true;
      }
      if (this.createSearchForm.key13Down && this.createSearchForm.key18Down) {
        if (this.createSearchForm.queries) {
          this.createSearchForm.queries += "\n";
        }
        this.createSearchForm.switchTextArea = true;
        this.createSearchForm.key13Down = false;
        this.createSearchForm.key18Down = false;
        setTimeout(() => {
          this.$refs["createSearchesTextarea"].focus();
        }, 200);
      }
    },
    createSearchesCheckKeyUp: function (e) {
      if (e.keyCode === 13) {
        this.createSearchForm.key13Down = false;
      }
      if (e.keyCode === 18) {
        this.createSearchForm.key18Down = false;
      }
    },
    showBlacklistSettings: function (){
      this.blacklistSettings.blacklistEnabled = this.$API.get("blacklists").getEnabled();
      this.blacklistSettings.show = !this.blacklistSettings.show;
    },
    onClickEnableBlacklist(blacklist) {
      this.$API.get("blacklists").enable(blacklist);
      this.blacklistSettings.blacklistEnabled = this.$API.get("blacklists").getEnabled();
    },
    onClickDisableBlacklist(blacklist) {
      this.$API.get("blacklists").disable(blacklist);
      this.blacklistSettings.blacklistEnabled = this.$API.get("blacklists").getEnabled();
    },
    onClickBlacklistSwitch(e, item){
      if(this.blacklistSettings.blacklistEnabled.find((el) => el === item.id) !== undefined) {
        this.onClickDisableBlacklist(item)
      } else{
        this.onClickEnableBlacklist(item)
      }
    }
  },
};
</script>

<style scoped>
.large-cell {
  min-width: 400px;
}




.toggle, .toggle:before, .slot__label, .curtain {
  transition-property: background-color, transform, visibility;
  transition-duration: 0.25s;
  transition-timing-function: ease-in, cubic-bezier(0.6,0.2,0.4,1.5), linear;
}
.toggle:before, .slot, .slot__label {
  display: block;
}
.toggle:before, .curtain {
  position: absolute;
}
.toggle:checked, .curtain {
  background-color: white;
}
.toggle:focus {
  outline: transparent;
}
.toggle {
  border-radius: 0.75em;
  box-shadow: 0 0 0 0.1em inset;
  cursor: pointer;
  position: relative;
  margin-right: 0.25em;
  width: 3em;
  height: 1.5em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
}
.toggle:before {
  background: currentColor;
  border-radius: 50%;
  content: "";
  top: 0.2em;
  left: 0.2em;
  width: 1.1em;
  height: 1.1em;
}
.toggle:checked:before {
  transform: translateX(1.5em);
}
.toggle:checked ~ .slot .slot__label, .slot__label:nth-child(2) {
  transform: translateY(-50%) scaleY(0);
}
.toggle:checked ~ .slot .slot__label:nth-child(2) {
  transform: translateY(-100%) scaleY(1);
}
.toggle:checked ~ .curtain {
  transform: scaleX(1);
}
.slot{
  font-size: 15px;
  padding: 0px;
  margin: 0px;
}
.curtain {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scaleX(0);
  transform-origin: 0 50%;
  z-index: -1;
}
</style>

