<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-50">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / Companies
      </div>
      <ModelSearchBar
        model="company"
        v-on:get-result="setItems"
        v-on:reset-result="reload"
        :limit="pagination.offset"
        :offset="pagination.index"
      ></ModelSearchBar>
    </div>
    <div class="pt-5">
      <section class="height-fit-content">
        <table class="table table-striped w-100">
          <thead>
            <tr>
              <th scope="col" class="w-25">Name</th>
              <th scope="col">Completion</th>
              <th scope="col">Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>{{ item.name }}</td>
              <td>
                <div
                  class="progress-bar bg-dark mt-2"
                  role="progressbar"
                  :style="
                    'width:' +
                    parseInt(item.corporate_infos_score * 100) +
                    '%;height:20px'
                  "
                  :aria-valuenow="parseInt(item.corporate_infos_score * 100)"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ parseInt(item.corporate_infos_score * 100) }}%
                </div>
              </td>
              <td>{{ item.createdAt }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'CompanyItem',
                    params: { company_id: item.id },
                  }"
                  class="btn btn-secondary"
                  ><img
                    src="../../assets/icons/magnifier.svg"
                    alt=""
                    class="icon"
                /></router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex w-100">
          <pagination
            class="w-75"
            :index="pagination.index"
            :index-max="pagination.indexMax"
            :offset="pagination.offset"
            @previous-page="previousPage"
            @next-page="nextPage"
          />
          <div class="w-25 text-right">{{ items.length }} item(s)</div>
        </div>
      </section>
    </div>

    <template #toolbar>
      <button class="btn btn-success" v-on:click="modalCreateCompany.show()">
        Create
      </button>
    </template>
  </content-panel>

  <Modal
    title="Create Company"
    v-on:modal-close="modalCreateCompany.hide()"
    :active="modalCreateCompany.visible.value"
    :width="50"
    :height="60"
    top="90"
  >
    <FormToCreateCompany :modal="modalCreateCompany"></FormToCreateCompany>
  </Modal>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal";
import ModelSearchBar from "../../components/ModelSearchBar.vue";
import FormToCreateCompany from "@/forms/FormToCreateCompany";
import Pagination from "@/components/Pagination";
import * as ModalClass from "@/assets/js/Modal";


export default {
  name: "Companies",
  components: {
    ContentPanel,
    Modal,
    ModelSearchBar,
    FormToCreateCompany,
    Pagination,
  },
  data: function () {
    return {
      items: [],
      showFormCreateCompany: false,
      pagination: {
        index: 0,
        indexMax: 0,
        offset: 20,
      },
      modalCreateCompany: new ModalClass.default(this, 'createCompany')
    };
  },
  created: async function () {
    await this.reload();
  },
  computed: {
    sortedItems: function () {
      return this.items;
    },
  },
  methods: {
    reload: async function () {
      this.$EventManager.triggerLoadingStart();
      let res = await this.$API
        .get("companies")
        .list(
          this.pagination.offset,
          this.pagination.index,
          this.$store.getters.token
        );
      this.items = res.items;
      this.pagination.indexMax = res.count;
      this.$EventManager.triggerLoadingEnd();
    },
    previousPage: async function () {
      this.pagination.index -= this.pagination.offset;
      await this.reload();
      document.body.scrollTop = 0;
    },
    nextPage: async function () {
      this.pagination.index += this.pagination.offset;
      await this.reload();
      document.body.scrollTop = 0;
    },
    setItems: function (result) {
      this.items = result.items;
      this.pagination.indexMax = result.count;
    },
  },
};
</script>

<style></style>
