<template>
    <!--  MODALITY / TECHNO  -->
    <Slide
      :slider="slider"
      :index="0"
      class="px-4"
      :use-submit="false"
      :disable-btn-next="tmp.cache.technology_id==null"
    >
      <div class="my-5">
        <h4>Select a modality <RequiredIcon/></h4>
        <button
          v-for="{ id, name } in tmp.modalities"
          :key="`mod-${id}`"
          class="btn m-1 p-4"
          :class="
            tmp.cache.modality_id === id
              ? 'btn-outline-success'
              : 'btn-secondary'
          "
          v-on:click.prevent="
            () => {
              tmp.cache.modality_id = id;
              tmp.cache.technology_id = null;
            }
          "
        >
          {{ name }}
        </button>
      </div>
      <div class="mb-5">
        <h4>Select a technology <RequiredIcon/></h4>
        <button
          v-show="tmp.cache.modality_id == null"
          class="btn m-1 p-4 btn-secondary"
          disabled
        >
          None
        </button>
        <button
          v-for="{ id, name } in getTechnologiesByCachedModality"
          :key="`tech-${id}`"
          class="btn m-1 p-4"
          :class="
            tmp.cache.technology_id === id
              ? 'btn-outline-success'
              : 'btn-secondary'
          "
          v-on:click.prevent="tmp.cache.technology_id = id"
        >
          {{ name }}
        </button>
      </div>
    </Slide>

    <!-- TYPOLOGIES  -->
    <Slide
      :slider="slider"
      :index="1"
      class="px-4"
      :use-submit="false"
      :needConfirmationPrevious="true"
      :disable-btn-next="tmp.cache.selectedTypologies.length===0"
    >
      <div class="my-5">
        <h4>Select typologies <RequiredIcon/></h4>
        <div
          v-for="{ id, name, typologies } in tmp.cache.general_typologies"
          :key="`g_typologies-${id}`"
          class="my-4 pb-3 border-bottom"
        >
          <h5>{{ name }}</h5>
          <button
            v-for="typology in typologies"
            :key="`typologies-${typology.id}`"
            class="btn m-1 p-4"
            :class="
              tmp.cache.selectedTypologies.includes(typology.id)
                ? 'btn-outline-success'
                : 'btn-secondary'
            "
            v-on:click.prevent="
              () => {
                if (tmp.cache.selectedTypologies.includes(typology.id)) {
                  let index = tmp.cache.selectedTypologies.findIndex(
                    (el) => el === typology.id
                  );
                  tmp.cache.selectedTypologies.splice(index, 1);
                } else {
                  tmp.cache.selectedTypologies.push(typology.id);
                }
              }
            "
          >
            {{ typology.name }}
          </button>
        </div>
      </div>
    </Slide>

    <!--  VVC  -->
    <Slide
      :slider="slider"
      :index="2"
      class="px-4"
      :use-submit="false"
      :disable-btn-next="tmp.cache.selectedVcs.length===0"
    >
      <div class="my-5">
        <h4>Select columns <RequiredIcon/></h4>
        <button
          v-for="{ id, name } in tmp.cache.vccs"
          :key="`mod-${id}`"
          class="btn m-1 p-4"
          :class="
            tmp.cache.selectedVcs.includes(id)
              ? 'btn-outline-success'
              : 'btn-secondary'
          "
          v-on:click.prevent="onSelectVcc(id)"
        >
          {{ name }}
        </button>
      </div>
    </Slide>

    <!--  SERVICES  -->
    <Slide
      v-for="(vcc, index) in tmp.cache.selectedVcs"
      :key="'form_step-vcc-' + index"
      :slider="slider"
      :index="selectedVcs_slideIds_reverse[index]"
      class="px-4"
      :use-submit="false"
      :show-required="false"
    >
      <h3>Select services</h3>
      <h5 class="btn btn-outline-secondary">
        {{ tmp.cache.vccs_data[vcc]?.name }}
      </h5>
      <div
        v-for="(services, typo_id) in tmp.cache.vccs_data[vcc]?.services"
        :key="`vcc-typo-${typo_id}`"
      >
        <div
          v-show="tmp.cache.selectedTypologies.includes(typo_id)"
          class="my-4 pb-3 border-bottom"
        >
          <h5>
            {{ tmp.cache.typologies[typo_id]?.general_typology.name }} /
            {{ tmp.cache.typologies[typo_id]?.name }}
          </h5>

          <button
            v-for="service in services"
            :key="`service-${service.id}`"
            :disabled="
              company.services.find((cs) => cs.id === service.id) !== undefined
            "
            class="btn m-1 p-4"
            :class="
              tmp.cache.selectedServices.findIndex(
                (el) => el.id === service.id
              ) >= 0
                ? 'btn-outline-success'
                : 'btn-secondary'
            "
            v-on:click.prevent="
              () => {
                if (
                  tmp.cache.selectedServices.findIndex(
                    (el) => el.id === service.id
                  ) >= 0
                ) {
                  let pos = tmp.cache.selectedServices.findIndex(
                    (el) => el.id === service.id
                  );
                  tmp.cache.selectedServices.splice(pos, 1);
                } else {
                  tmp.cache.selectedServices.push(service);
                }
              }
            "
          >
            {{ service.name }}
          </button>
        </div>
      </div>
    </Slide>

    <!--  RECAP  -->
    <Slide
      :slider="slider"
      :index="3"
      class="px-4"
      :disable-btn-submit="summary.services_to_save.length===0"
      :hide-next="true"
      @submit="formAddService.submit()"
    >
      <h3>Summary</h3>
      <table class="table mt-5">
        <tbody>
          <tr>
            <th>Modality</th>
            <td>{{ summary.modality?.name }}</td>
          </tr>
          <tr>
            <th>Technology</th>
            <td>{{ summary.technology?.name }}</td>
          </tr>
          <tr>
            <th>Typologies</th>
            <td>
              <ul>
                <li
                  v-for="gtypo in summary.general_typologies"
                  :key="`summary-gtypo-${gtypo.id}`"
                >
                  {{ gtypo.name }}
                  <ul>
                    <li
                      v-for="typo in gtypo.typologies.filter(
                        (el) =>
                          summary.typologies.find((st) => st.id === el.id) !==
                          undefined
                      )"
                      :key="`summary-typo-${typo.id}`"
                    >
                      {{ typo.name }}
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>Value chain columns</th>
            <td>
              <ul>
                <li
                  v-for="vcc in summary.value_chain_columns"
                  :key="`summary-vcc-${vcc.id}`"
                >
                  {{ vcc.name }}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>Services <RequiredIcon/></th>
            <td>
              <table class="table table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th
                      scope="col"
                      v-for="vcc in summary.value_chain_columns"
                      :key="`summary-services-vcc-${vcc.id}`"
                    >
                      {{ vcc.name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(s, index) in summary.services"
                    :key="`summary-services-${index}`"
                  >
                    <th scope="row">{{ s[0] }}</th>
                    <td
                      v-for="(svcc, svcc_i) in s.slice(1)"
                      :key="`summary-services-${index}-${svcc_i}`"
                    >
                      <ul>
                        <li
                          v-for="ss in svcc"
                          :key="`summary-services-${index}-${svcc_i}-${ss.id}`"
                        >
                          {{ ss.name }}
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex bg-secondary p-2">
                <button
                  class="btn btn-danger"
                  v-on:click.prevent="
                    () => {
                      this.tmp.cache.selectedServices = [];
                    }
                  "
                  :disabled="summary.services_to_save.length <= 0"
                >
                  Clear services
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Slide>
</template>

<script>
import { createForm } from "@/assets/js/FormManager";
import Modal from "@/assets/js/Modal";
import Slide from "@/components/Slide.vue";
import {useSlider} from "@/assets/js/Slider";
import RequiredIcon from "@/components/RequiredIcon.vue";
import {UpdateMessage} from "@/assets/js/Alert";

export default {
  name: "FormAddServiceToCompany",
  components: {RequiredIcon, Slide },
  props: {
    company: {
      type: Object,
      required: true,
    },
    modal: {
      type: Modal,
      required: true
    },
  },
  setup(){
    const slider = useSlider([
      {id:0, next:1, prev:null, protected: true, dynamic: false, description: "Select modality techno"},
      {id:1, next:2, prev:0, protected: true, dynamic: false, description: "Select typologies"},
      {id:2, next:3, prev:1, protected: true, dynamic: false, description: "Select VVC"},
      {id:3, next:null, prev:2, protected: true, dynamic: false, description: "Recap"},
    ])
    return {
      slider
    }
  },
  data: function () {
    return {
      formAddService: createForm(this, "addService"),
      lockPrevious: false,
      tmp: {
        modalities: [],
        technologies: [],
        cache: {
          modality_id: null,
          technology_id: null,
          vccs: [],
          selectedVcs: [],
          selectedVcs_slideIds: [],
          vccs_data: {},
          selectedServices: [],
          typologies: {},
          general_typologies: {},
          selectedGeneralTypologies: [],
          selectedTypologies: [],
        },
      },
    };
  },
  async created() {
    let offset = 0;
    let { items, count } = await this.$API
      .get("functions")
      .list("technology", 50, offset, this.$store.getters.token);
    this.tmp.technologies = items;

    while (this.tmp.technologies.length < count) {
      offset += 50;
      let { items } = await this.$API
        .get("functions")
        .list("technology", 50, offset, this.$store.getters.token);
      this.tmp.technologies = [...this.tmp.technologies, ...items];
    }

    this.tmp.technologies.forEach((tech) => {
      if (!this.tmp.modalities.map((m) => m.id).includes(tech.modality_id)) {
        this.tmp.modalities.push(tech.modality);
      }
    });

    this.slider.subscribe("slider-slide-change", async (data) => {
      let [currentSlide, lastSlide] = data

      // Modality & Techno
      if (currentSlide.id === 0){
        this.resetCache()

        // Modality & Techno > Typologies
      }else if (currentSlide.id === 1 && lastSlide.id === 0){
        let res = await this.getTypologiesByTechnologyId(this.tmp.cache.technology_id, 20)
        if(res.count>20){
          res = await this.getTypologiesByTechnologyId(this.tmp.cache.technology_id, res.count)
        }
        this.tmp.cache.typologies = res.items
        this.tmp.cache.general_typologies = []
        for (const item of res.items) {
          let i_gtypo = this.tmp.cache.general_typologies.findIndex(gt => gt.id === item.general_typology.id)
          if (i_gtypo>=0){
            this.tmp.cache.general_typologies[i_gtypo].typologies.push(item)
          }else{
            this.tmp.cache.general_typologies.push({...item.general_typology, typologies:[item]})
          }
        }
        // Modality & Techno > Typologies > VCCs
      }else if (currentSlide.id === 2 && lastSlide.id === 1){
        let res = await this.getValueChainColumnsByTechnologyId(this.tmp.cache.technology_id, 20)
        if(res.count>20){
          res = await this.getValueChainColumnsByTechnologyId(this.tmp.cache.technology_id, res.count)
        }
        this.tmp.cache.vccs = res.items
        // Modality & Techno > Typologies > VCCs > Services
      }else if (currentSlide.id > 3){
        let index = this.selectedVcs_slideIds_reverse.findIndex(el => el===currentSlide.id)
        let vcc_id = this.tmp.cache.selectedVcs[index]
        let vcc = this.tmp.cache.vccs.find(el => el.id === vcc_id)
        let res = await this.getServicesByValueChainColumnsId(vcc_id, 20, 0)
        if (res.count > 20){
          res = await this.getServicesByValueChainColumnsId(vcc_id, res.count, 0)
        }
        let services = {}
        res.items.forEach(i => {
          if (Object.keys(services).includes(i.typology_id)){
            services[i.typology_id].push(i)
          }else{
            services[i.typology_id] = [i]
          }
        })
        this.tmp.cache.vccs_data[vcc_id] = {...vcc, services:services}
      }

    })


    this.formAddService.onSubmit(async () => {
      let res = await this.$API
        .get("companies")
        .add_services(this.company.id, this.summary.services_to_save, this.$store.getters.token)

      this.$EventManager.triggerAlert(UpdateMessage(res!==null))
      await this.modal.hide()
      this.resetCache();
      this.$emit("submit")
    });
  },
  computed: {
    selectedVcs_slideIds_reverse: function () {
      return [...this.tmp.cache.selectedVcs_slideIds].reverse()
    },
    getTechnologiesByCachedModality: function () {
      return this.tmp.cache.modality_id
        ? this.tmp.technologies.filter(
            (tech) => tech.modality_id === this.tmp.cache.modality_id
          )
        : [];
    },
    summary: function () {
      let res = {
        modality: this.tmp.modalities?.find(
          (el) => el.id === this.tmp.cache.modality_id
        ),
        technology: this.tmp.technologies?.find(
          (el) => el.id === this.tmp.cache.technology_id
        ),
        general_typologies: Object.values(
          this.tmp.cache.general_typologies
        ).filter((el) =>
          Object.values(this.tmp.cache.typologies)
            ?.filter((el) => this.tmp.cache.selectedTypologies.includes(el.id))
            .map((st) => st.general_typology_id)
            .includes(el.id)
        ),
        typologies: Object.values(this.tmp.cache.typologies)?.filter((el) =>
          this.tmp.cache.selectedTypologies.includes(el.id)
        ),
        value_chain_columns: Object.values(this.tmp.cache.vccs_data).filter(
          (vcc) => this.tmp.cache.selectedVcs.includes(vcc.id)
        ),
      };
      res.services = [];
      res.services_to_save = [];
      res.typologies?.forEach((t) => {
        let index = res.services.length;
        res.services[index] = [`${t.general_typology.name} / ${t.name}`];
        res.value_chain_columns?.forEach((vcc) => {
          let tmp_serv = this.tmp.cache.selectedServices.filter(
            (s) => s.value_chain_column_id === vcc.id && s.typology_id === t.id
          );
          res.services[index].push(tmp_serv);
          tmp_serv.forEach((serv) => res.services_to_save.push(serv));
        });
      });

      return res;
    },
  },
  methods: {
    getRealVccSliderIndex: function (index) {
      return this.selectedVcs_slideIds_reverse[index]
    },
    onSelectVcc: function (id) {
      if (this.tmp.cache.selectedVcs.includes(id)) {
        let index = this.tmp.cache.selectedVcs.findIndex((el) => el === id);
        this.tmp.cache.selectedVcs.splice(index, 1);

        let slideId = this.slider.slide.value.next
        this.slider.removeSlide(slideId)

        let indexSlideId = this.tmp.cache.selectedVcs_slideIds.findIndex(el => el===slideId)
        this.tmp.cache.selectedVcs_slideIds.splice(indexSlideId, 1);
      } else {
        this.tmp.cache.selectedVcs.push(id);
        let slideIds = this.slider.addDynamicSlide(this.slider.slide.value.id, 1)
        let index = slideIds[0]
        this.tmp.cache.selectedVcs_slideIds.push(index)
      }
    },
    resetCache: function () {
      for (const slideId of this.tmp.cache.selectedVcs_slideIds) {
        this.slider.removeSlide(slideId)
      }
      this.tmp.cache = {
        modality_id: null,
        technology_id: null,
        vccs: [],
        selectedVcs: [],
        selectedVcs_slideIds: [],
        vccs_data: {},
        selectedServices: [],
        typologies: {},
        general_typologies: {},
        selectedGeneralTypologies: [],
        selectedTypologies: [],
      };
      this.slider.currentSlideId.value = this.slider.firstSlide.value.id
    },
    getTypologiesByTechnologyId: async function(technology_id, limit, offset = 0){
      return await this.$API.get("functions").filters(
        {
          "entity": "typology",
          "operator": null,
          "fields": {}
        },
        [
          {
            "entity": "technology",
            "operator": "AND",
            "fields": {
              "id": technology_id
            }
          }
        ],
        limit,
        offset,
        this.$store.getters.token
      )
    },
    getValueChainColumnsByTechnologyId: async function(technology_id, limit, offset = 0){
      return await this.$API.get("functions").filters(
        {
          "entity": "value_chain_column",
          "operator": null,
          "fields": {}
        },
        [
          {
            "entity": "technology",
            "operator": "AND",
            "fields": {
              "id": technology_id
            }
          }
        ],
        limit,
        offset,
        this.$store.getters.token
      )
    },
    getServicesByValueChainColumnsId: async function(vcc_id, limit, offset = 0){
      return await this.$API.get("functions").filters(
        {
          "entity": "service",
          "operator": null,
          "fields": {}
        },
        [
          {
            "entity": "value_chain_column",
            "operator": "AND",
            "fields": {
              "id": vcc_id
            }
          }
        ],
        limit,
        offset,
        this.$store.getters.token
      )
    }
  },
};
</script>

<style scoped></style>
