<template>
  <button
    v-if="isLocked && !lockContentHidedOnLock"
    :disabled="true"
    :class="additionalClass"
  >
    <img
      v-show="!lockIconHided"
      class="icon"
      src="../assets/icons/padlock.svg"
      alt=""
    />
    <slot></slot>
  </button>
  <button
    v-if="isLocked && lockContentHidedOnLock"
    :disabled="true"
    :class="additionalClass"
  >
    <img
      v-show="!lockIconHided"
      class="icon"
      src="../assets/icons/padlock.svg"
      alt=""
    />
  </button>
  <button
    v-if="!isLocked"
    :disabled="false"
    :class="additionalClass"
    v-on:click.prevent="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "LockedBtn",
  props: {
    additionalClass: {
      type: String,
    },
    permission: {
      type: String,
      required: true,
    },
    lockIconHided: {
      type: Boolean,
      default: false,
    },
    lockContentHidedOnLock: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLocked: function () {
      return !this.$store.getters.permissions.includes(this.permission);
    },
  },
};
</script>

<style scoped></style>
