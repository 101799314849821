<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-75">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / Projects
      </div>
      <form v-if="false" class="w-25">
        <input type="text" name="" id="" placeholder="Search" />
        <button>
          <img src="../../assets/icons/magnifier.svg" alt="" class="icon" />
        </button>
      </form>
    </div>
    <div id="project" class="pt-5">
      <div class="row mx-0">
        <div
          v-for="item in items"
          :key="item.name"
          class="col-md-2 mb-5 project-item"
        >
          <router-link
            class="d-block h-100 pb-5"
            :to="{ name: 'ProjectItemGroup', params: { id: item.id } }"
          >
            <h3 class="text-center h-50 titleProject">{{ item.name }}</h3>
            <h4 class="">{{ item.created_at }}</h4>
            <LockedBtn
              additional-class="btn MontBold w-25 mb-1"
              :lock-icon-hided="true"
              v-on:click="
                () => {
                  projectSettings.show = true;
                  projectSettings.item = item;
                }
              "
              :permission="`pages.main.projects.${item.id}.update`"
            >
              <img
                class="icon"
                src="../../assets/icons/setting.svg"
                alt="setting"
              />
            </LockedBtn>
          </router-link>
        </div>
      </div>
    </div>
    <div class="d-flex w-100">
      <pagination
        class="w-75"
        :index="pagination.index"
        :index-max="pagination.indexMax"
        :offset="pagination.offset"
        @previous-page="previousPage"
        @next-page="nextPage"
      />
      <div class="w-25 text-right">{{ items.length }} item(s)</div>
    </div>
    <template #toolbar>
      <LockedBtn
        additional-class="btn btn-success"
        v-on:click="addProject.show = true"
        permission="pages.main.projects.create"
      >
        Create
      </LockedBtn>
    </template>
  </content-panel>
  <modal
    :title="'Project ' + projectSettings.item.name"
    :active="projectSettings.show"
    :width="30"
    :height="35"
    v-on:modal-close="projectSettings.show = false"
  >
    <div class="px-5">
      <input
        class="py-3 my-3 w-100 border text-center"
        type="text"
        v-model="projectSettings.item.name"
        :disabled="!canChangeProject(projectSettings.item.id)"
      />
      <button
        class="btn btn-success w-100 py-3"
        v-on:click="updateProject"
        :disabled="!canChangeProject(projectSettings.item.id)"
      >
        <img class="icon mr-2" src="../../assets/icons/save.svg" alt="update" />
        <span>Save</span>
      </button>
      <button
        class="btn btn-danger w-100 mt-3"
        v-on:click="
          (deleteProjectModal.show = true), (projectSettings.show = false)
        "
        :disabled="!canDeleteProject(projectSettings.item.id)"
      >
        <img
          class="icon mr-2"
          src="../../assets/icons/garbage.svg"
          alt="delete"
        />
        <span>Delete</span>
      </button>
    </div>
  </modal>

  <modal
    title="Create Project"
    :active="addProject.show"
    :width="30"
    :height="30"
    v-on:modal-close="addProject.show = false"
  >
    <div class="px-5">
      <input
        class="py-3 my-3 w-100 border text-center"
        type="text"
        name="name"
        placeholder="Name"
        v-model="addProject.name"
      />
      <button
        class="btn btn-success w-100 py-3"
        v-on:click="createProject()"
        :disabled="!canCreateProject()"
      >
        <img class="icon mr-2" src="../../assets/icons/save.svg" alt="save" />
        <span>Submit</span>
      </button>
    </div>
  </modal>

  <modal
    :title="'delete ' + projectSettings.item.name"
    :active="deleteProjectModal.show"
    :width="30"
    :height="30"
    v-on:modal-close="deleteProjectModal.show = false"
  >
    <div class="px-5">
      <p>Are you sure ?</p>
      <p>You will remove the project named '{{ projectSettings.item.name }}'</p>
      <button class="btn btn-danger w-100 mt-3 py-3" v-on:click="deleteProject">
        <img class="icon" src="../../assets/icons/garbage.svg" alt="" />
        <span>Remove</span>
      </button>
    </div>
  </modal>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal.vue";
import Pagination from "../../components/Pagination";
import LockedBtn from "@/components/LockedBtn";

export default {
  name: "Projects",
  components: { LockedBtn, ContentPanel, Modal, Pagination },
  data: function () {
    return {
      items: [],
      projectSettings: {
        show: false,
        item: {
          name: null,
        },
      },
      deleteProjectModal: {
        show: false,
        item: {
          name: null,
        },
      },
      addProject: {
        show: false,
        name: null,
      },
      pagination: {
        index: 0,
        indexMax: 0,
        offset: 20,
      },
    };
  },
  computed: {},
  methods: {
    async createProject() {
      this.$EventManager.triggerLoadingMinSetValue(50);
      await this.$API
        .get("projects")
        .create(
          this.addProject.name,
          this.$store.getters.uid,
          this.$store.getters.token
        );
      this.$EventManager.triggerRefreshUserInfo(() => this.refresh());
      this.$EventManager.triggerShakeContentPanel();
      this.$EventManager.triggerLoadingMinSetValue(100);
      this.addProject.show = false;
      this.addProject.name = null;
    },
    async deleteProject() {
      await this.$API
        .get("projects")
        .remove(
          this.projectSettings.item.id,
          this.$store.getters.uid,
          this.$store.getters.token
        );
      this.$EventManager.triggerRefreshUserInfo(() => this.refresh());
      this.deleteProjectModal.show = false;
    },
    async updateProject() {
      await this.$API
        .get("projects")
        .update(
          this.projectSettings.item.id,
          this.projectSettings.item.name,
          this.$store.getters.token
        );
      this.$EventManager.triggerRefreshUserInfo(() => this.refresh());
      this.projectSettings.show = false;
    },
    refresh: async function () {
      let res = await this.$API
        .get("projects")
        .list(
          this.pagination.offset,
          this.pagination.index,
          this.$store.getters.token
        );
      let items = res.items;
      this.pagination.indexMax = res.count;
      this.items = items.filter((item) =>
        this.$store.getters.permissions.includes(
          `pages.main.projects.${item.id}.read`
        )
      );
    },
    previousPage: async function () {
      this.$EventManager.triggerLoadingStart();
      this.pagination.index -= this.pagination.offset;
      await this.refresh();
      document.body.scrollTop = 0;
      this.$EventManager.triggerLoadingEnd();
    },
    nextPage: async function () {
      this.$EventManager.triggerLoadingStart();
      this.pagination.index += this.pagination.offset;
      await this.refresh();
      document.body.scrollTop = 0;
      this.$EventManager.triggerLoadingEnd();
    },
    canChangeProject: function (id) {
      return (
        this.$store.getters.permissions.find(
          (perm) => perm === "pages.main.projects.update"
        ) ||
        this.$store.getters.permissions.find(
          (perm) => perm === `pages.main.projects.${id}.update`
        )
      );
    },
    canDeleteProject: function (id) {
      return (
        this.$store.getters.permissions.find(
          (perm) => perm === "pages.main.projects.delete"
        ) ||
        this.$store.getters.permissions.find(
          (perm) => perm === `pages.main.projects.${id}.delete`
        )
      );
    },
    canCreateProject: function () {
      return this.$store.getters.permissions.find(
        (perm) => perm === "pages.main.projects.create"
      );
    },
  },
  created: async function () {
    this.$EventManager.triggerLoadingStart();
    await this.refresh();
    this.$EventManager.triggerLoadingEnd();
  },
};
</script>

<style scoped>
.titleProject {
  font-family: "MontHeavy";
}
#project > .row div:hover {
  background-color: #f1f1f3;
}
#project {
  text-align: center;
}
#project > .row div {
  background-color: #fff;
  margin-left: 10px;
  margin-right: 10px;
  padding: 15px;
  border: 1px solid #f1f1f3;
  border-radius: 0.25rem;
  border-top: 3px solid #fda627;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  border-radius: 0.65rem;
}

#project > .row div .btn {
  border: 1px solid #f1f1f3;
}
#project > .row div .btn:hover {
  border: 1px solid #fff;
}

.project-item a,
.project-item a:link,
.project-item a:visited,
.project-item a:hover {
  text-decoration: none;
  color: black;
}
</style>
