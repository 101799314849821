import Request from "./request";
import Utils from "../Utils";

const SERVER = "main";

export async function list(limit, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `companies/paginate/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::company::list", error);
  }
}

//  TODO: not implemented in the API
export async function filter(name, limit, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `companies/filter/${name}/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::company::filter", error);
  }
}

export async function create(company, token) {
  try {
    return await Request.post(SERVER, "companies", token, { company });
  } catch (error) {
    Utils.debugVar("Connectors::company::create", error);
  }
}

export async function read(id, token) {
  try {
    return await Request.get(SERVER, `company/${id}`, token);
  } catch (error) {
    Utils.debugVar("Connectors::company::read", error);
  }
}

export async function getServicesByCompagnyId(id, token) {
  try {
    return await Request.get(SERVER, `company/${id}/services`, token);
  } catch (error) {
    Utils.debugVar("Connectors::company::getServicesByCompagnyId", error);
  }
}

export async function update(company, token) {
  try {
    return await Request.put(SERVER, `company/${company.id}`, token, {
      company,
    });
  } catch (error) {
    Utils.debugVar("Connectors::company::update", error);
  }
}

export async function remove(company, token) {
  try {
    return await Request.delete(SERVER, `company/${company.id}`, token);
  } catch (error) {
    Utils.debugVar("Connectors::company::remove", error);
  }
}

export async function add_site(companyId, site, token) {
  try {
    return await Request.patch(SERVER, `company/${companyId}/add_site`, token, {
      site,
    });
  } catch (error) {
    Utils.debugVar("Connectors::company::add_site", error);
  }
}

export async function remove_site(companyId, site, token) {
  try {
    return await Request.patch(
      SERVER,
      `company/${companyId}/remove_site`,
      token,
      {
        site,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::company::remove_site", error);
  }
}

export async function add_service(companyId, service, token) {
  try {
    return await Request.patch(
      SERVER,
      `company/${companyId}/add_service`,
      token,
      {
        service,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::company::add_service", error);
  }
}

export async function add_services(companyId, services, token) {
  try {
    return await Request.patch(
      SERVER,
      `company/${companyId}/add_services`,
      token,
      {
        services,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::company::add_services", error);
  }
}
export async function remove_service(companyId, service, token) {
  try {
    return await Request.patch(
      SERVER,
      `company/${companyId}/remove_service`,
      token,
      {
        service,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::company::remove_service", error);
  }
}
export const OEL = ["1", "2", "3", "4", "5"];
export const OWNERSHIPTYPE = [
  "Privately Owned",
  "Listed",
  "Subsidiary of the big group",
  "PE Owned",
];
