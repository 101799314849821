var DEBUG_MODE = false;
const INTERVAL = [];

//  ACTIVE Debug mode
if (isDevelopment() || isTest()) {
  debugMode(true);
}

function debug(...values) {
  if (!DEBUG_MODE) return;
  values.forEach((element) => {
    if (
      Object.is(element) ||
      Array.isArray(element) ||
      typeof element === "object"
    ) {
      console.table(element);
    } else {
      console.log(element);
    }
  });
}

function debugVar(name, value) {
  if (!DEBUG_MODE) return;
  console.group(name);
  debug(value);
  console.groupEnd();
}

function install(app) {
  // 3. add an instance method
  app.config.globalProperties.$debug = debug;
  app.config.globalProperties.$debugVar = debugVar;
  app.config.globalProperties.$meta = meta;
  app.config.globalProperties.$isProduction = isProduction;
  app.config.globalProperties.$isDevelopment = isDevelopment;
  app.config.globalProperties.$isTest = isTest;
  app.config.globalProperties.$mode = getModeEnv;
  app.config.globalProperties.$setInterval = customSetInterval;
  app.config.globalProperties.$clearInterval = customClearInterval;
  app.config.globalProperties.$clearAllInterval = customClearAllInterval;
  app.config.globalProperties.$uid = uid;
}

function uid() {
  return "xxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function debugMode(value) {
  DEBUG_MODE = value;
}

function meta(element) {
  var metaTag = document.createElement("meta");
  let keys = Object.keys(element);
  for (let index = 0; index < keys.length; index++) {
    const field = keys[index];
    metaTag[field] = element[field];
  }
  document.head.appendChild(metaTag);
}

function isProduction() {
  return process.env.NODE_ENV === "production";
}

function isDevelopment() {
  return process.env.NODE_ENV === "development";
}

function isTest() {
  return process.env.NODE_ENV === "test";
}

function getModeEnv() {
  return process.env.NODE_ENV;
}

function customSetInterval(callback, timeout) {
  INTERVAL.push(setInterval(callback, timeout));
  return INTERVAL.length - 1;
}

function customClearInterval(id) {
  clearInterval(INTERVAL[id]);
}

function customClearAllInterval() {
  for (let index = 0; index < INTERVAL.length; index++) {
    customClearInterval(index);
  }
}

export default {
  install,
  debugMode,
  debug,
  debugVar,
  meta,
  isProduction,
  isDevelopment,
  isTest,
  getModeEnv,
};
