<template>
  <button
    v-if="createBtn"
    class="btn btn-success filter-item"
    v-on:click="createItem"
  >
    <img class="icon" src="../assets/icons/plus.svg" alt="" />
  </button>
  <div v-else class="btn btn-secondary filter-item">
    <div>
      <div class="float-left p-3">Filter {{ name }}</div>
      <button class="btn btn-danger float-right" v-on:click="removeItem">
        <img src="../assets/icons/cancel.svg" alt="" class="icon" />
      </button>
    </div>
    <div>
      <select
        class="py-2 w-100 mt-1 border text-center bg-white"
        v-model="entity"
        v-on:change="() => (!isWanted ? getItems() : sendUpdate())"
        :disabled="gettingItems"
        required
      >
        <option value="" disabled selected>Entity</option>
        <option
          v-for="entity in restrectedEntities"
          :key="entity"
          :value="entity"
        >
          {{ entity }}
        </option>
      </select>

      <select
        v-if="!isWanted"
        class="py-2 w-100 mt-1 border text-center bg-white"
        v-model="fieldKey"
        v-on:change="
          () => {
            field = '';
            value = '';
            sendUpdate();
          }
        "
        :disabled="!entity"
        required
      >
        <option value="" disabled selected>Field</option>
        <option
          v-for="item in selectedEntityField"
          :key="entity + '.' + item.value"
          :value="item.value"
        >
          {{ item.label }}
        </option>
      </select>

      <select
        v-if="!isWanted"
        :disabled="!fieldKey"
        class="py-2 w-100 mt-1 border text-center bg-white"
        v-model="field"
        v-on:change="
          () => {
            value = '';
            sendUpdate();
          }
        "
        required
      >
        <option value="" disabled selected>Value</option>
        <option
          v-for="item in fieldValues"
          :key="entity + '.' + item"
          :value="item"
        >
          {{ item }}
        </option>
      </select>

      <input
        v-if="!isWanted"
        type="text"
        class="py-2 mt-1 w-100 border text-center bg-white"
        placeholder="Search"
        v-model="value"
        v-on:keyup="
          () => {
            field = '';
            sendUpdate();
          }
        "
        v-on:change="
          () => {
            field = '';
            getItems();
          }
        "
        :disabled="!fieldKey"
      />
    </div>
  </div>
  <div
    v-if="hasNext && !(isWanted && field.trim() == '')"
    style="padding: 120px 0px"
  >
    <button v-on:click="changeOperand" class="btn btn-secondary">
      {{ operatorAnd ? "AND" : "OR" }}
    </button>
  </div>
  <img
    v-if="hasNext && isWanted && field.trim() == ''"
    src="../assets/icons/next.svg"
    alt=""
    class="icon"
  />
</template>

<script>
export default {
  name: "FilterItem",
  props: {
    createBtn: Boolean,
    name: String,
    hasNext: Boolean,
    isWanted: Boolean,
    content: Object,
  },
  data: function () {
    return {
      gettingItems: false,
      haveValue: true,
      operatorAnd: false,
      entity: "",
      fieldKey: "",
      field: "",
      value: null,
      entity_data: { items: [] },
      entities: {
        Company: [
          { value: "name", label: "Name" },
          { value: "domain_name", label: "Domain name" },
          { value: "ownership_type", label: "Ownership type" },
          { value: "headquarter", label: "Headquarter" },
          { value: "continent-headquarter", label: "Continent" },
          { value: "annual_number_of_ftes", label: "Annual number of ftes" },
          { value: "number_of_site", label: "Number of site" },
          { value: "max_oel", label: "Max oel" },
          { value: "max_scale", label: "Max scale" },
          { value: "vet", label: "Is Veterinary?" },
        ].sort((a, b) => a.label.localeCompare(b.label)),
        Modality: [{ value: "name", label: "name" }].sort((a, b) =>
          a.label.localeCompare(b.label)
        ),
        Technology: [{ value: "name", label: "name" }].sort((a, b) =>
          a.label.localeCompare(b.label)
        ),
        "Value Chain Column": [
          { value: "name", label: "name" },
          { value: "is_drug_discovery", label: "Is Drug discovery?" },
        ].sort((a, b) => a.label.localeCompare(b.label)),
        "General Typology": [{ value: "name", label: "name" }].sort((a, b) =>
          a.label.localeCompare(b.label)
        ),
        Typology: [{ value: "name", label: "name" }].sort((a, b) =>
          a.label.localeCompare(b.label)
        ),
        Service: [{ value: "name", label: "name" }].sort((a, b) =>
          a.label.localeCompare(b.label)
        ),
        Site: [
          { value: "city", label: "City" },
          { value: "label", label: "Label" },
          { value: "country", label: "Country" },
          { value: "continent-country", label: "Continent" },
          { value: "ftes", label: "FTES" },
          { value: "build_at", label: "Build at" },
        ].sort((a, b) => a.label.localeCompare(b.label)),
      },
    };
  },
  computed: {
    selectedEntityField: function () {
      return this.entity ? this.entities[this.entity] : [];
    },
    restrectedEntities: function () {
      return this.isWanted
        ? ["Company", "Site"].sort()
        : Object.keys(this.entities).sort();
    },
    fieldValues: function () {
      let values = [];
      if (["country", "location", "headquarter"].includes(this.fieldKey)) {
        values = this.entity_data.items.map(
          (elm) => JSON.parse(elm[this.fieldKey]).name
        );
      } else if (
        [
          "continent-country",
          "continent-location",
          "continent-headquarter",
        ].includes(this.fieldKey)
      ) {
        values = this.entity_data.items.map(
          (elm) =>
            JSON.parse(elm[this.fieldKey.replace("continent-", "")]).continent
        );
      } else {
        values = this.entity_data.items.map((elm) => elm[this.fieldKey]);
      }
      return [...new Set(values)].sort();
    },
  },
  methods: {
    getItems: async function () {
      this.sendUpdate();
      this.gettingItems = true;
      this.entity_data = await this.$API
        .get("functions")
        .finder(
          this.entity,
          this.value ? this.value : "",
          200,
          0,
          this.$store.getters.token
        );
      this.gettingItems = false;
    },
    createItem: function () {
      this.$emit("create-item");
    },
    removeItem: function () {
      this.$emit("remove-item", this.name);
    },
    changeOperand: function () {
      this.operatorAnd = !this.operatorAnd;
      this.sendUpdate();
    },
    sendUpdate: function () {
      let data = {};

      if (this.isWanted && this.field.trim() == "") {
        data = {
          uid: this.name,
          entity: this.entity ? this.entity : "",
          operator: null,
          fields: {},
        };
      } else {
        data = {
          uid: this.name,
          entity: this.entity ? this.entity : "",
          operator: this.operatorAnd ? "AND" : "OR",
          fields: {},
        };
      }

      let tmpFieldKey = [
        "continent-country",
        "continent-location",
        "continent-headquarter",
      ].includes(this.fieldKey)
        ? this.fieldKey.replace("continent-", "")
        : this.fieldKey;

      if (!this.isWanted && this.fieldKey.trim() !== "") {
        let field_value = this.field;
        if (this.field && this.field.trim() != "") {
          if (this.field.toLowerCase() === "true") {
            field_value = 1;
          } else if (this.field.toLowerCase() === "false") {
            field_value = 0;
          }
          data.fields[tmpFieldKey] = !isNaN(field_value)
            ? Number.isInteger(field_value)
              ? `${parseInt(field_value)}`
              : `${parseFloat(field_value)}`
            : `%${field_value}%`;
        } else if (
          this.field != null &&
          this.field.trim() === "" &&
          this.value.trim() !== ""
        ) {
          field_value = this.value;
          data.fields[tmpFieldKey] = !isNaN(field_value)
            ? Number.isInteger(field_value)
              ? parseInt(field_value)
              : parseFloat(field_value)
            : `%${field_value}%`;
        }
      }

      this.$emit("update", data);
    },
  },
};
</script>

<style scoped>
.filter-item {
  width: 300px;
  height: 255px;
  margin: 10px;
  padding: 5px;
}

.filter-item input[type="checkbox"] {
  width: 30px;
  height: 35px;
  border-radius: 0%;
}
</style>
