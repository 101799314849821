import Request from "./request";
import Utils from "../Utils";

const SERVER = "main";

export async function list(limit, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `modalities/paginate/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::modalities::list", error);
  }
}

//  TODO: not implemented in the API
export async function filter(name, limit, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `modalities/filter/${name}/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::modalities::filter", error);
  }
}

export async function create(modality, token) {
  try {
    return await Request.post(SERVER, "modalities", token, { modality });
  } catch (error) {
    Utils.debugVar("Connectors::modalities::create", error);
  }
}

export async function read(id, token) {
  try {
    return await Request.get(SERVER, `modality/${id}`, token);
  } catch (error) {
    Utils.debugVar("Connectors::modality::read", error);
  }
}

export async function update(modality, token) {
  try {
    return await Request.put(SERVER, `modality/${modality.id}`, token, {
      modality,
    });
  } catch (error) {
    Utils.debugVar("Connectors::modality::update", error);
  }
}

export async function remove(modality, token) {
  try {
    return await Request.delete(SERVER, `modality/${modality.id}`, token);
  } catch (error) {
    Utils.debugVar("Connectors::modality::remove", error);
  }
}
