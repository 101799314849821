<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-50">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / Users
      </div>
      <form class="w-50">
        <input class="w-75" type="text" name="" id="" placeholder="Search" />
        <button>
          <img src="../../assets/icons/magnifier.svg" alt="" class="icon" />
        </button>
      </form>
    </div>

    <div class="pt-5">
      <section class="height-fit-content">
        <table class="table table-striped w-100">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Roles</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in sortedItems" :key="item.uid">
              <td>{{ item.displayName }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.roles.join(", ") }}</td>
              <td>
                <router-link
                  :to="{ name: 'UserItem', params: { id: item.uid } }"
                  class="btn btn-secondary"
                  ><img
                    src="../../assets/icons/magnifier.svg"
                    alt=""
                    class="icon"
                /></router-link>
                <button class="btn btn-info" disabled>
                  <img
                    src="../../assets/icons/screen.svg"
                    alt=""
                    class="icon"
                  />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <template #toolbar>
      <button class="btn btn-success" v-on:click="showCreateForm = true">
        Create
      </button>
      <button class="btn btn-secondary">Roles</button>
    </template>

    <modal
      title="Create user"
      v-on:modal-close="showCreateForm = false"
      :active="showCreateForm"
      :width="50"
      :height="50"
    >
      <form class="p-5 text-center" v-on:submit.prevent="create">
        <table class="table table-striped w-100 text-left">
          <tr>
            <th>Name</th>
            <td>
              <input
                class="w-100"
                v-model="createForm.displayName"
                type="text"
                name="name"
                placeholder="Name"
              />
            </td>
          </tr>
          <tr>
            <th>Email</th>
            <td>
              <input
                class="w-100"
                v-model="createForm.email"
                type="email"
                name="name"
                placeholder="email@larka.com"
              />
            </td>
          </tr>
          <tr>
            <th>Password</th>
            <td>
              <input
                class="w-100"
                v-model="createForm.password"
                type="password"
                name="name"
                minlength="6"
                placeholder="***********"
              />
            </td>
          </tr>
        </table>
        <button class="btn btn-primary mt-5" type="submit">Submit</button>
      </form>
    </modal>
  </content-panel>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal";

export default {
  name: "Users",
  components: { ContentPanel, Modal },
  props: {},
  data: function () {
    return {
      items: [],
      createForm: {
        displayName: null,
        email: null,
        password: null,
      },
      showCreateForm: false,
    };
  },
  created: async function () {
    this.$EventManager.triggerLoadingStart();
    this.items = (
      await this.$API.get("users").list(null, this.$store.getters.token)
    ).items;
    this.$EventManager.triggerLoadingEnd();
  },
  computed: {
    sortedItems: function () {
      return [...this.items].sort(function (a, b) {
        return a.email.localeCompare(b.email);
      });
    },
  },
  methods: {
    create: async function () {
      this.$EventManager.triggerLoadingStart();
      this.items.push(
        await this.$API
          .get("users")
          .create(this.createForm, this.$store.getters.token)
      );
      this.createForm = {
        displayName: null,
        email: null,
        password: null,
      };
      this.showCreateForm = false;
      this.$EventManager.triggerLoadingEnd();
    },
  },
};
</script>

<style></style>
