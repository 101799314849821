import * as Firebase from "../../assets/js/Connectors/firebase";
import * as User from "../../assets/js/Connectors/users";

export default {
  state() {
    return {
      data: {
        uid: null,
        accessToken: null,
        email: "",
        displayName: "",
        permissions: [],
        roles: [],
      },
    };
  },
  getters: {
    uid: function (state) {
      return state.data.uid;
    },
    token: function (state) {
      return state.data.accessToken;
    },
    username: function (state) {
      return state.data.displayName === null ||
        state.data.displayName === "null" ||
        state.data.displayName.trim() === ""
        ? state.data.email.split("@")[0]
        : state.data.displayName;
    },
    avatar: function (state) {
      return state.data.avatar;
    },
    permissions: function (state) {
      return state.data.permissions;
    },
    roles: function (state) {
      return state.data.roles;
    },
    isConnected: function (state) {
      return state.data.uid !== null;
    },
  },
  actions: {
    login: async function ({ commit }, payload = {}) {
      const { email, password } = payload;
      await Firebase.signIn(email, password);
      let user = Firebase.getUser();
      let user_o = await User.read(user.uid, user.accessToken);
      commit("setData", {
        ...user,
        ...user_o,
      });
      if (payload.callback) {
        payload.callback();
      }
    },
    logout: async function ({ commit }, payload = {}) {
      await Firebase.signOut();
      commit("setData", {
        uid: null,
        accessToken: null,
        email: "",
        displayName: "",
        permissions: [],
      });
      if (payload.callback) {
        payload.callback();
      }
    },
    refresh: async function ({ commit }, payload = {}) {
      let user = Firebase.getUser();

      if (user.uid) {
        let user_o = await User.read(user.uid, user.accessToken);
        commit("setData", {
          ...user,
          ...user_o,
        });
        if (payload.callback) {
          payload.callback();
        }
      }
    },
  },
  mutations: {
    setData: function (state, payload) {
      state.data = { ...state.data, ...payload };
    },
  },
};
