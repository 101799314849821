<template>
  <form v-on:submit.prevent="formCreateCompany.submit">
    <Slide
      :slider="slider"
      :index="0"
      class="px-4"
      :show-required="true"
      :use-submit="true"
      :use-navigation="true"
    >
      <h3>Minimal informations </h3>
      <table class="table table-borderless">
        <tbody>
        <tr>
          <th class="w-25">Name <RequiredIcon /></th>
          <td>
            <input
              ref="createCompany/name"
              class="w-100 form-control"
              type="text"
              placeholder="Name"
              required
            />
          </td>
        </tr>
        <tr>
          <th class="w-25">Domain name <RequiredIcon /></th>
          <td>
            <input
              ref="createCompany/domain_name"
              class="w-100 form-control"
              type="text"
              placeholder="www.domain.tld"
              required
            />
          </td>
        </tr>
        <tr>
          <th class="w-25">Headquarter <RequiredIcon /></th>
          <td class="d-flex">
            <select
              ref="createCompany/headquarter"
              class="form-control"
              v-model="selectedCountry"
              required
            >
              <option value="" selected disabled>Select a headquarter</option>
              <option
                v-for="item in countries"
                :key="item.name"
                :value="JSON.stringify(item)"
                required
              >
                {{ item.name }} ({{ item.continent }})
              </option>
            </select>
            <img
              v-if="selectedCountry != ''"
              class="rounded m-1"
              :src="getCountryFlagByName(JSON.parse(selectedCountry).name)"
              width="30"
              alt="selectedCountry.code"
            />
          </td>
        </tr>

        <tr>
          <th class="w-25">Is Veterinary ?</th>
          <td>
            <div class="form-check">
              <input
                ref="createCompany/vet"
                style="transform: scale(2)"
                class="form-check-input"
                type="checkbox"
                v-on:click="isVeterinary = !isVeterinary"
              />
              <p class="w-100 text-center">
                {{ isVeterinary ? "YES" : "NO" }}
              </p>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </Slide>
    <Slide
      :slider="slider"
      :index="1"
      class="px-4"
      :show-required="true"
      :use-submit="true"
      :use-navigation="true"
      :hide-next="true"
    >
      <h3>Additional informations</h3>
      <table class="table table-borderless">
        <tbody>
        <tr>
          <th class="w-25">Ownership Type</th>
          <td>
            <select
              ref="createCompany/ownership_type"
              class="form-control"
              placeholder="Name"
            >
              <option value="" selected disabled>
                Select a ownership type
              </option>
              <option v-for="item in ownershipType" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th class="w-25">Annual number of FTEs</th>
          <td>
            <input
              ref="createCompany/annual_number_of_ftes"
              class="w-100 form-control"
              type="number"
              placeholder="Annual number of FTEs"
            />
          </td>
        </tr>
        <tr>
          <th class="w-25">Number of sites</th>
          <td>
            <input
              ref="createCompany/number_of_site"
              class="w-100 form-control"
              type="number"
              placeholder="Number"
              min="0"
            />
          </td>
        </tr>
        <tr>
          <th class="w-25">Max OEL</th>
          <td>
            <select ref="createCompany/max_oel" class="form-control">
              <option value="0" selected disabled>Select max OEL</option>
              <option v-for="item in oel" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </td>
        </tr>
        <tr>
          <th class="w-25">Max Scale</th>
          <td>
            <input
              ref="createCompany/max_scale"
              class="w-100 form-control"
              type="number"
              placeholder="Number in liters"
              min="0"
            />
          </td>
        </tr>
        </tbody>
      </table>
    </Slide>
  </form>
</template>

<script>
import Slide from "@/components/Slide";
import { createForm } from "@/assets/js/FormManager";
import {useSlider} from "@/assets/js/Slider";
import RequiredIcon from "@/components/RequiredIcon.vue";
import Modal from "@/assets/js/Modal";
import {CreateMessage} from "@/assets/js/Alert";

export default {
  name: "FormToCreateCompany",
  components: {RequiredIcon, Slide },
  props:{
    modal:{
      type: Modal,
      required: true
    }
  },
  data: function () {
    return {
      oel: this.$API.get("companies").OEL,
      ownershipType: this.$API.get("companies").OWNERSHIPTYPE,
      selectedCountry: "",
      selectedOwnershipType: "",
      countries: this.$API.get("searches").COUNTRIES.sort((a, b) => {
        return a.name.localeCompare(b.name);
      }),
      formCreateCompany: createForm(this, "createCompany"),
      isVeterinary: false,
    };
  },
  setup(){
    const slider = useSlider([
      {id:0, next:1, prev:null, protected: true, dynamic: false, description: "Information needed"},
      {id:1, next:null, prev:0, protected: true, dynamic: false, description: "Information optional"},
    ])
    return {
      slider
    }
  },
  created: async function () {
    this.formCreateCompany.onSubmit(async (formData) => {
      let form_data = {
        ...formData,
      };

      if (
        formData.domain_name.includes("http://") ||
        formData.domain_name.includes("https://")
      ) {
        form_data.domain_name = new URL(formData.domain_name).hostname;
      } else if (formData.domain_name.includes("/")) {
        form_data.domain_name = new URL(
          "https://" + formData.domain_name
        ).hostname;
      }

      let company = await this.$API
        .get("companies")
        .create(form_data, this.$store.getters.token);
      this.formCreateCompany.close();
      this.$emit("submit");
      this.$EventManager.triggerRefreshUserInfo(() => {
        this.$EventManager.triggerAlert(CreateMessage(company!==null))
        this.$router.push({
          name: "CompanyItem",
          params: { company_id: company.id },
          query: {modal: "associateServices"}
        });
      });
      this.modal.hide()
    });
  },
  methods: {
    getCountryFlagByName(name) {
      let country = this.countries.find((el) => el.name === name);
      var images = require.context("@/assets/flags/", false, /\.svg$/);
      return images("./" + country.code + ".svg");
    },
  },
};
</script>

<style scoped>
td {
  padding-bottom: 0px;
}
</style>
