<template>
  <button
    id="left-side-navbar-content-account"
    class="btn mt-3 p-3 d-flex w-100"
    v-on:click="() => (showProfile = true)"
  >
    <img
      v-if="$store.getters.avatar"
      class=""
      width="50"
      :src="$store.getters.avatar"
    />
    <img v-else class="" width="50" src="../assets/icons/alien.svg" />
    <h5 class="m-auto">{{ $store.getters.username }}</h5>
  </button>
  <ul class="list-unstyled">
    <li
      class="mt-3"
      v-if="$store.getters.permissions.includes('pages.main.read')"
    >
      Screening
    </li>
    <li
      class="mt-2"
      v-if="$store.getters.permissions.includes('pages.main.home.read')"
    >
      <router-link
        :to="{ name: 'Home' }"
        class="btn w-100"
        :class="highlightBtn('Home')"
      >
        <img class="icon" src="../assets/icons/home.svg" alt="" />
        <span>Home</span>
      </router-link>
    </li>
    <li
      class="mt-2"
      v-if="$store.getters.permissions.includes('pages.main.projects.read')"
    >
      <router-link
        :to="{ name: 'Projects' }"
        class="btn w-100"
        :class="
          highlightBtn('Projects') === 'btn-primary'
            ? highlightBtn('ProjectItem') === 'btn-primary'
              ? highlightBtn('ProjectItemGroup')
              : 'btn-warning'
            : 'btn-warning'
        "
      >
        <img class="icon" src="../assets/icons/startup.svg" alt="" />
        <span>Projects</span>
      </router-link>
    </li>
    <li
      class="mt-2"
      v-if="$store.getters.permissions.includes('pages.main.blacklists.read')"
    >
      <router-link
        :to="{ name: 'Blacklists' }"
        class="btn w-100"
        :class="
          highlightBtn('Blacklists') === 'btn-primary'
            ? highlightBtn('BlacklistItem')
            : 'btn-warning'
        "
      >
        <img class="icon" src="../assets/icons/firewall.svg" alt="" />
        <span>Blacklists</span>
      </router-link>
    </li>
    <li
      class="mt-5"
      v-if="$store.getters.permissions.includes('pages.metrics.read')"
    >
      Metrics
    </li>
    <li
      class="mt-2"
      v-if="$store.getters.permissions.includes('pages.metrics.metric1.read')"
    >
      <router-link
        :to="{ name: 'Metrics' }"
        class="btn w-100"
        :class="highlightBtn('Metrics')"
      >
        <img class="icon" src="../assets/icons/trends.svg" alt="" />
        <span>Metric 1</span>
      </router-link>
    </li>
    <li
      class="mt-2"
      v-if="$store.getters.permissions.includes('pages.metrics.metric2.read')"
    >
      <router-link
        :to="{ name: 'Metrics' }"
        class="btn w-100"
        :class="highlightBtn('Metrics')"
      >
        <img class="icon" src="../assets/icons/statistics.svg" alt="" />
        <span>Metric 2</span>
      </router-link>
    </li>
    <li
      class="mt-5"
      v-if="$store.getters.permissions.includes('pages.qualification.read') && false"
    >
      Qualification
    </li>
    <li
      class="mt-2"
      v-if="
        $store.getters.permissions.includes(
          'pages.qualification.modalities.read'
        ) && false
      "
    >
      <router-link
        :to="{ name: 'Modalities' }"
        class="btn w-100"
        :class="
          highlightBtn('Modalities') === 'btn-primary'
            ? highlightBtn('Technologies') === 'btn-primary'
              ? highlightBtn('TechnologyItem')
              : 'btn-warning'
            : 'btn-warning'
        "
      >
        <img class="icon" src="../assets/icons/one-database.svg" alt="" />
        <span>Modalities</span>
      </router-link>
    </li>
    <li
      class="mt-2"
      v-if="
        $store.getters.permissions.includes(
          'pages.qualification.general_typologies.read'
        ) && false
      "
    >
      <router-link
        :to="{ name: 'Generaltypologies' }"
        class="btn w-100"
        :class="
          highlightBtn('Generaltypologies') === 'btn-primary'
            ? highlightBtn('Typologies')
            : 'btn-warning'
        "
      >
        <img class="icon" src="../assets/icons/one-database.svg" alt="" />
        <span>General Typologies</span>
      </router-link>
    </li>
    <li
      class="mt-2"
      v-if="
        $store.getters.permissions.includes(
          'pages.qualification.companies.read'
        ) && false
      "
    >
      <router-link
        :to="{ name: 'Companies' }"
        class="btn w-100"
        :class="
          highlightBtn('Companies') === 'btn-primary'
            ? highlightBtn('CompanyItem')
            : 'btn-warning'
        "
      >
        <img class="icon" src="../assets/icons/building.svg" alt="" />
        <span>Companies</span>
      </router-link>
    </li>
    <li
      class="mt-2"
      v-if="
        $store.getters.permissions.includes('pages.qualification.sites.read') && false
      "
    >
      <router-link
        :to="{ name: 'Sites' }"
        class="btn w-100"
        :class="
          highlightBtn('Sites') === 'btn-primary'
            ? highlightBtn('SiteItem')
            : 'btn-warning'
        "
      >
        <img class="icon" src="../assets/icons/factory-machine.svg" alt="" />
        <span>Sites</span>
      </router-link>
    </li>
    <li
      class="mt-2"
      v-if="
        $store.getters.permissions.includes('pages.qualification.filters.read') && false
      "
    >
      <router-link
        :to="{ name: 'Filters' }"
        class="btn w-100"
        :class="highlightBtn('Filters')"
      >
        <img class="icon" src="../assets/icons/filter.svg" alt="" />
        <span>Filters</span>
      </router-link>
    </li>
    <li
      class="mt-2 d-none"
      v-if="
        $store.getters.permissions.includes('pages.qualification.issues.read')
      "
    >
      <button class="btn btn-primary w-100">
        <img class="icon" src="../assets/icons/warning.svg" alt="" />
        <span>Entities issues</span>
      </button>
    </li>
    <li
      class="mt-5"
      v-if="$store.getters.permissions.includes('pages.administration.read')"
    >
      Administration
    </li>
    <li
      class="mt-2"
      v-if="
        $store.getters.permissions.includes('pages.administration.users.read')
      "
    >
      <router-link
        :to="{ name: 'Users' }"
        class="btn w-100"
        :class="
          highlightBtn('Users') === 'btn-primary'
            ? highlightBtn('UserItem')
            : 'btn-warning'
        "
      >
        <img class="icon" src="../assets/icons/team.svg" alt="" />
        <span>Users</span>
      </router-link>
    </li>
    <li
      class="mt-2"
      v-if="
        $store.getters.permissions.includes('pages.administration.logs.read')
      "
    >
      <router-link
        :to="{ name: 'Logs' }"
        class="btn w-100"
        :class="highlightBtn('Logs')"
      >
        <img class="icon" src="../assets/icons/job-seeker.svg" alt="" />
        <span>Logs</span>
      </router-link>
    </li>
    <li
      class="mt-2"
      v-if="
        $store.getters.permissions.includes(
          'pages.administration.status.read'
        ) && false
      "
    >
      <router-link
        :to="{ name: 'Status' }"
        class="btn w-100"
        :class="highlightBtn('Status')"
      >
        <img class="icon" src="../assets/icons/cloud.svg" alt="" />
        <span>Status</span>
      </router-link>
    </li>
  </ul>
  <modal
    :title="'User ' + $store.getters.username.toUpperCase()"
    :active="showProfile"
    :width="30"
    :height="30"
    v-on:modal-close="showProfile = false"
  >
    <div class="px-5">
      <button
        v-on:click.prevent="goToProfile"
        class="btn btn-secondary w-100 mt-5 py-3"
      >
        <img class="icon" src="../assets/icons/alien.svg" alt="" />
        <span>Profile</span>
      </button>
      <button
        class="btn btn-danger w-100 mt-3 py-3"
        v-on:click.prevent="logout"
      >
        <img class="icon" src="../assets/icons/sign-out.svg" alt="" />
        <span>Logout</span>
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from "../components/Modal";
export default {
  components: { Modal },
  data: function () {
    return {
      showProfile: false,
    };
  },
  methods: {
    isCurrentRoute: function (name) {
      return this.$route.name === name;
    },
    highlightBtn: function (name) {
      return this.isCurrentRoute(name) ? "btn-warning" : "btn-primary";
    },
    logout: async function () {
      this.$EventManager.triggerLoadingStart();
      this.$store.dispatch("logout", {
        callback: () => {
          this.showProfile = false;
          this.$EventManager.triggerLogout();
          this.$EventManager.triggerLoadingEnd();
          this.$router.push({ name: "Login" });
        },
      });
    },
    goToProfile: function () {
      this.showProfile = false;
      this.$router.push({
        name: "Login",
        query: { redirect: `/profile/${this.$store.getters.uid}` },
      });
    },
  },
  computed: {},
};
</script>

<style></style>
