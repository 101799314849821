<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-50">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / Sites
      </div>
      <ModelSearchBar
        model="site"
        v-on:get-result="setItems"
        v-on:reset-result="reload"
      ></ModelSearchBar>
    </div>
    <div class="pt-5">
      <section class="height-fit-content">
        <table class="table table-striped w-100">
          <thead>
            <tr>
              <th scope="col" class="w-25">Company</th>
              <th scope="col" class="w-25">City</th>
              <th scope="col" class="w-25">label</th>
              <th scope="col" class="w-25">Date</th>
              <th scope="col" class="w-25">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>
                <router-link
                  v-if="item.companies?.length > 0"
                  :to="{
                    name: 'CompanyItem',
                    params: { company_id: item.companies[0]?.id },
                  }"
                >
                  {{ item.companies[0]?.name }}
                </router-link>
              </td>
              <td>
                {{ item.city
                }}<span style="padding-left: 5px"
                  >( {{ JSON.parse(item.country).name }}
                  <span style="padding-left: 5px"
                    ><img
                      class="rounded"
                      :src="getCountryFlagByName(JSON.parse(item.country).name)"
                      width="30"
                      alt="selectedCountry.code"
                  /></span>
                  )</span
                >
              </td>
              <td>{{ item.label }}</td>
              <td>{{ item.createdAt }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'SiteItem',
                    params: { site_id: item.id },
                  }"
                  class="btn btn-secondary"
                  ><img
                    src="../../assets/icons/magnifier.svg"
                    alt=""
                    class="icon"
                /></router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <template #toolbar> </template>
  </content-panel>

</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import ModelSearchBar from "../../components/ModelSearchBar.vue";

export default {
  name: "Sites",
  components: { ContentPanel, ModelSearchBar },
  data: function () {
    return {
      items: [],
      count: 0,
      selectedCountry: "",
      countries: this.$API.get("searches").COUNTRIES.sort((a, b) => {
        return a.name.localeCompare(b.name);
      }),
    };
  },
  created: async function () {
    await this.reload();
  },
  computed: {
    sortedItems: function () {
      return this.items;
    },
  },
  methods: {
    reload: async function () {
      this.$EventManager.triggerLoadingStart();
      let res = await this.$API
        .get("sites")
        .list(20, 0, this.$store.getters.token);
      this.items = res.items;
      this.count = res.count;
      this.$EventManager.triggerLoadingEnd();
    },
    getCountryFlagByName(name) {
      let country = this.countries.find((el) => el.name === name);
      var images = require.context("../../assets/flags/", false, /\.svg$/);
      return images("./" + country.code + ".svg");
    },
    setItems: function (result) {
      this.items = result.items;
      this.count = result.count;
    },
  },
};
</script>

<style></style>
