import Request from "./request";
import Utils from "../Utils";

const SERVER = "main";

export async function list(modality_id, limit, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `modality/${modality_id}/technologies/paginate/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::modalities::list", error);
  }
}

//  TODO: not implemented in the API
export async function filter(modality_id, name, limit, offset = 0, token) {
  try {
    return await Request.get(
      SERVER,
      `modality/${modality_id}/technologies/filter/${name}/${limit}/${offset}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::modalities::filter", error);
  }
}

export async function create(modality_id, technology, token) {
  try {
    return await Request.post(
      SERVER,
      `modality/${modality_id}/technologies`,
      token,
      { technology }
    );
  } catch (error) {
    Utils.debugVar("Connectors::modalities::create", error);
  }
}

export async function read(modality_id, id, token) {
  try {
    return await Request.get(
      SERVER,
      `modality/${modality_id}/technology/${id}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::technologies::read", error);
  }
}

export async function update(modality_id, technology, token) {
  try {
    return await Request.put(
      SERVER,
      `modality/${modality_id}/technology/${technology.id}`,
      token,
      {
        technology,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::technologies::update", error);
  }
}

export async function remove(modality_id, id, token) {
  try {
    return await Request.delete(
      SERVER,
      `modality/${modality_id}/technology/${id}`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::technologies::remove", error);
  }
}

export async function get_typologies(modality_id, technology_id, token) {
  try {
    return await Request.get(
      SERVER,
      `modality/${modality_id}/technology/${technology_id}/typologies`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::technologies::update", error);
  }
}

export async function add_typology(
  modality_id,
  technology_id,
  typology,
  token
) {
  try {
    return await Request.patch(
      SERVER,
      `modality/${modality_id}/technology/${technology_id}/add_typology`,
      token,
      {
        typology,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::technologies::update", error);
  }
}

export async function remove_typology(
  modality_id,
  technology_id,
  typology,
  token
) {
  try {
    return await Request.patch(
      SERVER,
      `modality/${modality_id}/technology/${technology_id}/remove_typology`,
      token,
      {
        typology,
      }
    );
  } catch (error) {
    Utils.debugVar("Connectors::technologies::update", error);
  }
}

export async function get_services(modality_id, technology_id, token) {
  try {
    return await Request.get(
      SERVER,
      `modality/${modality_id}/technology/${technology_id}/services`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::technologies::get_services", error);
  }
}

export async function get_services_by_vchainc(
  modality_id,
  technology_id,
  value_chaine_column_id,
  token
) {
  try {
    return await Request.get(
      SERVER,
      `modality/${modality_id}/technology/${technology_id}/value_chain_column/${value_chaine_column_id}/services`,
      token
    );
  } catch (error) {
    Utils.debugVar("Connectors::technologies::get_services_by_valueC", error);
  }
}
