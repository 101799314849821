<template>
  <div class="mt-5 mx-auto text-center">
    <img
      class="mb-3"
      src="../../assets/icons/servers-burning.svg"
      width="200"
      alt=""
    />
    <h2 class="text-center">500</h2>
    <h3>Internal Server Error</h3>
    <router-link class="btn btn-primary" :to="{ name: 'Home' }"
      >Go back home</router-link
    >
  </div>
</template>

<script>

export default {
  mounted() {
    this.$EventManager.triggerLoadingEnd();
    this.$EventManager.triggerLoadingMinSetValue(0);
  },
};
</script>

<style></style>
