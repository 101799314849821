<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-50">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / General Typologies
      </div>
      <ModelSearchBar
        model="general_typology"
        v-on:get-result="setItems"
        v-on:reset-result="reload"
      ></ModelSearchBar>
    </div>
    <div class="pt-5">
      <section class="height-fit-content">
        <table class="table table-striped w-100">
          <thead>
            <tr>
              <th scope="col" class="w-50">Name</th>
              <th scope="col">Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in sortedItems" :key="item.id">
              <td>{{ item.name }}</td>
              <td>{{ item.createdAt }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'Typologies',
                    params: { general_typology_id: item.id },
                  }"
                  class="btn btn-secondary"
                  ><img
                    src="../../assets/icons/magnifier.svg"
                    alt=""
                    class="icon"
                /></router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <template #toolbar>
      <button class="btn btn-success" v-on:click="formAddGeneralTypo.show()">
        Create
      </button>
    </template>
  </content-panel>
  <modal
    title="Create General Typology"
    v-on:modal-close="formAddGeneralTypo.close()"
    :active="formAddGeneralTypo.visible.value"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formAddGeneralTypo.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="addGeneralTypo/name"
              class="w-100"
              type="text"
              placeholder="Name"
            />
          </td>
        </tr>
      </table>
      <input
        ref="addGeneralTypo/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal";
import ModelSearchBar from "../../components/ModelSearchBar.vue";
import { createForm } from "../../assets/js/FormManager";
import {CreateMessage} from "@/assets/js/Alert";

export default {
  name: "GeneralTypologies",
  components: { ContentPanel, Modal, ModelSearchBar },
  data: function () {
    return {
      items: [],
      count: 0,
      formAddGeneralTypo: createForm(this, "addGeneralTypo"),
      showCreateForm: false,
    };
  },
  computed: {
    sortedItems: function () {
      return this.items;
    },
  },
  created: async function () {
    this.formAddGeneralTypo.onSubmit(async (formData) => {
      let res = await this.$API
        .get("general_typologies")
        .create(formData, this.$store.getters.token);
      this.$EventManager.triggerAlert(CreateMessage(res!==null))
    });

    this.formAddGeneralTypo.afterSubmit(async () => {
      this.formAddGeneralTypo.close();
      await this.reload();
    });
    await this.reload();
  },
  methods: {
    reload: async function () {
      this.$EventManager.triggerLoadingStart();
      let res = await this.$API
        .get("general_typologies")
        .list(20, 0, this.$store.getters.token);
      this.items = res.items;
      this.count = res.count;
      this.$EventManager.triggerLoadingEnd();
    },
    setItems: function (result) {
      this.items = result.items;
      this.count = result.count;
    },
  },
};
</script>

<style></style>
