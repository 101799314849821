<template>
  <content-panel>
    <div class="pt-5 d-flex">
      <div class="w-50">
        <router-link :to="{ name: 'Home' }"
          ><img src="../../assets/icons/home.svg" alt="" class="icon"
        /></router-link>
        / Modalities
      </div>
      <ModelSearchBar
        model="modality"
        v-on:get-result="setItems"
        v-on:reset-result="reload"
      ></ModelSearchBar>
    </div>
    <div class="pt-5">
      <div class="row">
        <h2>Modalities</h2>
        <information>
          <p>
            Create a modality by clicking on
            <button class="btn-success mt-1">Create</button>
            at the bottom of the page.
          </p>
          <p>
            Click on
            <a class="btn-secondary"
              ><img
                style="width: 15px"
                src="@/assets/icons/magnifier.svg"
                alt=""
            /></a>
            to select a technology.
          </p>
        </information>
      </div>
      <section class="height-fit-content">
        <table class="table table-striped w-100">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col" class="w-50">Technologies</th>
              <th scope="col">Date</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in sortedItems" :key="item.id">
              <td>{{ item.name }}</td>
              <td>
                <router-link
                  v-for="tech in item.technologies"
                  :key="`m-${item.id}.t-${tech.id}`"
                  :to="{
                    name: 'TechnologyItem',
                    params: {
                      modality_id: item.id,
                      modality_json: JSON.stringify(item),
                      technology_id: tech.id,
                    },
                  }"
                  class="btn btn-secondary"
                  >{{ tech.name }}</router-link
                >
              </td>
              <td>{{ item.createdAt }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'Technologies',
                    params: { modality_id: item.id },
                  }"
                  class="btn btn-secondary"
                  ><img
                    class="icon"
                    src="../../assets/icons/magnifier.svg"
                    alt=""
                /></router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <template #toolbar>
      <button class="btn btn-success" v-on:click="formCreateModality.show()">
        Create
      </button>
    </template>
  </content-panel>
  <modal
    title="Create Modality"
    v-on:modal-close="formCreateModality.close()"
    :active="formCreateModality.visible.value"
    :width="50"
    :height="50"
  >
    <form
      class="p-5 text-center"
      v-on:submit.prevent="formCreateModality.submit"
    >
      <table class="table table-striped w-100 text-left">
        <tr>
          <th>Name</th>
          <td>
            <input
              ref="createModality/name"
              class="w-100"
              type="text"
              placeholder="Name"
            />
          </td>
        </tr>
      </table>
      <input
        ref="createModality/user"
        class="w-100"
        type="hidden"
        :value="$store.getters.uid"
      />
      <button class="btn btn-primary mt-5" type="submit">Submit</button>
    </form>
  </modal>
</template>

<script>
import ContentPanel from "../../layout-components/ContentPanel";
import Modal from "../../components/Modal.vue";
import ModelSearchBar from "../../components/ModelSearchBar";
import { createForm } from "../../assets/js/FormManager";
import Information from "../../components/Information";
import {CreateMessage} from "@/assets/js/Alert";

export default {
  name: "Modalities",
  components: { ContentPanel, Modal, ModelSearchBar, Information },
  data: function () {
    return {
      items: [],
      count: 0,
      formCreateModality: createForm(this, "createModality"),
    };
  },
  created: async function () {
    this.formCreateModality.onSubmit(async (formData) => {
      let res = await this.$API
        .get("modalities")
        .create(formData, this.$store.getters.token);
      this.$EventManager.triggerAlert(CreateMessage(res!==null))
    });

    this.formCreateModality.afterSubmit(async () => {
      this.formCreateModality.close();
      await this.reload();
    });
    await this.reload();
  },
  computed: {
    sortedItems: function () {
      return this.items;
    },
  },

  methods: {
    reload: async function () {
      this.$EventManager.triggerLoadingStart();
      let res = await this.$API
        .get("modalities")
        .list(20, 0, this.$store.getters.token);
      this.items = res.items;
      this.count = res.count;
      this.$EventManager.triggerLoadingEnd();
    },
    setItems: function (result) {
      this.items = result.items;
      this.count = result.count;
    },
  },
};
</script>

<style></style>
